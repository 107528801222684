import React from 'react';
import AnalysisPlacesMissingCard from './analysis_places_missing_card';
import AnalysisPlacesRecurrancesCard from './analysis_places_recurrances_card'
import AnalysisPlacesRecurrancesCardNew from './analysis_places_recurrances_card_new'
import AnalysisPlacesDistributionCard from './analysis_places_distribution_card'
import AnalysisPlacesStatisticsCard from './analysis_places_statistics_card'
import AnalysisPlacesChainsCard from './analysis_places_chains_card';
import AnalysisPlacesNonStandardValueCard from './analysis_places_non_standard_value_card'
import isNil from 'lodash/isNil';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';

class AnalysisResults extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  render()
  {
    const { analysis } = this.props;
    if (isNil(analysis))
      return (
        <div>
          Missing analysis
        </div>
      )

    const id = this.props.match.params.id
    let typeResult = this.props.match.path.toString().includes('deliveries') ? 'deliveries' : this.props.match.path.toString().includes('samples') ? 'samples' : 'brands'

    const activeR = analysis.getIn(['statistics', 'active', 'active'])
    const inactiveR = analysis.getIn(['statistics', 'count']) - activeR
    let version = 2.0

    let inactive = new Map({ active: activeR, inactive: inactiveR })

    if (analysis.has('version'))
      version = parseFloat(analysis.get('version'))

    let outShapeAlert, outCountryAlert
    if (version < 3)
    {
      const outShape = analysis.getIn(['problems', 'outside_shapefile'])
      const outCountry = analysis.getIn(['problems', 'wrong_country'])

      if (outShape && outShape.length === 0)
      {
        outShapeAlert = (
          <div className="alert alert-warning" role="alert">
            Warning!<hr />
            Shapefile might not be defined!
          </div>
        )
      }
      if (outCountry && outCountry.length === 0)
      {
        outCountryAlert = (
          <div className="alert alert-warning" role="alert">
            Warning!<hr />
            Country might not be defined!
          </div>
        )
      }

    }
    else
    {
      const outShape = analysis.getIn(['options', 'nominatim_relation_ids'])
      const outCountry = analysis.getIn(['options', 'regions'])

      if (outShape && outShape.size === 0)
      {
        outShapeAlert = (
          <div className="alert alert-warning" role="alert">
            Warning!<hr />
            Shapefile is not be defined!
          </div>
        )
      }
      if (outCountry && outCountry.size === 0)
      {
        outCountryAlert = (
          <div className="alert alert-warning" role="alert">
            Warning!<hr />
            Country is not be defined!
          </div>
        )
      }

    }
    const problems = analysis.get('problems')
    const warnings = analysis.get('warnings') || []
    const distributions = analysis.get('distributions')

    const missObj = []
    const missObjW = []
    const nsvObj = []
    const nsvObjW = []
    const distributionsObj = []


    problems?.forEach((element, key) =>
    {
      if (!(element instanceof Map))
      {
        switch (key)
        {
          case 'name_equals_address':
            missObj[0] = { key: key, values: element }
            break
          case 'name_only_numbers':
            missObj[10] = { key: key, values: element }
            break
          case 'name_without_alphanumerics':
            missObj[11] = { key: key, values: element }
            break
          case 'name_with_unicode_chars':
            missObj[12] = { key: key, values: element }
            break
          case 'address_equals_city':
            missObj[1] = { key: key, values: element }
            break
          case 'address_equals_neighborhood':
            missObj[2] = { key: key, values: element }
            break
          case 'address_only_numbers':
            missObj[13] = { key: key, values: element }
            break
          case 'address_without_alphanumerics':
            missObj[14] = { key: key, values: element }
            break
          case 'address_with_unicode_chars':
            missObj[15] = { key: key, values: element }
            break
          case 'invalid_phone':
            missObj[3] = { key: key, values: element }
            break
          case 'outside_shapefile':
            missObj[4] = { key: key, values: element }
            break
          case 'missing_postal_code':
            missObj[5] = { key: key, values: element }
            break
          case 'missing_name':
            missObj[6] = { key: key, values: element }
            break
          case 'missing_address':
            missObj[7] = { key: key, values: element }
            break
          case 'outside_city':
            missObj[8] = { key: key, values: element }
            break
          default:
            missObj[9] = { key: key, values: element }
        }

      }
      else
      {
        switch (key)
        {
          case 'wrong_country':
            nsvObj[0] = { key: key, values: element }
            break
          case 'nonstandard_state':
            nsvObj[1] = { key: key, values: element }
            break
          case 'missing_coordinates':
            nsvObj[2] = { key: key, values: element }
            break
          case 'missing_city':
            nsvObj[3] = { key: key, values: element }
            break
          case 'missing_state':
            nsvObj[4] = { key: key, values: element }
            break
          case 'missing_country_code':
            nsvObj[5] = { key: key, values: element }
            break
          case 'missing_business_category_id':
            nsvObj[6] = { key: key, values: element }
            break
          case 'outside_city':
            nsvObj[7] = { key: key, values: element }
            break
          case 'outside_state':
            nsvObj[8] = { key: key, values: element }
            break
          case 'outside_country':
            nsvObj[9] = { key: key, values: element }
            break
          default:
            nsvObj[10] = { key: key, values: element }
            break
        }
      }
    });

    warnings?.forEach((element, key) =>
    {
      if (element.get(0))
      {
        missObjW.push({ key: key, values: element })
      }
      else if (element.size > 0)
      {
        nsvObjW.push({ key: key, values: element })
      }

    });

    distributions?.forEach((element, key) =>
    {
      distributionsObj.push({ key: key, values: element })
    });


    const missT = missObj.map((x, index) =>
    {
      if (x.key === 'duplicates')
        return null
      return < AnalysisPlacesMissingCard typeIssue={ 'problems' } attribute={ x.key } ids={ x.values } key={ index } />
    })


    const missTW = missObjW.map((x, index) =>
      <AnalysisPlacesMissingCard typeIssue={ 'warnings' } attribute={ x.key } ids={ x.values } key={ `$missing_warning_${index}` } />
    )

    const nsvT = nsvObj.map((x, index) =>
      <AnalysisPlacesNonStandardValueCard typeIssue={ 'problems' } attribute={ x.key } nonstandard_value={ x.values } key={ `$non_std_val_problem_${index}` } />
    )
    const nsvTW = nsvObjW.map((x, index) =>
      <AnalysisPlacesNonStandardValueCard typeIssue={ 'warnings' } attribute={ x.key } nonstandard_value={ x.values } key={ `$non_std_val_warning_${index}` } />
    )

    const distributionsT = distributionsObj.map((x, index) =>
      <AnalysisPlacesDistributionCard attribute={ x.key } distributions={ x.values } key={ index } />
    )

    const recurrencesKeys = Array.from(analysis.getIn(['recurrances', 'name']).keys())

    const chains = analysis.getIn(['chains']);
    let chainsUI
    if (chains)
      chainsUI = <AnalysisPlacesChainsCard chains={ chains } id={ id } typeResult={ typeResult } />


    let newFormat = true
    recurrencesKeys.forEach(key =>
    {
      if (isNaN(parseInt(key)))
        newFormat = false
    })

    return (
      <div>
        { outCountryAlert }
        { outShapeAlert }
        <h4>Total records: { analysis.getIn(['count']) } </h4>
        <h4>Problems</h4>
        {/* this.props.renderDups ? <AnalysisDuplicates duplicates={ analysis.getIn(['problems', 'duplicates']) } onExclude={ this.props.onExclude } /> : null */ }
        { missT[0] }
        { missT[10] }
        { missT[11] }
        { missT[12] }
        { missT[1] }
        { missT[2] }
        { missT[13] }
        { missT[14] }
        { missT[15] }
        { missT[3] }
        { missT[4] }
        { nsvT[0] }
        { nsvT[1] }
        <h4>Missing Attributes</h4>
        { nsvT[2] }
        { nsvT[3] }
        { missT[5] }
        { nsvT[4] }
        { missT[6] }
        { nsvT[5] }
        { nsvT[6] }
        { nsvT[7] }
        { missT[7] }
        <h4>Geocoding Problems</h4>
        { missT[8] }
        { nsvT[8] }
        { nsvT[9] }
        <h4>Warnings</h4>
        { missTW }
        { nsvTW }
        <h4>Recurrences</h4>
        { newFormat ? <AnalysisPlacesRecurrancesCardNew attribute={ 'name' } ids={ analysis.getIn(['recurrances', 'name']) } /> : <AnalysisPlacesRecurrancesCard attribute={ 'name' } ids={ analysis.getIn(['recurrances', 'name']) } /> }
        { newFormat ? <AnalysisPlacesRecurrancesCardNew attribute={ 'phone' } ids={ analysis.getIn(['recurrances', 'phone']) } /> : <AnalysisPlacesRecurrancesCard attribute={ 'phone' } ids={ analysis.getIn(['recurrances', 'phone']) } /> }
        { newFormat ? <AnalysisPlacesRecurrancesCardNew attribute={ 'address' } ids={ analysis.getIn(['recurrances', 'address']) } /> : <AnalysisPlacesRecurrancesCard attribute={ 'address' } ids={ analysis.getIn(['recurrances', 'address']) } /> }
        <h4>Distributions</h4>
        { distributionsT }
        <h4>Statistics</h4>
        <AnalysisPlacesStatisticsCard attribute={ 'cities' } stats={ analysis.getIn(['statistics', 'cities']) } />
        <AnalysisPlacesStatisticsCard attribute={ 'states' } stats={ analysis.getIn(['statistics', 'states']) } />
        <AnalysisPlacesStatisticsCard attribute={ 'countries' } stats={ analysis.getIn(['statistics', 'country_codes']) } />
        <AnalysisPlacesStatisticsCard attribute={ 'active' } stats={ inactive } />
        <AnalysisPlacesStatisticsCard attribute={ 'ignored' } stats={ analysis.getIn(['statistics', 'ignored']) } />
        <AnalysisPlacesStatisticsCard attribute={ 'duplicates' } stats={ analysis.getIn(['statistics', 'duplicates']) } />
        <h4>Chains: </h4>
        { chainsUI }
      </div>
    )
  }
}

export default withRouter(AnalysisResults);
