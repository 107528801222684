import React from 'react';
import { Link } from 'react-router-dom';
class AnalysisPlacesRecurrancesCardNewRow extends React.PureComponent
{
  constructor(props)
  {
    super(props)

    this.state = {

    }
  }

  componentDidMount()
  {
    const shuffled = this.props.item.get('ids')
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    this.setState({ shuffledA: shuffled })
  }

  render()
  {
    const { item } = this.props;

    if (this.state.shuffledA === undefined)
      return null

    const value = item.get('value')
    const count = item.get('count')
    const ids = this.state.shuffledA
    let counter = 0

    const idBadges = ids.map(id =>
    {
      counter++
      if (counter < 200)
      {
        return <Link to={ `/places/${id}/edit` } key={ id } target="_blank" className="badge badge-places m-1">{ id }</Link>
      }
      return null
    })

    const copy =
      <div className="btn btn-info mx-2" onClick={ () =>
      {
        let idsList = ''

        ids.forEach(element =>
        {
          idsList = idsList + element.toString() + ','
        })

        idsList = idsList.substring(0, idsList.length - 1)

        navigator.permissions.query({ name: "clipboard-write" }).then(result =>
        {
          if (result.state === "granted" || result.state === "prompt")
          {
            navigator.clipboard.writeText(idsList)
          }
        });
      } }>
        Copy
      </div>


    return (
      <tr>
        <td className='col-3'>
          { copy }
          { value }
        </td>
        <td className='col-1'>
          { count }
        </td>
        <td className='col-8'>
          { idBadges }
        </td>
      </tr>
    )
  }
}


export default AnalysisPlacesRecurrancesCardNewRow;
