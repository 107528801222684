import React from 'react'
import { default as ReactSelect } from 'react-select'
import { createFilter } from 'react-select'
import { FixedSizeList as List } from "react-window"

class MenuList extends React.Component {
  render () {
    const { options, children, maxHeight, getValue } = this.props
    const height = 35
    const [value] = getValue()
    const initialOffset = options.toIndexedSeq().indexOf(value) * height

    if (children.size > 0) {
      return (
        <List
          height={ maxHeight }
          itemCount={ children.size }
          itemSize={ height }
          initialScrollOffset={ initialOffset }
        >
          { ({ index, style }) => <div style={ style }>{ children.toArray()[index] }</div> }
        </List>
      )
    }

    return (
      <List
        height={ height }
        itemCount={ 1 }
        itemSize={ height }
        initialScrollOffset={ initialOffset }
      >
        { ({ style }) => <div style={ Object.assign({}, style, { textAlign: "center", lineHeight: height + "px" }) }>No Options</div> }
      </List>
    )
  }
}

class Select extends React.Component {
  render () {
    return (
      <ReactSelect
        filterOption={ createFilter({ ignoreAccents: false }) }
        components={ { MenuList } }
        { ...this.props }
      />
    )
  }
}

export default Select
