// @ts-check
import React from 'react';
import { connect } from 'react-redux';

import AnalysisActions from '../actions/analysis_actions';
import Actions from '../actions/analyzable_actions';
import Loading from '../../shared/components/loading';
import AnalysisDuplicates from './analysis_duplicates';

class AnalysisDuplicatesPanel extends React.Component
{
  /**
   * @param {any} props
   */
  constructor(props)
  {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  componentDidMount()
  {
    const dupsPath = this.props.x.duplicates_path

    let dupsPathSplit, dupsBucket, dupsKey

    if (dupsPath)
    {
      dupsPathSplit = dupsPath.split('/')
      dupsBucket = dupsPathSplit[2]
      dupsKey = `${dupsPathSplit[3]}/${dupsPathSplit[4]}`
    }

    const promises = []
    if (dupsPath)
    {
      promises.push(AnalysisActions.downloadFromBucket(this.props.x.id, dupsBucket, dupsKey, this.props.type))
    }

    Promise.all(promises).then(() =>
    {
      this.setState({ loaded: true })
      if (window.report === undefined)
        window.report = {}
      window.report.analysis = JSON.parse(JSON.stringify(this.props.x.analysis))
      window.report.duplicates = window.report.analysis.problems.duplicates

    })
  }

  render()
  {
    const { x } = this.props;

    if (this.state.loaded)
    {
      return (
        <div className="deliveries-data mb-2" style={ { height: '100%' } }>
          <AnalysisDuplicates duplicates={ x.getIn(['analysis', 'problems', 'duplicates']) } onExclude={ this.props.onExclude } />
        </div>
      )
    } else
    {
      return <Loading />
    }
  }

  analyzeStatus = () =>
  {
    const { x } = this.props;
    if (!x.analyze_queued_at && !x.analyzed_at)
    {
      return 'unanalyzed';
    } else if (!x.analyzed_at || (x.analyze_queued_at && x.analyze_queued_at > x.analyzed_at))
    {
      return 'analyzing';
    } else
    {
      return 'completed';
    }
  }

  onRemove = (id) =>
  {
    Actions.destroy(this.props.x.id, [id], this.props.type)
  }

  analyze = () =>
  {
    this.setState({ loaded: false });
    AnalysisActions.create(this.props.x.id, this.props.type).then(() =>
    {
      this.setState({ loaded: true });
    });
  }
}

const mapStateToProps = (state, props) =>
{
  return {
    x: state.getIn([props.type, props.match.params.id])
  };
};

const mapDispatchToProps = {
  updateDelivery: (data, type) => ({ type: `${type}.merge`, data })
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AnalysisDuplicatesPanel);
