
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  index: function(params, actionType = "load")
  {
    const q = Object.assign({ sort: 'places.id', sort_dir: 'desc' }, params, { page_size: 25 });
    return authorizedFetch('/v3/admin/places', q).then(handleResponse("places", actionType));

  },
  show: function(id)
  {
    return authorizedFetch(`/v3/admin/places/${id}`).then(handleResponse("places", "merge"));
  },
  update: function(id, params)
  {
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/places/${id}`, null, opts).then(handleResponse("places", "merge"));
  },
};
