import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {
  index: function(params, actionType = "load")
  {
    const q = Object.assign({ sort: 'samples.id', sort_dir: 'desc' }, params, { page_size: 25 });
    return authorizedFetch('/v3/admin/samples', q).then(handleResponse("samples", actionType));

  },
  show: function(id)
  {
    return authorizedFetch(`/v3/admin/samples/${id}`).then(handleResponse("samples", "merge"));
  },

  destroy: function(id, place_ids)
  {
    const opts = {
      method: 'DELETE',
      ids: place_ids
    };
    return authorizedFetch(`/v3/admin/samples/${id}/places`, null, opts).then(handleResponse("samples", "merge"));
  },
  analyze: function(id, params)
  {
    const opts = {
      method: 'POST',
      body: JSON.stringify(params)
    }
    return authorizedFetch(`/v3/admin/samples/${id}/analysis`, null, opts);
  }
};
