import React from 'react';
import { Link } from 'react-router-dom';

import LiveDate from './live_date';

class DeliveriesRow extends React.PureComponent {

  render(){
    return (
      <tr>
        <td><Link to={`/deliveries/${this.props.delivery.id}/edit`} target="_blank" className="badge badge-deliveries">{this.props.delivery.id}</Link></td>
        <td>{this.props.delivery.name}</td>
        <td><LiveDate date={this.props.delivery.created_at} /></td>
        <td>{this.props.delivery.notes}</td>
      </tr>
    )
  }
}

export default DeliveriesRow;
