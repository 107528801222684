import React from 'react';
import { connect } from 'react-redux';
import PlacesActions from '../actions/places_actions';

class PlacesActionsPanel extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = { ignored: false, error: null, ignoring: false };
  }

  ignore = () =>
  {
    let date = null
    if (this.props.place.ignored_at === null)
      date = (new Date()).toISOString()
    this.setState({ ignoring: true })
    PlacesActions.update(this.props.match.params.id, { data: { attributes: { ignored_at: date } } }).then(() =>
    {
      this.setState({ ignored: true, ignoring: false, error: null })
    }).catch(e =>
    {
      this.setState({ error: e, ignored: false, ignoring: false })
    })
  }

  render()
  {
    const { place } = this.props;

    let errors
    if (this.state.error)
    {
      if (this.state.error.errors)
      {
        errors = this.state.error.errors.map(error =>
        {
          return (
            <div key={ error.code }>
              <span className='text-danger'>{ error.code }</span>
              <span className='text-danger'>{ error.title }</span>
            </div>)
        })
      }
      else
      {
        const error = this.state.error
        errors =
          <div key={ error }>
            <span className='text-danger'>{ error.status }</span>
            <span className='text-danger'>{ error.error }</span>
          </div>
      }
    }

    const error = <div className='col align-self-end m-1'>{ errors }</div>
    const textIgnoreButton = (place.ignored_at === null) ? 'Ignore' : 'Don\'t ignore'

    const ignoreButton = <div className="col align-self-end m-1">
      <div className="btn btn-danger" onClick={ this.ignore }>{ textIgnoreButton }</div>
    </div>
    return (
      <div className="observations-data mb-2">
        { ignoreButton }
        { error }
      </div>
    )
  }
}

const mapStateToProps = (state, props) =>
{
  const place = state.getIn(['places', props.match.params.id]);

  return {
    place: place,
  };
};

export default connect(mapStateToProps)(PlacesActionsPanel);
