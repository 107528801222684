export const getLink = (type, value) =>
{
  switch (type)
  {
    case 'google_places_id':
      return `https://www.google.com/maps?q=place_id:${value}`
    case 'facebook_id':
      return `https://www.facebook.com/${value}`
    case 'street_view':
      return "http://maps.google.com?cbll=" + value.latitude + "," + value.longitude + "&cbp=12,20.09,,0,5&layer=c"
    case 'starbucks_store_id':
      return `https://www.starbucks.com/store-locator/store/${value}`
    default:
      return value
  }
}
