import React from 'react';

class AnalysisPlacesRecurrancesCardRow extends React.PureComponent
{
  render()
  {
    const { value, attribute } = this.props;
    return (
      <tr>
        <td>
          { attribute || "null" }
        </td>
        <td>
          { value }
        </td>
      </tr>
    )
  }
}


export default AnalysisPlacesRecurrancesCardRow;
