import React from 'react';
import { connect } from 'react-redux';
import DuplicateChecksIndex from './duplicate_checks_index';

class PlaceDuplicatesReviewTask extends React.PureComponent
{

  render()
  {
    const { task, taskJson, original, duplicate } = this.props;
    let response
    if (taskJson)
      response = { data: taskJson.included }
    else
      response = task.response
    return <DuplicateChecksIndex task={ task } original={ original } duplicate={ duplicate } onMerge={ this.onMerge } response={ response } />
  }

  onMerge = (shouldMerge) =>
  {
    return this.props.onComplete({
      data: {
        attributes: {
          should_merge: shouldMerge,
          original_id: this.props.original.id,
          duplicate_id: this.props.duplicate.id,
        }
      }
    });
  }
}

const mapStateToProps = state =>
{
  let original;
  let duplicate;
  let explain
  const task = state.get('tasks').sortBy(t => t.created_at).first();

  if (task)
  {
    original = state.getIn(['places', task.getIn(['association_ids', 'original_id']).toString()]);
    duplicate = state.getIn(['places', task.getIn(['association_ids', 'duplicate_id']).toString()]);
  }

  explain = state.get('page').get('explain')

  return {
    task,
    original,
    duplicate,
    explain
  }
};

const mapDispatchToProps = {
  clearTask: (task) => ({ type: 'tasks.delete_in', data: [[task.id.toString()]] }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceDuplicatesReviewTask);
