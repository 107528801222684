import React from 'react';
import { connect } from 'react-redux';
import MergeEventsActions from '../actions/merge_events_actions';
import BrandComparionActions from '../actions/brand_comparion_actions';

import PlacesActions from '../actions/places_actions'
import BrandsImagesPanel from './brands_images_panel';
import BrandsComparePlacesNameExplanationPanel from './brands_compare_places_name_explanation_panel';
import BrandsComparePlacesWebsiteExplanationPanel from './brands_compare_places_website_explanation_panel';
import BrandsComparePlacesCategoryExplanationPanel from './brands_compare_places_category_explanation_panel';
import BrandsComparePlacesCoordinatesExplanationPanel from './brands_compare_places_coordinates_explanation_panel';

class BrandsComparePlacesPanel extends React.Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      loaded: false,
      response: undefined,
      place: undefined
    };
  }

  componentDidMount()
  {
    this.fetchData()
  }

  render()
  {
    const { brand, place } = this.props;
    const response = this.state.response
    let rows

    if (response)
    {
      console.log(response)
      const scoreKeys = Object.keys(response.data.attributes.score)

      rows = scoreKeys.map(key =>
      {
        switch (key)
        {
          case 'name':
            return <BrandsComparePlacesNameExplanationPanel key={ key } name_explanation={ response.data.attributes.score.name } place={ place } />
          case 'category':
            return <BrandsComparePlacesCategoryExplanationPanel key={ key } category_explanation={ response.data.attributes.score.category } place={ place } />
          case 'coordinates':
            return <BrandsComparePlacesCoordinatesExplanationPanel key={ key } coordinates_explanation={ response.data.attributes.score.coordinates } place={ place } />
          case 'website':
            return <BrandsComparePlacesWebsiteExplanationPanel key={ key } website_explanation={ response.data.attributes.score.website } place={ place } />

        }
      });

      const params = {
        params: { id: brand.id.toString() }
      }
      return (
        <div className="row m-1">
          <div className='card col-6'>
            <div className='card-header badge-brands'>
              Brand: { brand.name }
            </div>
            <div className='card-body'>
              <span className='row'>
                Website: { brand.get('website') }
              </span>
              <BrandsImagesPanel brand={ brand } match={ params } />
            </div>
          </div>

          <div className='card col-6 p-0'>
            <div className='card-header badge-places'>
              <div className='row justify-content-between'>
                <div className='col-9'>
                  Place: { place.name }
                </div>
                <div className='col-auto'>
                  Total Score: { response.data.attributes.total.toFixed(2) }
                </div>
              </div>

            </div>
            <div className='card-body m-0 pr-0'>
              { rows }
            </div>
          </div>
        </div>
      )
    }
    else if (this.props.match.params.candidate_id)
    {
      return null
    }
    else
    {
      return (
        <div className="visits-data mb-2">
          <form onSubmit={ this.onSubmit } className="form-inline">
            <label>
              Id of place to compare:
            </label>
            <input className="ml-2 form-control" type="text" value={ this.state.query || "" } onChange={ this.onChange } />

            <input type="submit" className="btn btn-primary ml-2" value="Compare" />
          </form>
        </div>
      )
    }

  }

  fetchData = (id = this.props.match.params.candidate_id) =>
  {
    BrandComparionActions.show(this.props.match.params.id, id).then((response) =>
    {
      PlacesActions.show(this.props.match.params.candidate_id).then((place) =>
      {
        this.setState({ loaded: true, response: response, place: place });
      })

    });

  }

  onSubmit = (event) =>
  {
    event.preventDefault();
    this.fetchData(this.state.query);
    this.props.history.push(`/brands/${this.props.brand.id}/places/${this.state.query}`);
  }

  onChange = (event) =>
  {
    this.setState({ query: event.target.value })
  }

  onMerge = (shouldMerge) =>
  {
    const { place, duplicateCandidate } = this.props;
    this.setState({ query: "" })
    MergeEventsActions.create({ data: { attributes: { original_id: place.id, duplicate_id: duplicateCandidate.id, should_merge: shouldMerge } } })
    this.props.history.push(`/places/${this.props.place.id}/patch`);
  }
}

const mapStateToProps = (state, props) =>
{
  return {
    brand: state.getIn(['brands', props.match.params.id]),
    place: state.getIn(['places', props.match.params.candidate_id])

  };
}

export default connect(mapStateToProps)(BrandsComparePlacesPanel);
