import React from 'react';
import { connect } from 'react-redux';
import LiveDate from './live_date';
import { Link } from 'react-router-dom';

class PlacesOverviewPatchPanelPatchesRow extends React.Component
{
  render()
  {

    const winner = this.props.patch

    const c = winner['c']
    const v = winner['v']
    const d = winner['d']
    const i = winner['i']

    return (
      <tr style={{color: 'gray'}}>
        <td><Link to={`/places/${i}/edit`} target="_blank" className="badge badge-places">{i}</Link></td>
        <td>{v}</td>
        <td>{c}</td>
        <td><LiveDate date={d} /></td>
      </tr>
    )
  }
}


export default connect()(PlacesOverviewPatchPanelPatchesRow);
