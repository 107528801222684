import React from 'react';
import { connect } from 'react-redux';
import ChainReviewIndex from './chain_review_index';
import PlacesActions from '../actions/places_actions';
import BrandActions from '../actions/brand_actions';

class ChainReviewTask extends React.PureComponent
{

  render()
  {
    const { task, brand, place } = this.props;
    if (brand && place)
      return <ChainReviewIndex task={ task } brand={ brand } place={ place } onSubmit={ this.onSubmit } />
    return null
  }

  onSubmit = (value) =>
  {
    return this.props.onComplete({
      data: {
        attributes: {
          brand_id: this.props.brand.id,
          place_id: this.props.place.id,
          confidence: value
        }
      }
    });
  }
}

const mapStateToProps = state =>
{
  let brand, place

  const task = state.get('tasks').sortBy(t => t.created_at).first();
  if (task)
  {
    brand = state.getIn(['brands', task.getIn(['association_ids', 'brand_id']).toString()]);
    place = state.getIn(['places', task.getIn(['association_ids', 'place_id']).toString()]);
  }
  if (brand === undefined)
    BrandActions.show(task.getIn(['association_ids', 'brand_id']).toString())
  if (place === undefined)
    PlacesActions.show(task.getIn(['association_ids', 'place_id']).toString())

  return {
    task,
    brand,
    place
  }
};

const mapDispatchToProps = {
  clearTask: (task) => ({ type: 'tasks.delete_in', data: [[task.id.toString()]] }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ChainReviewTask);
