import React from 'react';
import Ajv from 'ajv';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';

import JSONFormSection from './json_form_section';
import JSONFormField from './json_form_field';
import JSONFormWidget from './json_form_widget';

import { getFormErrorsFromState } from '../helpers/error_helpers';
import { validateForm } from '../helpers/schema_helpers';

const ajv = new Ajv({unknownFormats: ['phone']});

class JSONFormElement extends React.PureComponent {
  render(){
    const {form,errors,warnings,i18nKey,element,schema,...rest} = this.props;
    const updateForm = this.updateForm;
    const validateFormProperties = this.validateFormProperties;

    let shouldDisplay
    if(element.get('display_if')){
      const displayIf = element.get('display_if').toJSON()
      const data = form.toJSON()
      shouldDisplay = ajv.validate(displayIf, data);
    } else {
      shouldDisplay = true;
    }

    if(shouldDisplay){
      switch(element.get('type')) {
        case 'section':
          return <JSONFormSection {...rest} {...{element,i18nKey,schema}}/>;
        case 'field': {
          const property = element.get('property')
          const v = form.get(property);
          const value = isNil(v) ? '' : v;

          const error = errors.get(property);
          const warning = warnings.get(property);

          return <JSONFormField {...rest} {...{element,property,value,i18nKey,schema,error,warning,updateForm,validateFormProperties}}/>;
        }
        case 'widget':
          return <JSONFormWidget {...rest} {...{element,form,errors,warnings,i18nKey,updateForm,schema,validateFormProperties}}/>;
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  updateForm = (update) => {
    const { prevalidation,removePrevalidation,updateForm } = this.props;
    if(prevalidation){ removePrevalidation(prevalidation.id) }
    updateForm(update);
  }

  validateFormProperties = (properties) => {
    const {form,schema,loadErrors,i18nKey,errori18nKey} = this.props;
    const errors = validateForm(schema,form,(errori18nKey||i18nKey));
    loadErrors(errors);

    const noLocalErrors = !(properties.some(p => errors[p]));
    if(noLocalErrors){
      return this.remoteValidate(properties)
    } else {
      return Promise.resolve();
    }
  }

  remoteValidate = (properties) => {
    const {form,schema,updateAsyncErrors,updateAsyncWarnings,prevalidation,sendRemoteValidation} = this.props;

    const needsRemoteValidation = properties.some(p => schema.getIn(['properties',p,'remote']));
    if(needsRemoteValidation){
      const noPrevals = !prevalidation;
      if(noPrevals){
        const attributes = properties.reduce((o,p) => { o[p] = form.get(p); return o; },{});
        if(Object.values(attributes).find(x => x)){
          const p = sendRemoteValidation(attributes).catch((e) => {
            updateAsyncErrors(e.errors);
            updateAsyncWarnings(e.warnings);
          });
          return p
        }
      }
    }

    return Promise.resolve();
  }
}


const mapStateToProps = (initialState,initialProps) => {
  const { formKey,element } = initialProps;
  const property = element.get('property');
  return (state) => {
    let prevalidation;
    const prevalidations = state.get('prevalidations');
    if(prevalidations && !prevalidations.isEmpty()){
      prevalidation = prevalidations.filter(pv => pv.attr === property).last()
    }

    return {
      form: state.getIn(['forms',formKey]),
      errors: getFormErrorsFromState(state,formKey),
      warnings: state.getIn(['form_async_warnings',formKey]),
      prevalidation,
    };
  };
};

const mapDispatchToProps = (initialState,initialProps) => {
  const { formKey } = initialProps;
  return dispatch => ({
    updateForm: (data) => dispatch({ type: 'forms.merge', data: {[formKey]: data }}),
    loadErrors: (errors) => dispatch({type: 'form_errors.load', data: {[formKey]: errors}}),
    updateAsyncErrors: (errors) => dispatch({type: 'form_async_errors.merge', data: {[formKey]: errors}}),
    updateAsyncWarnings: (warnings) => dispatch({type: 'form_async_warnings.merge', data: {[formKey]: warnings}}),
    removePrevalidation: (id) => dispatch({type: 'prevalidations.delete_in', data: [[id.toString()]]}),
    mergeIncluded: (action) => dispatch(action)
  });
}

export default connect(mapStateToProps,mapDispatchToProps)(JSONFormElement);

