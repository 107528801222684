import React from 'react';
import * as queries from '../../shared/data/queries_analysis.json';
import AnalysisPlacesMissingCardRow from './analysis_places_missing_card_row';
import { withRouter } from 'react-router-dom';

class AnalysisPlacesMissingCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount()
  {
    let shuffled

    if (this.props.ids)
    {
      shuffled = this.props.ids
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    }
    this.setState({ shuffledA: shuffled })
  }


  render()
  {
    const nextUrl = 'https://adminnext.dataplor.com'
    const { ids, attribute } = this.props;
    let rows, copy, body
    let counter = 0

    const id = this.props.match.params.id

    let typeResult = this.props.match.path.toString().includes('deliveries') ? 'deliveries' : this.props.match.path.toString().includes('samples') ? 'samples' : 'brands'

    if (this.state.open)
    {
      if (this.state.shuffledA)
        rows = this.state.shuffledA.map(item =>
        {
          counter++
          if (counter <= 200)
          {
            return <AnalysisPlacesMissingCardRow id={ item } key={ item } />
          }
          else
          {
            return null
          }
        })
      copy =
        <div className="row p-2">
          <div className="col-2 btn btn-info m-1" onClick={ this.download }>Copy</div>
          { attribute in queries.queries ? <div className="col-2 btn btn-info m-1" onClick={ this.query }>Query</div> : null }

          <a href={ `${nextUrl}/${typeResult}/${id}/analysis/places?analysis_key=${this.props.typeIssue}.${attribute}` } target='_blank' rel="noreferrer" className='col-2 btn btn-info m-1'>adminNext</a>
        </div>

      body = <div className='card-body'>
        { copy }
        <div>
          { rows }
        </div>
      </div>
    }


    return (
      <div className="card border-danger mb-2">
        <div className="card-header text-danger cursor-pointer" onClick={ this.toggle }>
          { attribute } [{ (ids && ids.size) || 0 }]
        </div>
        { body }
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
  download = () =>
  {
    navigator.permissions.query({ name: "clipboard-write" }).then(result =>
    {
      if (result.state === "granted" || result.state === "prompt")
      {
        navigator.clipboard.writeText(JSON.stringify(this.state.shuffledA))
      }
    });
  }


  query = () =>
  {
    const { ids, attribute } = this.props;

    let idsList = ''
    ids.forEach(element =>
    {
      idsList = idsList + element.toString() + ','
    });

    idsList = idsList.substring(0, idsList.length - 1)

    let query = queries.queries[attribute].replace('{{ids}}', idsList)
    navigator.permissions.query({ name: "clipboard-write" }).then(result =>
    {
      if (result.state === "granted" || result.state === "prompt")
      {
        navigator.clipboard.writeText(query)
      }
    });
  }
}


export default withRouter(AnalysisPlacesMissingCard);
