import React from 'react';
import { Link } from 'react-router-dom';

import LiveDate from './live_date';

class PlacesRow extends React.PureComponent {
  render(){
    return (
      <tr>
        <td><Link to={`/places/${this.props.place.id}/edit`} target="_blank"  className="badge badge-places">{this.props.place.id}</Link></td>
        <td>{this.props.place.name}</td>
        <td>{this.props.place.city}</td>
        <td>{this.props.place.state}</td>
        <td>{this.props.place.country}</td>
        <td><LiveDate date={this.props.place.created_at} /></td>
      </tr>
    )
  }
}

PlacesRow.columns = [
 {col: "id", label: "Id"},
 {col: "name", label: "Name"},
 {col: "city", label: "City"},
 {col: "state", label: "State"},
 {col: "country", label: "Country"},
 {col: "created_at", label: "Created"},
];


export default PlacesRow;
