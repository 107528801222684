import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PatchForm from './patch_form';
//import Immutable from 'immutable';
import { savePlaceManualObservation } from '../helpers/observation_helpers';
import PlacesObservationsActions from '../actions/places_observations_actions';

class PlacesManualObservation extends React.Component
{

  constructor(props)
  {
    super(props);
    this.state = { submitted: false };
  }


  onComplete = (id, patchForm) =>
  {
    if (this.props.form_errors.size === 0)
    {
      savePlaceManualObservation(id, patchForm, PlacesObservationsActions.create)
      this.setState({ submitted: true })
    }
  }

  render()
  {

    const { place, formKey } = this.props;
    //let patchFormData


    //patchFormData = Immutable.fromJS({
    //  name: place.data.get("name"),
    //  address: place.data.get("address"),
    //})

    const patchForm = <PatchForm formKey={ formKey } pin={ ['name', 'address'] } data={ place.data } />

    if (this.state.submitted)
    {
      return (
        this.props.history.push(`/places/${this.props.place.id}/patch`)
        //<div><span>Submitted! Refresh to review again.</span></div>
      );
    }
    else
    {
      return (
        <div className="container mt-3" style={ { paddingLeft: "0px", paddingRight: "0px", maxWidth: "100%" } }>
          <div className="row">
            <div className="col">
              { patchForm }
            </div>
          </div>
          <div className="fixed-bottom">
            <div className="row my-2 justify-content-center">
              <button className="col-2 btn btn-primary align-self-center" onClick={ () => this.onComplete(place.id, this.props.form) }>Save</button>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, props) =>
{
  const formKey = uuidv4();
  return (state) =>
  {
    const form = state.getIn(['forms', formKey])
    return {
      form: form,
      place: state.getIn(['places', props.match.params.id]),
      formKey,
      form_errors: state.getIn(['form_errors', formKey]),
    };
  }
};

export default connect(mapStateToProps, null)(PlacesManualObservation);
