import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import isNil from 'lodash/isNil';
import DataplorMap from '../../shared/components/dataplor_map';
import ConfirmModal from '../../shared/components/confirm_modal';


class ObservationsMapPanel extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {};
    this.map = React.createRef();
  }

  render()
  {
    const { observation } = this.props;
    let longitude = observation.longitude
    let latitude = observation.latitude

    const retract =
      <div className="col align-self-end">
        <div className="btn btn-info" data-toggle="modal" data-target="#conf" onClick={ () => { console.log("Retract") } }>Retract Coordinates</div>
      </div>

    if (isNil(longitude) || isNil(latitude))
    {
      longitude = observation.getIn(["response", "longitude"])
      latitude = observation.getIn(["response", "latitude"])
    }

    if (isNil(longitude) || isNil(latitude))
      return (
        "No location"
      )

    const modal = <ConfirmModal id="conf" title="Confirmation" message="Are you sure you want to retract the coords?" confirm="Yes" cancel="No" onConfirm={ () => console.log("test") } />
    return (
      <div>
        { retract }
        { modal }
        <DataplorMap center={ [latitude, longitude] } zoom={ 15 } key="place-map" style={ { height: '75vh' } }>
          <Marker position={ [latitude, longitude] } />
        </DataplorMap>
      </div>
    )
  }
}

const mapStateToProps = (state, props) =>
{
  const observationId = props.match.params.id;
  const observation = state.getIn(['observations', observationId]);

  let lead;
  if (observation.lead_id)
  {
    lead = state.getIn(['leads', observation.lead_id.toString()]);
  }

  return {
    observation: observation,
    images: state.get('images').filter(i => i.observation_id === parseInt(observationId, 10)),
    lead: lead
  }
};

export default connect(mapStateToProps)(ObservationsMapPanel);
