
import { authorizedFetch } from '../../shared/helpers/request_helpers';
import { handleResponse } from '../../shared/helpers/action_helpers';


export default {
  create: function(params)
  {
    const body = Object.assign({}, params, { types: ['BotCallAuditTask', 'GooglePlacesMatchTask', 'PlaceDuplicatesReviewTask', 'ChainValidationTask'] });
    const opts = {
      method: 'POST',
      body: JSON.stringify(body)
    };
    return authorizedFetch(`/v3/admin/task_claims`, null, opts).then(handleResponse("tasks", "load"));
  },
  update: function(id, params)
  {
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/task_claims/${id}`, null, opts);
  },
  destroy: function(id)
  {
    const opts = {
      method: 'DELETE'
    };
    return authorizedFetch(`/v3/admin/task_claims/${id}`, null, opts);
  }
};
