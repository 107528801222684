import AWS from 'aws-sdk';
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';
import Store from '../reducers/store';
import * as fflate from 'fflate';

AWS.config.update({
  accessKeyId: 'AKIA5RPX3UTPPUECMWEG',
  secretAccessKey: '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',
});

export default {
  show: function(id, params, type)
  {
    return authorizedFetch(`/v3/admin/${type}/${id}/analysis`, params).then(data =>
    {
      window.analysis = data
      Store.dispatch({
        type: `${type}.merge`,
        data: [{ id: id, attributes: { analysis: data } }],
      })
    }).catch(error =>
    {
      console.log(error)
    });
  },
  create: function(id, params, type, actionType = "merge")
  {
    const opts = { method: 'POST' };
    return authorizedFetch(`/v3/admin/${type}/${id}/analysis`, params, opts).then(handleResponse(type, actionType));
  },


  downloadFromBucket: function(id, bucket, key, type)
  {
    const params = {
      Bucket: bucket,
      Key: key,
    };

    const s3 = new AWS.S3();
    return new Promise((resolve, reject) =>
    {
      s3.getObject(params, (err, data) =>
      {
        if (err)
        {
          console.log(err, err.stack);
        }
        else
        {
          try
          {
            const dataUZ = fflate.decompressSync(data.Body)
            let toJson = fflate.strFromU8(dataUZ)
            toJson = toJson.replaceAll('}{', '},{')
            toJson = toJson.replaceAll(',,', ',')
            //toJson = toJson.replaceAll('][', '],[')

            const json = JSON.parse(toJson)

            Store.dispatch({
              type: `${type}.merge`,
              data: [{ id: id, attributes: { analysis: json } }],
            })
            resolve(json)
          }
          catch (err)
          {
            console.log(err.toString())
            resolve(null)
          }
        }
      });
    })
  }
}
