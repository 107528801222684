import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch, Route, Link, withRouter } from 'react-router-dom';

import SamplesActions from '../actions/samples_actions';
import SamplesExportActions from '../actions/samples_export_actions';
import SamplesDataPanel from './samples_data_panel';
import SamplesPlacesPanel from './samples_places_panel';
import SamplesGeneratePanel from './samples_generate_panel';
import AnalyzePanel from './analyze_panel';
import AnalysisDuplicatesPanel from './analysis_duplicates_panel';
import { triggerDownload } from '../helpers/download_helpers';


import LiveDate from './live_date';

class SamplesShow extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      place: {},
      tab: 'data',
      loaded: false,
      analyzePanel: false,
      analyzeVersion: null,
      analyzeOptions: [],
      analyzeStatus: null,
    };
  }

  componentDidMount()
  {
    if (!this.subpath())
    {
      this.props.history.push(`${this.props.location.pathname}/data`)
    }
    SamplesActions.show(this.props.match.params.id).then(json =>
    {
      this.setState({ loaded: true });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if (this.props.match.params.id !== prevProps.match.params.id)
    {
      SamplesActions.show(this.props.match.params.id).then(json =>
      {
        this.setState({ loaded: true });
      })
    }
  }

  onVersionChange = (e) =>
  {
    const value = e.target.checked
    const id = e.target.id
    this.setState({ analyzeVersion: value ? id : null })
  }

  onAnalyze = () =>
  {
    const params = {}
    params.version = this.state.analyzeVersion
    const only = this.state.analyzeOptions
    params.options = { only: only }

    SamplesActions.analyze(this.props.match.params.id, params).then(json =>
    {
      this.setState({ analyzeStatus: 'Triggered' })
    })
  }

  onParamChange = (e) =>
  {
    const value = e.target.checked
    const id = e.target.id
    const params = [...this.state.analyzeOptions]
    console.log(params, this.state.analyzeOptions)
    if (params.includes(id))
    {
      //so the param is already included
      if (!value)
      {
        params.splice(params.indexOf(id), 1)
      }
    }
    else
    {
      if (value)
      {
        params.push(id)
      }
    }
    this.setState({ analyzeOptions: params })
  }

  render()
  {
    if (this.state.loaded)
    {

      const { sample } = this.props;
      const subpath = this.subpath();
      const date = sample.get('analysis') ? sample.get('analysis').get('started') : null
      const analyzeButton = this.state.analyzeVersion && this.state.analyzeOptions.length > 0 ? <button type="button" className="btn btn-success mt-1" onClick={ this.onAnalyze }>Analyze</button> : <button type="button" className="btn btn-success mt-1" disabled>Analyze</button>

      const analyzePanel = (
        <div className='row mt-3 border border-success'>
          <div className='col-3'>
            <span>Params:</span>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="duplicates" onChange={ this.onParamChange } />
              <label className="form-check-label" htmlFor="duplicates">
                Duplicates
              </label>

            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="analysis" onChange={ this.onParamChange } />
              <label className="form-check-label" htmlFor="analysis">
                Analysis
              </label>

            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="chains" onChange={ this.onParamChange } />
              <label className="form-check-label" htmlFor="chains">
                Chains
              </label>
            </div>
          </div>
          <div className='col-2'>
            <span>Version:</span>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="3" onChange={ this.onVersionChange } />
              <label className="form-check-label" htmlFor="3">
                3
              </label>
            </div>
          </div>
          <div className='col-1'>
            { analyzeButton }
          </div>
          <div className='col'>
            { this.state.analyzeStatus }
          </div>
        </div >)



      return (
        <div id="samplesShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-samples">{ sample.id }</span> { sample.name }</h2>
              <h5><span className="badge badge-samples">{ sample.uuid }</span></h5>
              <p>Added <LiveDate date={ sample.created_at } />, updated <LiveDate date={ sample.updated_at } /></p>
              <p>Analyzed at: <LiveDate date={ date } /></p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <button className="btn btn-primary btn-success mr-2" onClick={ () => { this.setState({ analyzePanel: !this.state.analyzePanel }) } }>Analyze</button>
              <a className="mr-2" href={ `https://s3.console.aws.amazon.com/s3/buckets/dataplor-data?region=us-west-2&prefix=exports/samples/${sample.id}/&showversions=false` } rel="noreferrer" target="_blank">
                <button className="btn btn-primary">Download Finals</button>
              </a>
              <button className="btn btn-primary btn-warning" onClick={ this.downloadDraft }>Download Draft</button>
            </div>
          </div>
          { this.state.analyzePanel ? analyzePanel : null }
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'data') }) } to={ `/samples/${sample.id}/data` }>Data</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'places') }) } to={ `/samples/${sample.id}/places` }>Places</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'generate') }) } to={ `/samples/${sample.id}/generate` }>Generate</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'analyze') }) } to={ `/samples/${sample.id}/analyze` }>Analyze</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'duplicates') }) } to={ `/samples/${sample.id}/duplicates` }>Duplicates</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/samples/:id/data" component={ SamplesDataPanel } />
                    <Route exact path="/samples/:id/places" component={ SamplesPlacesPanel } />
                    <Route exact path="/samples/:id/generate" component={ SamplesGeneratePanel } />
                    <Route exact path="/samples/:id/analyze" render={ (props) => <AnalyzePanel type={ 'samples' } { ...props } /> } />
                    <Route exact path="/samples/:id/duplicates" render={ (props) => <AnalysisDuplicatesPanel type={ 'samples' } { ...props } /> } />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else
    {
      return 'loading...'
    }

  }

  subpath = () =>
  {
    return this.props.location.pathname.match(/samples\/\d+\/?(\w*)/)[1];
  }

  downloadDraft = () =>
  {
    SamplesExportActions.show(this.props.sample.id).then(response =>
    {
      triggerDownload("text/csv", `\uFEFF${response.data}`, response.filename);
    })
  }

  switchTab = (e) =>
  {
    e.preventDefault();
    this.setState({ tab: e.target.getAttribute('data-tab') })
  }

  fetchData = (query) =>
  {
    SamplesActions.index(
      Object.assign(
        {},
        query,
        { filter: { root_id: this.props.match.params.id } }
      ), "merge"
    )
  }
}

const mapStateToProps = (state, props) =>
{
  const id = props.match.params.id
  const sample = state.getIn(['samples', id])
  return {
    sample: sample,
  }
}

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SamplesShow));
