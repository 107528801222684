import React from 'react';
import { connect } from 'react-redux';
import PlacesDataViewCurrentPatchPanelRow from './places_dataview_current_patch_panel_row'

class PlacesDataViewCurrentPatchPanel extends React.Component
{

  render()
  {

    const { patch, cutout } = this.props
    let rows
    let key = 0

    let protoPatchMap = {}
    if (patch)
    {
      patch.forEach(row =>
      {

        if (row.p in protoPatchMap && Date.parse(row.d) <= Date.parse(cutout))
        {
          protoPatchMap[row.p].push(row)
        }
        else if (Date.parse(row.d) <= Date.parse(cutout))
        {
          protoPatchMap[row.p] = []
          protoPatchMap[row.p].push(row)
        }
      })
      rows = Object.values(protoPatchMap).map(row =>
      {
        return (
          <PlacesDataViewCurrentPatchPanelRow patch={ row } cutout={ cutout } key={ key++ } />
        )
      })
    }

    return (
      <table className="table_ly table table-bordered table-hover" >
        <thead>
          <tr>
            <th className='col' style={ { width: '30%' } } key='key'>
              Key
            </th>
            <th className='col' style={ { width: '50%' } } key='attribute'>
              Attr
            </th>
            <th className='col' style={ { width: '20%' } } key='confidence'>
              Conf
            </th>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </table>
    )
  }
}


export default connect()(PlacesDataViewCurrentPatchPanel);
