
// This file is mostly a switch so that we can build different apps with this same
// create-react-app infrastructure.
//
// Specifically, we use the env variable
// REACT_APP_NAME to control which app gets built here. The individual apps have
// index.js files that resemble a more conventional create-react-app index.js except
// that they are wrapped in functions so that they can be imported without invoking.
//
// "But doesn't this mean that all the apps get packed into each other?", you say. It
// would, except that we are relying on Webpack to tree-shake the other apps out
// given that this if statement essentially renders the other apps as dead code. As
// of 07/2019, this required the "sideEffects": false option to be set in the
// package.json file. I think it can also be done with __PURE__ comments but I
// haven't really explored that very well yet.
import ExplorerApp from './explorer/index';
import ValiadtorApp from './validator/index';
import AdminApp from './admin/index';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import './index.scss';

// Polyfills
import E6Promise from 'es6-promise';

E6Promise.polyfill();

Sentry.init({
  dsn: "https://c44d6dcf62fc43d896c197b4617c9a32@o181345.ingest.sentry.io/4504618710401024",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// Switch
const appName = process.env.REACT_APP_NAME

if (appName === 'explorer')
{
  ExplorerApp();
} else if (appName === 'validator')
{
  ValiadtorApp();
} else if (appName === 'admin')
{
  AdminApp();
}
