import React from 'react';
import { connect } from 'react-redux';

import AnalysisActions from '../actions/analysis_actions';
import Actions from '../actions/analyzable_actions';
import AnalysisResults from './analysis_results';
import Loading from '../../shared/components/loading';

class AnalyzePanel extends React.Component
{
  /**
   * @param {any} props
   */
  constructor(props)
  {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  componentDidMount()
  {
    const analPath = this.props.x.analysis_path
    const chainsPath = this.props.x.chains_path

    let analPathSplit, analBucket, analKey, chainsPathSplit, chainsBucket, chainsKey
    if (analPath)
    {
      analPathSplit = analPath.split('/')
      analBucket = analPathSplit[2]
      analKey = `${analPathSplit[3]}/${analPathSplit[4]}`
    }
    if (chainsPath)
    {
      chainsPathSplit = chainsPath.split('/')
      chainsBucket = chainsPathSplit[2]
      chainsKey = `${chainsPathSplit[3]}/${chainsPathSplit[4]}`
    }


    const promises = []
    if (analPath)
      promises.push(AnalysisActions.downloadFromBucket(this.props.x.id, analBucket, analKey, this.props.type))
    if (chainsPath)
      promises.push(AnalysisActions.downloadFromBucket(this.props.x.id, chainsBucket, chainsKey, this.props.type))

    Promise.all(promises).then(() =>
    {
      this.setState({ loaded: true })
      if (window.report === undefined)
        window.report = {}
      window.report.analysis = JSON.parse(JSON.stringify(this.props.x.analysis))
      window.report.chains = window.report.analysis.chains
    })
  }

  render()
  {
    const { x } = this.props;
    let regionsAlert, optional_params;
    if (x.optional_attributes !== undefined)
    {
      optional_params = (
        <div className="alert alert-info" role="alert">
          Optional parameters:<hr />
          <ul>
            { x.optional_attributes.map((x, index) => <li key={ index }>{ x }</li>) }
          </ul>
        </div>
      )
    }

    if (x.regions === undefined)
    {
      regionsAlert = (
        <div className="alert alert-warning" role="alert">
          Warning!<hr />
          Regions are not set!
        </div>
      )
    }
    else
    {
      regionsAlert = (
        <div className="alert alert-primary" role="alert">
          Regions:<hr />
          <ul>
            { x.regions.map((x, index) => <li key={ index }>{ x }</li>) }
          </ul>
        </div>
      )
    }


    if (this.state.loaded)
    {
      return (
        <div className="deliveries-data mb-2">
          { optional_params }
          { regionsAlert }
          <AnalysisResults analysis={ x.analysis } renderDups={ this.props.x.duplicates_path ? false : true } onRemove={ this.onRemove } />
        </div>
      )
    } else
    {
      return <Loading />
    }
  }

  analyzeStatus = () =>
  {
    const { x } = this.props;
    if (!x.analyze_queued_at && !x.analyzed_at)
    {
      return 'unanalyzed';
    } else if (!x.analyzed_at || (x.analyze_queued_at && x.analyze_queued_at > x.analyzed_at))
    {
      return 'analyzing';
    } else
    {
      return 'completed';
    }
  }

  onRemove = (id) =>
  {
    Actions.destroy(this.props.x.id, [id], this.props.type)
  }

  analyze = () =>
  {
    this.setState({ loaded: false });
    AnalysisActions.create(this.props.x.id, this.props.type).then(() =>
    {
      this.setState({ loaded: true });
    });
  }
}

const mapStateToProps = (state, props) =>
{
  return {
    x: state.getIn([props.type, props.match.params.id])
  };
};

const mapDispatchToProps = {
  updateDelivery: (data, type) => ({ type: `${type}.merge`, data })
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AnalyzePanel);
