import React from 'react';
import { connect } from 'react-redux';
import PlacesDataViewPatchPanelRow from './places_dataview_patch_panel_row'

class PlacesDataViewPatchPanel extends React.Component
{

  render()
  {

    const { patch, cutout } = this.props
    let key = 0
    let rows
    if (patch)
    {
      rows = patch.map(row =>
      {
        if (cutout === undefined || (new Date(row.d) <= new Date(cutout)))
        {
          return <PlacesDataViewPatchPanelRow patch={ row } key={ key++ } />
        }
        else
        {
          return null
        }
      })
    }


    return (
      <table className="table_ly table table-bordered table-hover" >
        <thead>
          <tr>
            <th className='col' style={ { width: '30%' } } key='key'>
              Key
            </th>
            <th className='col' style={ { width: '50%' } } key='attribute'>
              Attr
            </th>
            <th className='col' style={ { width: '20%' } } key='confidence'>
              Conf
            </th>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </table>
    )
  }
}


export default connect()(PlacesDataViewPatchPanel);
