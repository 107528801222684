import Immutable from 'immutable';

export const raw = {
  "type": "object",
  "properties": {
    "recurrances": {"type": "object"},
    "problems": {"type": "object"},
    "distributions": { "type": "object"},
    "statistics": { "type": "object"},
    "count": { "type": "number"}
  }, required:["recurrances", "problems", "distributions", "statistics", "count"]
}
export default Immutable.fromJS(raw)
