import React from 'react';
import AnalysisPlacesChainBrandsCard from './analysis_places_chain_brands_card';


class AnalysisPlacesChainsCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
    };
    const { stats } = this.props;
    if (stats === undefined)
      return
  }

  render()
  {
    const { chains, id, typeResult } = this.props


    let brands

    if (this.state.open)
    {
      brands = Array.from(chains.keys()).map((key, index) =>
      {
        return < AnalysisPlacesChainBrandsCard brand={ chains.get(key) } name={ key } key={ index } typeResult={ typeResult } id={ id } />
      })
    }

    return (
      <div className="card border-info mb-2">
        <div className="card-header text-info cursor-pointer" onClick={ this.toggle }>
          Chains: [{ Array.from(this.props.chains.keys()).length }]
        </div>
        { brands }
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default AnalysisPlacesChainsCard;
