import Immutable from 'immutable';

const defaults = {
  id: null,
  uuid: null,
  type: null,
  name: null,
  notes: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
  regions: null,
  analysis: null,
  analysis_path: null,
  duplicates_path: null,
  optional_attributes: null,
  chains_path: null,
  analyze_queued_at: null,
  analyzed_at: null,

};


class Delivery extends Immutable.Record(defaults) {

}


export default Delivery;
