import React from 'react';
import { connect } from 'react-redux';

class BrandsComparePlacesNameExplanationPanel extends React.Component
{

  render()
  {
    const { name_explanation, place } = this.props;
    if (!name_explanation)
    {
      return null
    }
    let rows

    rows = Object.keys(name_explanation.names).map(key =>
    {
      return (
        <tr key={ key } >
          <td className='text-truncate'>
            { place.name }
          </td>
          <td >
            { key }
          </td>
          <td >
            { name_explanation.names[key].total }
          </td>
        </tr>
      )
    })

    return (
      <div className='card mb-2 p-0'>
        <div className='card-header bg-info'>
          <div className='row text-white justify-content-between fs-1 fw-bold'>
            <div className='col-9 fw-bold'>
              Name explanation
            </div>
            <div className='col-auto fw-bold'>
              Score: { name_explanation.score.toFixed(2) }
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row mb-1'>
            Place name: { name_explanation.place_name }
            <a target="_blank" key={ 'adminNext' } className="badge badge-places m-1" href={ `http://admin.dataplor.com/places/${place.id}` } rel="noreferrer">{ place.id }</a>
          </div>
          <div className='row'>
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr >
                  <th className='w-25'>Place Name</th>
                  <th >Name</th>
                  <th className='w-25'>Score</th>
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </table>
          </div>
        </div>

      </div>
    )
  }
}

export default connect(null)(BrandsComparePlacesNameExplanationPanel);
