import React from 'react';
import BrandReviewCard from './brand_review_card';
import PlaceReviewCard from './place_review_card';

class ChainReviewIndex extends React.PureComponent
{
  render()
  {
    const { brand, place } = this.props;
    return (
      <div>
        <div className='row' style={ { paddingBottom: 200 } }>
          <div className='card col m-2 ml-3 border-primary' style={ { marginBottom: 100 } }>
            <div className='card-body'>
              <BrandReviewCard brand={ brand } />
            </div>
          </div>
          <div className='card col m-2 mr-3 border-info' style={ { marginBottom: 100 } }>
            <div className='card-body'>
              <PlaceReviewCard place={ place } />
            </div>
          </div>
        </div>
        <div className="row fixed-bottom border border-success mx-1">
          <div className='card col-12 justify-content-center'>
            <form className="row px-4" action="" id="radioG" >
              <p className="col-12 text-center fw-bold h4">Is this a { brand.name }?</p>
              <div className='col-12 d-flex justify-content-center'>
                <div className="form-check form-check-inline mx-2 my-2">
                  <input className="form-check-input" type="radio" name="radio" id="radioId1" />
                  <label className="form-check-label h4 text-danger" htmlFor="radioId1" >
                    👎  Definitely not
                  </label>
                </div>
                <div className="form-check form-check-inline mx-2 my-2">
                  <input className="form-check-input " type="radio" name="radio" id="radioId2" />
                  <label className="form-check-label text-warning h4" htmlFor="radioId2">
                    🧐  Probably not
                  </label>
                </div>
                <div className="form-check form-check-inline mx-2 my-2">
                  <input className="form-check-input" type="radio" name="radio" id="radioId3" />
                  <label className="form-check-label h4 text-secondary" htmlFor="radioId3">
                    🤷‍♀️  Not sure
                  </label>
                </div>
                <div className="form-check form-check-inline mx-2 my-2">
                  <input className="form-check-input" type="radio" name="radio" id="radioId4" />
                  <label className="form-check-label text- info h4" htmlFor="radioId4">
                    🤞  Probably
                  </label>
                </div>
                <div className="form-check form-check-inline mx-2 my-2">
                  <input className="form-check-input" type="radio" name="radio" id="radioId5" />
                  <label className="form-check-label h4 text-success" htmlFor="radioId5">
                    👍  Definitely
                  </label>
                </div>
              </div>
            </form>
            <div className='row'>
              <div className=" col d-flex justify-content-center">
                <button type="button" onClick={ this.onSubmit } className="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }

  onSubmit = () =>
  {
    let selected = -1
    if (document.getElementById("radioId1").checked)
      selected = 0
    else if (document.getElementById("radioId2").checked)
      selected = 0.25
    else if (document.getElementById("radioId3").checked)
      selected = 0.5
    else if (document.getElementById("radioId4").checked)
      selected = 0.75
    else if (document.getElementById("radioId5").checked)
      selected = 1
    if (selected !== -1)
      return this.props.onSubmit(selected);
  }
  onSelected = (e) =>
  {
    console.log(e)
  }

}

export default ChainReviewIndex;
