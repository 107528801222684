import React from 'react';
import { connect } from 'react-redux';
import TasksPdrtCardBody from './tasks_pdrt_card_body';
import TasksBotCallAuditCardBody from './tasks_botcallaudit_card_body'
import LiveDate from './live_date';
import cn from 'classnames';
import { Link } from 'react-router-dom';

class TasksDetailsPanel extends React.Component
{

//<pre>{JSON.stringify(place.toJSON(),null,2)}</pre>
  render()
  {
    const { task } = this.props;

    let body;
    switch(task.type){
      case 'PlaceDuplicatesReviewTask':
        body = <TasksPdrtCardBody {...this.props} />
        break;
      case 'BotCallAuditTask':
        body = <TasksBotCallAuditCardBody {...this.props} />
        break;
      default:
        body = <TasksPdrtCardBody {...this.props} />
        break;
    }

    let createdAt;
    if(task.created_at)
    {
      createdAt = <span>, validated <LiveDate date={task.created_at} /></span>
    }

    const cardClasses = cn({
      'border-success': task.priority,
      'border-danger': task.priority !== undefined
    },'card mb-2');

    return (
      <div className={cardClasses}>
        <div className="card-header" onClick={this.toggleOpen}>
          <div className="row">
            <div className="col-6">
              <h5 className="card-title mb-0"><Link to={`/tasks/${task.id}`} target="_blank" className="text-monospace">{task.id}</Link> {task.type}</h5>
            </div>
            <div className="col-6 text-right">
              <span className="small">Created at: <LiveDate date={task.created_at} />{createdAt}</span>
            </div>
          </div>
        </div>
        <div className="card-body" style={{display: (null)}}>
          {body}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    task: state.getIn(['tasks',props.match.params.id])
  };
};

export default connect(mapStateToProps,null)(TasksDetailsPanel);
