import React from 'react';
import { connect } from 'react-redux';
import ObservationsPatchRow from './observations_patch_row'
import ManualReviewTaskTable from './manual_review_task_table'
import { raw } from '../../shared/schemas/data_schema';
import ImageWithMetadata from '../../shared/components/image_with_metadata';
import PlacesActions from '../actions/places_actions';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class ObservationsPatchPanel extends React.Component
{

  constructor(props)
  {
    super(props)
    this.state = {
      switch: false,
      sortBy: 'c',
      filter: "",
      order: 'asc'
    }
  }

  componentDidMount()
  {
    PlacesActions.show(this.props.place.id)
  }

  onSortChangeA = e =>
  {
    this.onSortChange('p')
  }
  onSortChangeC = e =>
  {
    this.onSortChange('c')
  }
  onSortChangeV = e =>
  {
    this.onSortChange('v')
  }

  onSortChange = e =>
  {
    const sortBy = e
    const currentSortBy = this.state.sortBy

    if (sortBy === currentSortBy)
    {
      if (this.state.order === 'asc')
        this.setState({ order: 'des' })
      else
        this.setState({ order: 'asc' })
    }

    else
    {
      switch (sortBy)
      {
        case 'c':
          this.setState({ sortBy: 'c' })
          break
        case 'p':
          this.setState({ sortBy: 'p' })
          break
        case 'v':
          this.setState({ sortBy: 'v' })
          break
        default:
          this.setState({ sortBy: 'c' })
      }
    }
  }


  onSwitchChange = (e) =>
  {
    this.setState({ switch: e.target.checked })
  }

  onFilterBlur = e =>
  {
    this.setState({ filter: e.target.value })
  }

  render()
  {
    const { observation, place, images } = this.props;

    const patch = observation.patch
    const attrOrder = Object.keys(raw.properties)
    let rows, dropdown, filter


    if (patch && this.state.switch === true)
    {
      rows = attrOrder.map(x =>
      {
        if (x === 'coordinates')
        {
          let latitude = patch.find(attribute => attribute.get("p") === '/latitude')
          const longitude = patch.find(attribute => attribute.get("p") === '/longitude')
          if (latitude && longitude)
          {
            latitude = latitude.set('v', `${latitude.get("v")}, ${longitude.get("v")}`)
            latitude = latitude.set('p', "coordinates")
            return <ObservationsPatchRow patch={ latitude } key={ x } />
          }
        }
        let value = patch.find(attribute => attribute.get("p").substring(1) === x.toString())
        if (value)
          return <ObservationsPatchRow patch={ value } key={ x } />
        else
          return <ObservationsPatchRow attribute={ x } key={ x } />

      })
    }
    else if (patch)
    {
      let keys = patch.map((value, index) =>
      {
        return value
      })

      let sortBy = this.state.sortBy

      keys = keys.sort((a, b) =>
      {
        if (this.state && this.state.order === 'asc')
          return a.get(sortBy).toString().localeCompare(b.get(sortBy).toString())
        else
          return b.get(sortBy).toString().localeCompare(a.get(sortBy).toString())
      });


      rows = keys.map(x =>
      {
        if ((x.get('p').toLowerCase().includes(this.state.filter.toLowerCase())) || (x.get('v') && x.get('v').toString().toLowerCase().includes(this.state.filter.toLowerCase())))
          return <ObservationsPatchRow patch={ x } key={ x } />
        else
          return null
      })

      dropdown = <UncontrolledDropdown className='my-1'>
        <DropdownToggle>
          Sort by:
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={ this.onSortChangeC }>
            Confidence
          </DropdownItem>
          <DropdownItem onClick={ this.onSortChangeA }>
            Attribute
          </DropdownItem>
          <DropdownItem onClick={ this.onSortChangeV }>
            Value
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      filter = <div>
        <input type="email" className="form-control" id="filter" placeholder="filter" onChange={ this.onFilterBlur } />
      </div>
    }


    let rImages
    if (images)
      rImages = images.toIndexedSeq().sortBy(i => i.id * -1).map(image =>
      {
        if (image)
        {
          return (
            <div className="row" key={ image.id }>
              <div className="col mb-3 d-flex justify-content-center">
                <ImageWithMetadata
                  image={ image }
                  urlKey={ image.key }
                  assetsServer={ assetsServer }
                  role={ image.role }
                  latitude={ place.latitude }
                  longitude={ place.longitude }
                />
              </div>
            </div>
          );
        }
        else
        {
          return null;
        }
      });




    // const sortedData = place.data.toKeyedSeq().sortBy((v,k) => ((attrOrder.indexOf(k)+1) || 999 ))

    const mrtt = <ManualReviewTaskTable place={ place } />


    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-header h5">
                Current place data:
              </div>
              <div className="card-body">
                <pre>
                  { mrtt }
                </pre>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <div className="card-header h5">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="viewSwitch" onChange={ this.onSwitchChange } />
                  <label className="custom-control-label" htmlFor="viewSwitch">Switch view</label>
                </div>
                Patch:
                { dropdown }
                { filter }
              </div>
              <div className="card-body">
                { rows }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header h5">
                Images:
              </div>
              <div className="card-body">
                { rImages }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) =>
{
  const observation = state.getIn(['observations', props.match.params.id]);
  const place = state.getIn(['places', observation.relationships.get('place')]);
  const imagesIds = place.get('relationships').has('images') ? place.get('relationships').get('images') : undefined
  let images
  if (imagesIds)
    images = state.get('images').filter(i => imagesIds.contains(i.id.toString()));
  return {
    observation: observation,
    place: place,
    images: images
  };
};

export default connect(mapStateToProps)(ObservationsPatchPanel);
