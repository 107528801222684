import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import DeliveriesExportActions from '../actions/deliveries_export_actions';

class DeliveriesGeneratePanel extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      generating: false,
      generated: false,
    }
  }

  render(){
    let label;

    if(this.state.generating){
      label = <FontAwesomeIcon icon={faSpinner} spin className="mx-auto d-block" size="lg"/>;
    } else {
      if(this.state.generated){
        label = "Generated"
      } else {
        label = "Generate New File"
      }
    }

    return (
      <div className="samples-data mb-2">
        <button className="btn btn-danger col-2" onClick={this.generate}>{label}</button>
      </div>
    )
  }

  generate = () => {
    this.setState((prevState) => {
      return {generating: true}
    });
    DeliveriesExportActions.create(this.props.delivery.id).then(response => {
      this.setState({generating: false, generated: true});
    })
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    delivery: state.getIn(['deliveries',props.match.params.id])
  };
};

export default connect(mapStateToProps,null)(DeliveriesGeneratePanel);
