import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import ExternalIdAutoLinker from './external_id_autolinker';
import ImageWithMetadata from '../../shared/components/image_with_metadata';
import DuplicatePlaceComparisonPanelRow from './duplicate_place_comparison_panel_row';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class DuplicatePlaceComparisonPanel extends React.PureComponent
{

  generateRowDistance(lat1, long1, lat2, long2, score)
  {
    let scoreI = Math.floor(score)

    const scaleClass = `scale-bg-${scoreI} text-white`
    const textColor = `text-white`

    const middleRow = <td className={ `span1 text-center ${scaleClass}` } style={ { width: "10%" } }>Distance <br /> { score }%</td>

    //<a href={ link } target="_blank" rel="noopener noreferrer" className="">{ value }</a>
    const link1 = `https://www.google.com/maps/?q=${lat1},${long1}`
    const link2 = `https://www.google.com/maps/?q=${lat2},${long2}`

    return (
      <tr style={ { height: "50px" } } key={ 'Distance' }>
        <td className={ `span4 text-center align-middle ${textColor}` } style={ { width: "45%" } }>
          <a href={ link1 } target="_blank" rel="noopener noreferrer" className="">{ `${lat1}, ${long1}` }</a>
        </td>
        { middleRow }
        <td className={ `span4 text-center align-middle ${textColor}` } style={ { width: "45%" } }>
          <a href={ link2 } target="_blank" rel="noopener noreferrer" className="">{ `${lat2}, ${long2}` }</a>
        </td>
      </tr>
    )
  }

  generateRowExternalIds(value1, value2, attr)
  {
    let scoreI = value1 === value2 ? 100 : 0
    if (scoreI === 0)
    {
      if (value1 === undefined || value2 === undefined)
        scoreI = 50
    }

    let scaleClass, borderColor

    switch (scoreI)
    {
      case 100:
        scaleClass = `bg-success text-white`
        borderColor = 'border border-5 border-success text-success'
        break
      case 50:
        scaleClass = `text-white`
        borderColor = 'border border-5 border-secondary'
        break
      case 0:
        scaleClass = 'bg-danger text-white'
        borderColor = 'border border-5 border-danger text-danger'
        break
    }

    let middleRow = <td className={ `span1 text-center ${scaleClass}` } style={ { width: "10%" } }>{ attr } <br /></td>
    return (
      <tr className='border order-primary border-5' style={ { height: "50px" } } key={ attr }>
        <ExternalIdAutoLinker type={ attr } value={ value1 } borderColor={ borderColor } />
        { middleRow }
        <ExternalIdAutoLinker type={ attr } value={ value2 } borderColor={ borderColor } />
      </tr>
    )
  }

  generateTableExternalIds(response)
  {

    if (response === undefined || response === null)
      return null

    const place1ExtIds = response.data[0].attributes.external_ids
    const place2ExtIds = response.data[1].attributes.external_ids

    const place1Keys = Object.keys(place1ExtIds)
    const place2Keys = Object.keys(place2ExtIds)

    const keysWithDups = place1Keys.concat(place2Keys)
    const keys = [...new Set(keysWithDups)];

    const tableContent = []

    keys.forEach((key) =>
    {
      const valP1 = place1ExtIds[key]
      const valP2 = place2ExtIds[key]

      //console.log(key, valP1, valP2)

      if (valP1 && valP2)
      {
        tableContent.unshift({ key: key, valP1: valP1, valP2: valP2 })
      }
      else
        tableContent.push({ key: key, valP1: valP1, valP2: valP2 })
    })

    const rows = tableContent.map(element =>
    {
      return this.generateRowExternalIds(element.valP1, element.valP2, element.key)
    })
    return rows
  }

  generatePic(image, place)
  {
    return (
      <div className="col">
        <ImageWithMetadata
          image={ image }
          urlKey={ image.key }
          assetsServer={ assetsServer }
          role={ image.role }
          latitude={ place.latitude }
          longitude={ place.longitude }
        />
      </div>
    )
  }

  render()
  {
    const { place1, place2, imagesP1, imagesP2, explain, response } = this.props;

    let nameW = explain.getIn(['name', 'weight'])
    let addressW = explain.getIn(['address', 'weight'])
    let neighborhoodW = explain.getIn(['neighborhood', 'weight'])
    let cityW = explain.getIn(['city', 'weight'])
    let postal_codeW = explain.getIn(['postal_code', 'weight'])
    let countryW = explain.getIn(['country', 'weight'])
    let distanceW = explain.getIn(['distance', 'weight'])
    let emailW = explain.getIn(['email', 'weight'])
    let phoneW = explain.getIn(['phone', 'weight'])
    let mobileW = explain.getIn(['mobile', 'weight'])
    //let buscatIdW = explain.getIn(['business_category_id', 'weight'])

    let nameS = explain.getIn(['name', 'score'])
    let addressS = explain.getIn(['address', 'score'])
    let neighborhoodS = explain.getIn(['neighborhood', 'score'])
    let cityS = explain.getIn(['city', 'score'])
    let stateS = explain.getIn(['state', 'score'])
    let postal_codeS = explain.getIn(['postal_code', 'score'])
    let countryS = explain.getIn(['country', 'score'])
    let distanceS = explain.getIn(['distance', 'score'])
    let emailS = explain.getIn(['email', 'score'])
    let phoneS = explain.getIn(['phone', 'score'])
    let mobileS = explain.getIn(['mobile', 'score'])
    let buscatIdS = explain.getIn(['business_category_id', 'score'])

    if (explain)
    {
      if (nameS != null)
        nameS = (nameS * 100).toFixed(2)

      if (addressS != null)
        addressS = (addressS * 100).toFixed(2)

      if (neighborhoodS != null)
        neighborhoodS = (neighborhoodS * 100).toFixed(2)

      if (cityS != null)
        cityS = (cityS * 100).toFixed(2)

      if (stateS != null)
        stateS = (stateS * 100).toFixed(2)

      if (postal_codeS != null)
        postal_codeS = (postal_codeS * 100).toFixed(2)

      if (buscatIdS != null)
        buscatIdS = (buscatIdS * 100).toFixed(2)

      if (distanceS != null)
        distanceS = (distanceS * 100).toFixed(2)

      if (countryS != null)
        countryS = (countryS * 100).toFixed(2)

      if (mobileS != null)
        mobileS = (mobileS * 100).toFixed(2)

      if (emailS != null)
        emailS = (emailS * 100).toFixed(2)

      if (phoneS != null)
        phoneS = (phoneS * 100).toFixed(2)
    }

    let rowsP1
    if (imagesP1)
      rowsP1 = imagesP1.toIndexedSeq().sortBy(i => i.id * -1).map(image =>
      {
        if (image)
        {
          return (
            <div className="col" key={ image.key }>
              <ImageWithMetadata
                image={ image }
                urlKey={ image.key }
                assetsServer={ assetsServer }
                role={ image.role }
                latitude={ place1.latitude }
                longitude={ place1.longitude }
              />
            </div>
          );
        }
        else
        {
          return null;
        }
      });

    let rowsP2
    if (imagesP2)
      rowsP2 = imagesP2.toIndexedSeq().sortBy(i => i.id * -1).map(image =>
      {
        if (image)
        {
          return (
            <div className="col" key={ image.key } style={ { "width": "200px" } }>
              <ImageWithMetadata
                image={ image }
                urlKey={ image.key }
                assetsServer={ assetsServer }
                role={ image.role }
                latitude={ place2.latitude }
                longitude={ place2.longitude }
              />
            </div>
          );
        }
        else
        {
          return null;
        }
      });

    const external_ids_table = this.generateTableExternalIds(response)

    return (
      <div className={ cn("card mb-5") }>
        <div className="card-body">
          <div className="row mb-12">
            <div className="col-sm-5">
              <div className="row container" style={ { overflowX: "auto", whiteSpace: "nowrap", overflowY: "hidden" } }>
                { rowsP1 }
              </div>
            </div>
            <div className="col-sm-2" />
            <div className="col-sm-5">
              <div className="row container" style={ { overflowX: "auto", whiteSpace: "nowrap", overflowY: "hidden" } }>
                { rowsP2 }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <tbody>
                  <DuplicatePlaceComparisonPanelRow value1={ place1.name } value2={ place2.name } score={ nameS } name="Name" weight={ nameW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.address } value2={ place2.address } score={ addressS } name="Address" weight={ addressW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.neighborhood } value2={ place2.neighborhood } score={ neighborhoodS } name="Neighborhood" weight={ neighborhoodW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.city } value2={ place2.city } score={ cityS } name="City" weight={ cityW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.state } value2={ place2.state } score={ stateS } name="State" weight={ 1 } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.postal_code } value2={ place2.postal_code } score={ postal_codeS } name="Postal code" weight={ postal_codeW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.country } value2={ place2.country } score={ countryS } name="Country" weight={ countryW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.business_category_id } value2={ place2.business_category_id } score={ 0 } name="Business Cat Id" />
                  { this.generateRowDistance(place1.latitude, place1.longitude, place2.latitude, place2.longitude, distanceS, distanceW) }
                  <DuplicatePlaceComparisonPanelRow value1={ place1.getIn(['data', 'email']) } value2={ place2.getIn(['data', 'email']) } score={ emailS } name="Email" weight={ emailW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.getIn(['data', 'phone']) } value2={ place2.getIn(['data', 'phone']) } score={ phoneS } name="Phone" weight={ phoneW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.getIn(['data', 'mobile']) } value2={ place2.getIn(['data', 'mobile']) } score={ mobileS } name="Mobile" weight={ mobileW } />
                  <DuplicatePlaceComparisonPanelRow value1={ place1.getIn(['data', 'chain_id']) } value2={ place2.getIn(['data', 'chain_id']) } name="Chain id" />
                  <DuplicatePlaceComparisonPanelRow value1={ "" } value2={ "" } score={ 100 } name="External ids" weight={ 1 } />


                  { external_ids_table }

                  <tr>
                    <td className="span4 text-center"><a href={ `/places/${place1.id}/edit` }> Go to place</a></td>
                    <td className="span1 text-center">Links</td>
                    <td className="span4 text-center"><a href={ `/places/${place2.id}/edit` }> Go to place</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state, props) =>
{
  const imagesp1 = props.place1.getIn(['relationships', 'images']);
  const imagesp2 = props.place2.getIn(['relationships', 'images']);
  const explain = state.getIn(['page', 'explain'])
  let imagesPlace1, imagesPlace2
  if (imagesp1)
    imagesPlace1 = state.get('images').filter(i => imagesp1.contains(i.id.toString()))
  if (imagesp2)
    imagesPlace2 = state.get('images').filter(i => imagesp2.contains(i.id.toString()))
  return {
    imagesP1: imagesPlace1,
    imagesP2: imagesPlace2,
    explain: explain,
  };
};

export default connect(mapStateToProps)(DuplicatePlaceComparisonPanel);
