import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import * as countries from '../../shared/data/countries.json';
import * as languages from '../../shared/data/languages.json';

class BrandsFormPanelLocale extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  validateLocale = (localeToValidate) => {
    if (localeToValidate === undefined || localeToValidate === null) {
      this.props.updateErrors(this.props.brand.id, this.props.data.id, 'Missing locale')
      this.setState({ error: 'missing locale' })
      return false
    }

    if (localeToValidate.length !== 5) {
      this.props.updateErrors(this.props.brand.id, this.props.data.id, 'wrong locale')
      this.setState({ error: 'wrong locale' })
      return false
    }

    const splitted = localeToValidate.split('_')
    if (splitted.length !== 2) {
      this.props.updateErrors(this.props.brand.id, this.props.data.id, 'wrong locale')
      this.setState({ error: 'wrong locale' })
      return false
    }

    const country = splitted[1]
    const language = splitted[0]

    var countryFound = false
    for (var i = 0; i < countries.default.countrycodes.length; ++i) {
      if (countries.default.countrycodes[i].code.toLowerCase() === country) {
        countryFound = true
        break;
      }
    }
    var languageFound = false
    for (var j = 0; j < languages.default.languagecodes.length; ++j) {
      if (languages.default.languagecodes[j].code.toLowerCase() === language) {
        languageFound = true
        break;
      }
    }

    if (languageFound === false || countryFound === false) {
      this.props.updateErrors(this.props.brand.id, this.props.data.id, 'wrong locale')
      this.setState({ error: 'wrong locale' })
      return false
    }

    this.setState({ error: null })
    return true
  }

  validateName = (name) => {
    if (!name) {
      this.props.updateErrors(this.props.brand.id, this.props.data.id, 'no name')
      this.setState({ error: 'no name' })
      return false
    }

    this.props.removeErrors(this.props.brand.id, this.props.data.id)
    this.setState({ error: null })
    return true
  }

  componentDidMount() {
    this.validateName(this.props.data.name)
    this.validateLocale(this.props.data.locale)
  }

  removeRow = () => {
    this.props.removeErrors(this.props.brand.id, this.props.data.id)
    this.props.removeRow(this.props.brand.id, this.props.data.id)
  }

  onChange = (e) => {
    const value = e.target.value.trim();

    switch (e.target.id) {
      case 'locale':
        this.props.updateRow(this.props.brand.id, this.props.data.id, { 'locale': value })
        this.validateLocale(value)
        break;
      case 'name':
        this.props.updateRow(this.props.brand.id, this.props.data.id, { 'name': value })
        this.validateName(value)
        break;
      case 'aliases':
        this.props.updateRow(this.props.brand.id, this.props.data.id, { 'aliases': value })
        break;
      default:
        break;
    }
  }

  render() {
    const { data } = this.props
    const errorMessage = this.state.error
    const trashButton = <FontAwesomeIcon icon={ faTrash } className="col-1 my-auto" size="lg" color={ "red" } onClick={ this.removeRow } />
    return <div>
      <div className="row pl-3 mb-1">
        <input type="text" className="mx-1 form-control col-2" id="locale" placeholder='Locale' defaultValue={ data.locale } onChange={ this.onChange } />
        <input type="text" className="mx-1 form-control col" id="name" placeholder='Name' defaultValue={ data.name } onChange={ this.onChange } />
        <input type="text" className="mx-1 form-control col" id="aliases" placeholder='Aliases' defaultValue={ data.aliases } onChange={ this.onChange } />
        { trashButton }
      </div>
      <div className="row pl-4 mb-1 text-danger">
        { errorMessage }
      </div>
    </div>
  }
}

const mapDispatchToProps = {
  updateRow: (id, formKey, changes) => ({ type: 'forms.merge', data: { 'brands/edit': { [id.toString()]: { 'translations': { [formKey]: changes } } } } }),
  updateErrors: (id, formKey, errors) => ({ type: 'form_errors.merge', data: { 'brands/edit': { [id.toString()]: { 'translations': { [formKey]: errors } } } } }),
  removeErrors: (id, formKey) => ({ type: 'forms_errors.delete_in', data: [[id.toString(), 'translations', formKey]] }),
  removeRow: (id, formKey) => ({ type: 'forms.delete_in', data: [['brands/edit', id.toString(), 'translations', formKey]] }),
}

export default connect(null, mapDispatchToProps)(BrandsFormPanelLocale);
