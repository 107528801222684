import React from 'react';
import NominatimPlaceActions from '../actions/nominatim_place_actions';
import { connect } from 'react-redux';

class BrandsComparePlacesCoordinatesExplanationPanel extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      loaded: false,
      response: undefined
    };
  }

  componentDidMount()
  {
    this.fetchData()
  }

  render()
  {
    const { coordinates_explanation } = this.props;
    if (!coordinates_explanation || this.state.loaded === false)
    {
      return null
    }
    const jsonNomResponse = this.state.response
    const nominatim_id = coordinates_explanation.intersecting_geo ? coordinates_explanation.intersecting_geo.substring(1) : undefined

    let nom_link = 'null'
    if (nominatim_id)
      nom_link = <a target="_blank" key='nom' href={ `https://nominatim.openstreetmap.org/details.php?osmtype=R&osmid=${nominatim_id}` } rel="noreferrer"> { nominatim_id + ' , ' + jsonNomResponse.localname }</a>

    return (
      <div className='card mb-1'>
        <div className='card-header  bg-info'>
          <div className='row text-white justify-content-between fs-1 fw-bold'>
            <div className='col-9 fw-bold'>
              Coordinates explanation
            </div>
            <div className='col-auto fw-bold'>
              Score: { coordinates_explanation.score }
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            Intersecting Geo:  { nom_link }
          </div>

        </div>

      </div>
    )
  }

  fetchData = (id = this.props.coordinates_explanation.intersecting_geo) =>
  {
    if (id)
      NominatimPlaceActions.show(id.substring(1)).then((response) =>
      {
        response.json().then(response =>
        {
          this.setState({ loaded: true, response: response });
        })

      })
    else
    {
      this.setState({ loaded: true })
    }
  }

}

export default connect(null)(BrandsComparePlacesCoordinatesExplanationPanel);
