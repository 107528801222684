import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import BrandActions from '../actions/brand_actions';
import BrandsExportActions from '../actions/brands_export_actions';
import BrandsFormPanel from './brands_form_panel';
import BrandsImagesPanel from './brands_images_panel';
import BrandsComparePlacesPanel from './brands_compare_places_panel';
import AnalyzePanel from './analyze_panel';
import AnalysisDuplicatesPanel from './analysis_duplicates_panel';
import { triggerDownload } from '../helpers/download_helpers';
import LiveDate from './live_date';


class BrandsShow extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      brand: {},
      loaded: false,
      analyzePanel: false,
      analyzeVersion: null,
      analyzeOptions: [],
      analyzeStatus: null,
    };
  }

  componentDidMount()
  {
    BrandActions.show(this.props.match.params.id).then(json =>
    {
      if (json.data.id !== this.props.match.params.id)
      {
        const postPath = this.props.location.pathname.split(`${this.props.match.params.id}/`)
        this.props.history.push(`/brands/${json.data.id}/${postPath.length > 1 ? postPath[1] : ''}`)
      } else
      {
        this.setState({ loaded: true });
      }
    })
  }

  componentDidUpdate(prevProps)
  {
    if (this.props.match.params.id !== prevProps.match.params.id)
    {
      this.setState({ loaded: false });

      BrandActions.show(this.props.match.params.id).then(json =>
      {
        this.setState({ loaded: true });
      })
    }
  }

  onVersionChange = (e) =>
  {
    const value = e.target.checked
    const id = e.target.id
    this.setState({ analyzeVersion: value ? id : null })
  }

  onAnalyze = () =>
  {
    const params = {}
    params.version = this.state.analyzeVersion
    const only = this.state.analyzeOptions
    params.options = { only: only }

    BrandActions.analyze(this.props.match.params.id, params).then(json =>
    {
      this.setState({ analyzeStatus: 'Triggered' })
    })
  }

  onParamChange = (e) =>
  {
    const value = e.target.checked
    const id = e.target.id
    const params = [...this.state.analyzeOptions]
    console.log(params, this.state.analyzeOptions)
    if (params.includes(id))
    {
      //so the param is already included
      if (!value)
      {
        params.splice(params.indexOf(id), 1)
      }
    }
    else
    {
      if (value)
      {
        params.push(id)
      }
    }
    this.setState({ analyzeOptions: params })
  }


  render()
  {
    if (this.state.loaded)
    {
      const { brand } = this.props;
      const subpath = this.props.location.pathname.match(/brands\/\d+\/?(\w*)/)[1];
      const date = brand.get('analysis') ? brand.get('analysis').get('started') : null
      const analyzeButton = this.state.analyzeVersion && this.state.analyzeOptions.length > 0 ? <button type="button" className="btn btn-success mt-1" onClick={ this.onAnalyze }>Analyze</button> : <button type="button" className="btn btn-success mt-1" disabled>Analyze</button>

      const analyzePanel = (
        <div className='row mt-3 border border-success'>
          <div className='col-3'>
            <span>Params:</span>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="duplicates" onChange={ this.onParamChange } />
              <label className="form-check-label" htmlFor="duplicates">
                Duplicates
              </label>

            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="analysis" onChange={ this.onParamChange } />
              <label className="form-check-label" htmlFor="analysis">
                Analysis
              </label>

            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="chains" onChange={ this.onParamChange } />
              <label className="form-check-label" htmlFor="chains">
                Chains
              </label>
            </div>
          </div>
          <div className='col-2'>
            <span>Version:</span>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="3" onChange={ this.onVersionChange } />
              <label className="form-check-label" htmlFor="3">
                3
              </label>
            </div>
          </div>
          <div className='col-1'>
            { analyzeButton }
          </div>
          <div className='col'>
            { this.state.analyzeStatus }
          </div>
        </div >)




      return (
        <div id="brandsShow " className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-brands">{ brand.id }</span> { brand.name }</h2>
              <h5><span className="badge badge-brandsl">{ brand.uuid }</span></h5>
              <p>Added <LiveDate date={ brand.created_at } />, updated <LiveDate date={ brand.updated_at } /></p>
              <p>Analyzed at: <LiveDate date={ date } /></p>
              <p>Admin next: <a target="_blank" key={ 'adminNext' } className="badge badge-brands m-1" href={ `http://adminnext.dataplor.com/brands/${brand.id}/analysis/places` } rel="noreferrer">{ `http://adminnext.dataplor.com/brands/${brand.id}/analysis/places` }</a></p>
              <p>Website: <a target="_blank" key={ 'webSite' } className="badge badge-primary m-1" href={ brand.website } rel="noreferrer">{ brand.website }</a></p>
            </div>
          </div>
          { this.state.analyzePanel ? analyzePanel : null }
          <div className="row mt-3">
            <div className="col">
              <button className="btn btn-primary btn-success mr-2" onClick={ () => { this.setState({ analyzePanel: !this.state.analyzePanel }) } }>Analyze</button>
              <a className="mr-2" href={ `https://s3.console.aws.amazon.com/s3/buckets/dataplor-data?region=us-west-2&prefix=exports/brands/${brand.id}/&showversions=false` } rel="noreferrer" target="_blank">
                <button className="btn btn-primary">Download Finals</button>
              </a>
              <button className="btn btn-primary btn-warning" onClick={ this.downloadDraft }>Download Draft</button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="card col-12">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <Link className={ cn('nav-link', { active: (subpath === '') }) } to={ `/brands/${brand.id}` }>Data</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={ cn('nav-link', { active: (subpath === 'edit') }) } to={ `/brands/${brand.id}/edit` }>Edit</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={ cn('nav-link', { active: (subpath === 'images') }) } to={ `/brands/${brand.id}/images` }>Images</Link>
                  </li>

                  <li className="nav-item">
                    <Link className={ cn('nav-link', { active: (subpath === 'places') }) } to={ `/brands/${brand.id}/places` }>Compare</Link>
                  </li>

                  <li className="nav-item">
                    <Link className={ cn('nav-link', { active: (subpath === 'analyze') }) } to={ `/brands/${brand.id}/analyze` }>Analyze</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={ cn('nav-link', { active: (subpath === 'duplicates') }) } to={ `/brands/${brand.id}/duplicates` }>Duplicates</Link>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <Switch>
                  <Route exact path="/brands/:id">
                    <div className="brands-data mb-2">
                      <p>If you need the `key`, `translated_names`, or `external_ids` fields updated, reach out to an engineer.</p>
                      <pre>{ JSON.stringify(brand.toJSON(), null, 2) }</pre>
                    </div>
                  </Route>
                  <Route exact path="/brands/:id/edit" component={ BrandsFormPanel } />
                  <Route exact path="/brands/:id/images" component={ BrandsImagesPanel } />
                  <Route path="/brands/:id/places/:candidate_id?" component={ BrandsComparePlacesPanel } />
                  <Route exact path="/brands/:id/analyze" render={ (props) => <AnalyzePanel type={ 'brands' } { ...props } /> } />
                  <Route exact path="/brands/:id/duplicates" render={ (props) => <AnalysisDuplicatesPanel type={ 'brands' } { ...props } /> } />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      );
    } else
    {
      return 'loading...'
    }
  }

  downloadDraft = () =>
  {
    BrandsExportActions.show(this.props.brand.id).then(response =>
    {
      triggerDownload("text/csv", `\uFEFF${response.data}`, response.filename);
    })
  }

}


const mapStateToProps = (state, props) =>
{
  const id = props.match.params.id
  const brand = state.getIn(['brands', id])
  const brandC = state.getIn(['form_changes', 'brands/edit', id])
  return {
    brand: brand,
    brandC: brandC
  }
}

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BrandsShow));
