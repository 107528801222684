import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class AnalysisPlacesChainListIdsCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
    };

    const { stats } = this.props;
    if (stats === undefined)
      return
  }

  render()
  {
    const { ids, title } = this.props

    let idsUI


    if (this.state.open)
    {
      idsUI = ids.map(place =>
      {
        return <Link to={ `/places/${place}/edit` } key={ place } target="_blank" className="badge badge-places m-1">{ place }</Link>
      })
    }

    return (
      <div className="card border-info mb-2">
        <div className="card-header text-info cursor-pointer" onClick={ this.toggle }>
          { title }: [{ ids.size }]
        </div>
        <div className="col mp-5">
          { idsUI }
        </div>
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default AnalysisPlacesChainListIdsCard;
