import React from 'react';

import DuplicatePlaceComparisonPanel from './duplicate_place_comparison_panel';
import DuplicatePlaceMap from './duplicate_place_map';

class DuplicateChecksIndex extends React.PureComponent
{
  render()
  {
    return (
      <div id="duplicateChecksIndex" className="pt-4">
        <div className="row mb-4">
          <div className="col">
            <DuplicatePlaceMap original={ this.props.original } duplicate={ this.props.duplicate } />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <DuplicatePlaceComparisonPanel place1={ this.props.original } place2={ this.props.duplicate } explain={ this.props.explain } response={ this.props.response } bsClass="primary" />
          </div>
        </div>
        <div className="fixed-bottom bg-light border border-top border-solid border-dark">
          <div className="row my-3">
            <div className="col-3 ml-auto">
              <button className="col btn btn-info" onClick={ this.onDuplicate }><span role="img" aria-label="happy">😄</span> Same Place <span role="img" aria-label="happy">😄</span></button>
            </div>
            <div className="col-1">
            </div>
            <div className="col-3 mr-auto">
              <button className="col btn btn-success" onClick={ this.onDifferent }><span role="img" aria-label="holy">😇</span> Different Place <span role="img" aria-label="angry">😡</span></button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onDuplicate = (e) =>
  {
    return this.props.onMerge(true);
  }

  onDifferent = (e) =>
  {
    return this.props.onMerge(false);
  }
}

export default DuplicateChecksIndex;
