import Immutable from 'immutable';

import { getStoredAdmin,setAuth } from '../../shared/helpers/authentication_helpers';


const defaultValue = Immutable.fromJS(
  Object.assign({
    locale: 'en',
  },getStoredAdmin())
)

export default (state=defaultValue,action) => {
  switch (action.type) {
    case `current_admin.load`: {
      const loadedAdmin = Object.keys(action.data).reduce((state,key) => {
        return state.set(key,Immutable.fromJS(action.data[key]));
      },state);
      setAuth('admin',loadedAdmin.toJSON());
      return loadedAdmin;
    }
    case `current_admin.merge`: {
      const mergedAdmin = state.mergeDeep(action.data);
      setAuth('admin',mergedAdmin.toJSON());
      return mergedAdmin;
    }
    case `current_admin.delete_in`: {
      const modifiedAdmin = state.withMutations(s => {
        action.data.forEach(p => {
          s.deleteIn(p);
        });
      });
      setAuth('admin',modifiedAdmin.toJSON());
      return modifiedAdmin;
    }
    default:
      return state;
  }
};
