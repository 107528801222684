import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params, type, actionType = "load")
  {
    let query
    if (type === 'brands')
      query = Object.assign({ sort: 'brands.id', sort_dir: 'desc' }, params, { page_size: 25 });
    return authorizedFetch(`/v3/admin/${type}`, query).then(handleResponse(type, actionType));
  },
  show: function(id, type)
  {
    return authorizedFetch(`/v3/admin/${type}/${id}`).then(handleResponse(type, "merge"));
  },
  destroy: function(id, place_ids, type)
  {
    const opts = {
      method: 'DELETE',
      ids: place_ids
    };
    return authorizedFetch(`/v3/admin/${type}/${id}/places`, null, opts).then(handleResponse(type, "merge"));
  },
  create: function(type, params)
  {
    const options = { method: 'POST', body: JSON.stringify(params) };
    return authorizedFetch(`/v3/admin/${type}`, null, options).then(handleResponse(type, "merge"));
  },
  update: function(id, type, params)
  {
    const options = { method: 'PUT', body: JSON.stringify(params) };
    return authorizedFetch(`/v3/admin/${type}/${id}`, null, options).then(handleResponse(type, "merge"));
  }
};

