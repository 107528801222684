import React from 'react';
import { connect } from 'react-redux';
import ObservationsActions from '../actions/observations_actions';

class ObservationsActionsPanel extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = { segregatted: false, error: null, segregatting: false };
  }

  segregate = () =>
  {
    this.setState({ segregatting: true })
    ObservationsActions.segregate(this.props.match.params.id).then(() =>
    {
      this.setState({ segregatted: true });
      this.setState({ segregatting: false })
      this.setState({ error: null })

    }).catch(e =>
    {
      this.setState({ error: e })
      this.setState({ segregatting: false })
    })
  }

  disable = () =>
  {
    let date = null
    if (this.props.observation.disabled_at === null)
      date = (new Date()).toISOString()
    ObservationsActions.update(this.props.match.params.id, { data: { attributes: { disabled_at: date } } }).then(() =>
    {
      this.setState({ submitted: true });
      this.setState({ error: null })
    }).catch(e =>
    {
      this.setState({ error: e })
    })
  }

  render()
  {
    const { observation } = this.props;

    let segregateButton

    switch (observation.type)
    {
      case 'FacebookObservation':
      case 'GoogleMapsObservation':
        if (this.state.segregatting)
        {
          segregateButton = <div className="col align-self-end m-1">
            <div className="btn btn-info">Segregating</div>
          </div>
        }
        else if (this.state.segregatted)
        {
          segregateButton = <div className="col align-self-end m-1">
            <div className="btn btn-info">Success!</div>
          </div>
        }
        else
        {
          segregateButton = <div className="col align-self-end m-1">
            <div className="btn btn-info" onClick={ this.segregate }>Segregate</div>
          </div>
        }
        break
      default:
        break
    }
    let errors
    if (this.state.error)
    {
      if (this.state.error.errors)
      {
        errors = this.state.error.errors.map(error =>
        {
          return (
            <div key={ error.code }>
              <span className='text-danger'>{ error.code }</span>
              <span className='text-danger'>{ error.title }</span>
            </div>)
        })
      }
      else
      {
        const error = this.state.error
        errors =
          <div key={ error }>
            <span className='text-danger'>{ error.status }</span>
            <span className='text-danger'>{ error.error }</span>
          </div>
      }
    }

    const error = <div className='col align-self-end m-1'>{ errors }</div>
    const textDisableButton = (observation.disabled_at === null) ? 'Disable' : 'Enable'

    const disableButton = <div className="col align-self-end m-1">
      <div className="btn btn-danger" onClick={ this.disable }>{ textDisableButton }</div>
    </div>
    return (
      <div className="observations-data mb-2">
        { segregateButton }
        { disableButton }
        { error }
      </div>
    )
  }
}

const mapStateToProps = (state, props) =>
{
  const observation = state.getIn(['observations', props.match.params.id]);

  return {
    observation: observation,
  };
};

export default connect(mapStateToProps)(ObservationsActionsPanel);
