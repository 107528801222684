import React from 'react';

import AnalysisPlacesRecurrancesCardRow from './analysis_places_recurrances_card_row';

class AnalysisPlacesRecurrancesCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);

    this.state = {
      open: false,
    };
  }

  render()
  {
    const { ids, attribute } = this.props;

    let list;

    let keys = Array.from(ids ? ids.keys() : {});
    let values = Array.from(ids ? ids?.values() : {});


    let vals = [];
    for (var j = 0; j < keys.length; j++)
      vals.push({ 'attr': keys[j], 'value': values[j] });
    vals.sort(function(a, b)
    {
      return ((b.value - a.value));
    })

    const rows = vals.map((item, index) =>
    {
      return <AnalysisPlacesRecurrancesCardRow key={ index } value={ item.value } attribute={ item.attr } />
    });
    let height

    if (this.state.open)
    {
      list =
        <table className="table table-striped table-bordered table-sm table-hover">
          <tbody className="mb-3">
            { rows }
          </tbody>
        </table>
      height = { height: '50vh', 'overflowY': 'auto' }
    }
    return (
      <div className="card border-warning mb-2" >
        <div className="card-header text-warning cursor-pointer" onClick={ this.toggle }>
          Recurrences: { attribute } [{ (ids && ids.size) || 0 }]
        </div>
        <div className="card-body">
          <div className="col mp-5" style={ height }>
            { list }
          </div>
        </div>
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default AnalysisPlacesRecurrancesCard;
