import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import DataplorMap from '../../shared/components/dataplor_map';
import HorizontalTimeline from "react-styled-horizontal-timeline";
import PlacesDataViewPatchPanel from './places_dataview_patch_panel'
import PlacesDataViewCurrentPatchPanel from './places_dataview_current_patch_panel'
import PlacesPatchActions from '../actions/places_patch_actions'

class PlacesDataViewPanel extends React.Component
{

  constructor(props)
  {
    super(props)
    this.state = {
      position: [props.place.latitude ? props.place.latitude : 0, props.place.longitude ? props.place.longitude : 0],
      startPos: [props.place.latitude ? props.place.latitude : 0, props.place.longitude ? props.place.longitude : 0],
      placeId: [props.place.id],
      currentZoom: 5,
      loaded: false,
      value: 0,
      previous: 0,
      currentPatch: null
    }

    PlacesPatchActions.show(this.props.match.params.id, null).then(response =>
    {
      this.setState({ loaded: true, response: response.data.attributes.patch })
    })
  }

  getUniqueObservationsId()
  {
    const uniqueIds = {}
    const unique = []
    const resp = this.state.response
    resp.forEach(patch =>
    {
      if (patch.i in uniqueIds === false)
      {
        uniqueIds[patch.i] = { 'id': patch.i, 'date': patch.d }
        unique.push({ 'id': patch.i, 'date': patch.d })
      }
    })
    return Object.values(uniqueIds)
  }

  render()
  {
    if (!this.state.loaded)
    {
      return (
        <div>
          Loading ...
        </div>)
    }

    let uniqueObs = this.getUniqueObservationsId(), patch
    const obsDates = uniqueObs.map(x => x.date)

    const timelineStyle = { background: '#00000000', foreground: '#7b9d6f', outline: '#aaaaaa', label: '#fff' }
    let currentObs, currentState
    let markerPos = this.state.position
    if (this.state.currentPatch)
    {
      patch = <PlacesDataViewCurrentPatchPanel patch={ this.state.response } cutout={ this.state.cutout } />
      let obsType = ""
      if (this.state.currentPatch.length > 0)
        obsType = this.state.currentPatch[0]['t']
      currentObs =
        <div className="col-4 overflow-auto border border-primary" style={ { height: '75vh' } } >
          <div className="card">
            <div className="card-header p-0 m-0">
              { obsType }
            </div>
            <div className="card-body p-0 m-0" >
              <PlacesDataViewPatchPanel patch={ this.state.currentPatch } />
            </div>
          </div>
        </div>
      let lat = 0
      let long = 0

      if (patch)
      {
        currentState =
          <div className="col-4 overflow-auto border border-success" style={ { height: '75vh' } }>
            { patch }
          </div>
      }
      this.state.currentPatch.forEach(patch =>
      {
        if (patch['p'] === ('/latitude') && lat !== 0)
          lat = patch['v']
        if (patch['p'] === ('/longitude') && long !== 0)
          long = patch['v']
      })


      if (lat && lat !== 0 && long && long !== 0)
      {
        markerPos = [lat, long]
      }
    }

    const map =
      <DataplorMap center={ markerPos } zoom={ this.state.currentZoom } key="place-map" style={ { height: '75vh' } } >
        <Marker position={ markerPos } draggable={ false } />
      </DataplorMap>

    let sizeMap = 'col-12'
    if (this.state.currentPatch)
      sizeMap = 'col-4'
    if (this.state.loaded)
    {
      return (
        <div className="row ph-0" style={ { height: '75vh' } }>

          { currentState }
          <div className={ sizeMap }>
            { map }
          </div >
          { currentObs }


          <div className="row p-0" style={ {
            width: "100%",
            height: "100px"
          } }>
            <HorizontalTimeline
              isOpenBeginning={ false }
              isOpenEnding={ true }
              isBackEnabled={ true }
              isForwardEnabled={ true }
              styles={ timelineStyle }
              index={ this.state.value }
              indexClick={ (index) =>
              {
                const idObs = uniqueObs[index].id
                const patches = []
                this.state.response.forEach(x => x.i === idObs ? patches.push(x) : null)

                if (this.state.currentPatch && this.state.currentPatch.length > 0 && this.state.currentPatch[0].i === idObs)
                {
                  this.setState({ currentPatch: null })
                  return
                }
                this.setState({ currentPatch: patches, cutout: uniqueObs[index].date, value: index })
              } }

              values={ obsDates } />
          </div>
        </div>

      )
    }
    else
    {
      return (
        <div>
          Loading ...
        </div>)
    }

  }
}

const mapStateToProps = (state, props) =>
{
  const place = state.getIn(['places', props.match.params.id])
  return {
    place: place,
    observations: state.get('observations').filter(o => o.place_id && o.place_id === place.id)
  };
};

export default connect(mapStateToProps, null)(PlacesDataViewPanel);
