import React from 'react';
import { FixedSizeList as List } from 'react-window';
import AnalysisDuplicate from './analysis_duplicate';

class AnalysisDuplicates extends React.PureComponent
{
  constructor(props)
  {
    super(props);

  }

  render()
  {
    const { duplicates } = this.props;

    let Row, list
    console.log(duplicates)
    const duplicatesSorted = duplicates.sort((a, b) =>
    {
      const aScore = a.get('score')
      const bScore = b.get('score')

      if (aScore > bScore)
        return -1
      else if (bScore > aScore)
        return 1
      return 0
    })

    Row = ({ index, style }) => (
      <AnalysisDuplicate duplicate={ duplicatesSorted.get(index) } score={ duplicatesSorted.get(index).get('score') } key={ duplicatesSorted.get(index).hashCode() } onExclude={ this.props.onExclude } style={ style } />
    );


    list = duplicatesSorted ? (
      <List
        className="List"
        height={ 400 }
        style={ { height: '400px' } }
        itemCount={ duplicatesSorted.size }
        itemSize={ 150 }
      >
        { Row }
      </List>
    ) : null

    return (
      <div className="card border-danger mb-2" style={ { height: '400px' } }  >
        <div className="card-header text-danger cursor-pointer">
          Duplicates [{ duplicatesSorted !== undefined ? duplicatesSorted.size : 0 }]
        </div>
        { list }
      </div>
    )
  }


}


export default AnalysisDuplicates;
