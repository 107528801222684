
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  show: function(sampleId,params){
    return authorizedFetch(`/v3/admin/samples/${sampleId}/export`,params);
  },
  create: function(sampleId, params, actionType = "load"){
    const opts = { method: 'POST'};
    return authorizedFetch(`/v3/admin/samples/${sampleId}/export`,params,opts);
  }
};
