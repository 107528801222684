import React from 'react';
import { connect } from 'react-redux';
import PlacesActions from '../actions/places_actions';
import PlaceActionsRelatives from '../actions/place_actions_relatives';
import PlacesChildsRecursivePanel from './places_childs_recursive_panel'

class PlacesChildsPanel extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount()
  {
    Promise.all([PlaceActionsRelatives.show(this.props.place.id)]).then(this.setState({ loaded: true }))
  }

  render()
  {
    if (this.state.loaded === false)
      return 'loading...'

    const { place, root, allChilds } = this.props;

    if (root === undefined)
      return 'loading...'
    
    let rows = <PlacesChildsRecursivePanel place={ root } allChilds={ allChilds } main={ place } root={ root } />

    return (
      <div className="visits-data mx-2 my-4">
        { rows }
      </div>
    )
  }

  fetchData = (filter) =>
  {
    PlacesActions.index(
      Object.assign(
        {},
        {},
        { filter }
      ), "merge"
    )
  }

}

const mapStateToProps = (state, props) =>
{
  const placeId = props.match.params.id;
  const place = state.getIn(['places', placeId]);

  const rootId = place.path.split('.')[0]
  const root = state.getIn(['places', rootId]);

  return {
    place: place,
    childs: state.get('places').filter(i => i.parent_id === placeId),
    allChilds: state.get('places').filter(i => i.path.split('.')[0] === rootId),
    root: root
  }
}

export default connect(mapStateToProps)(PlacesChildsPanel);
