import React from 'react';
import { Link } from 'react-router-dom';
import { observerLink } from '../helpers/observation_helpers'
import LiveDate from './live_date';

class ObservationsRow extends React.PureComponent {

  render(){
    const { observation } = this.props;
    return (
      <tr>
        <td><Link to={`/observations/${observation.id}`} target="_blank" className="badge badge-observations">{observation.id}</Link></td>
        <td>{observation.type}</td>
        <td>{observation.name}</td>
        <td>{observerLink(observation)}</td>
        <td><Link to={`/places/${observation.observable_id}`} target="_blank" className="badge badge-places">{observation.observable_id}</Link></td>
        <td>{observation.source}</td>
        <td><Link to={`/observations/${observation.parent_id}`} target="_blank" className="badge badge-observations">{observation.parent_id}</Link></td>
        <td><Link to={`/places/${observation.place_id}`} target="_blank" className="badge badge-places">{observation.place_id}</Link></td>
        <td><LiveDate date={observation.responded_at} /></td>
        <td className = "text-danger"><LiveDate date={observation.disabled_at}/></td>
      </tr>
    )
  }
}

ObservationsRow.columns = [
  { col: "id", label: "Id" },
  { col: "type", label: "Type"},
  { col: "name", label: "Name" },
  { col: "observer_id", label: "Observer" },
  { col: "observable_id", label: "Place" },
  { col: "source", label: "Source"},
  { col: "parent_id", label: "Parent" },
  { col: "place_id", label: "Place" },
  { col: "responded_at", label: "Responded at" },
  { col: "disabled_at", label: "Disabled at"}
];

export default ObservationsRow;
