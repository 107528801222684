
export const calculateFieldValidationState = ({error,warning,prevalidation,value}) => {
  if(error || (prevalidation && prevalidation.validated === false)){ return 'error'; }
  if(warning || (prevalidation && prevalidation.validated === null)){ return 'warning'; }
  if((value || value === false) && (!prevalidation || prevalidation.validated === true)){ return 'ok'; }
};

export const validationClasses = ({error,warning,prevalidation,value}) => {
  const fieldState = calculateFieldValidationState({error,warning,prevalidation,value});
  return {
    "is-valid": (fieldState === 'ok'),
    "is-invalid": (fieldState === 'error'),
    "border-warning": (fieldState === 'warning')
  };
};

export const calculateError = ({error,property,prevalidation,}) => {
  if(error){ return error; }
  if(prevalidation && prevalidation.validated === false){
    return [
      (prevalidation.error_code),
      `visits.errors.${property}.async_invalid`,
      `visits.errors.${property}.invalid`,
      'forms.error_templates.invalid'
    ].filter(x => x);
  }
};

export const maskFormatChars = {
  '#': '[0-9]',
  'a': '[A-Za-z]',
  '*': '[A-Za-z0-9]',
};


const placeholder = '_';

export const unmaskValue = (value,mask,formatChars=maskFormatChars) => {
  if(!mask){ return value }
  return value.split('').reduce((str,char,index) => {
    return (maskFormatChars[mask[index]] && char !== placeholder) ? str+char : str;
  },'');
}

export const nullifyBlanks = (value) => (value === '' ? null : value );
