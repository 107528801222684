import React from 'react';
import { connect } from 'react-redux';

import AdminActions from '../actions/admins_actions';

import IndexView from './index_view';
import AdminsRow from './admins_row';

const columns = [
  { col: "id", label: "Id" },
  { col: "first_name", label: "First name" },
  { col: "last_name", label: "Last name" },
  { col: "email", label: "Email" },
  { col: "suspended", label: "Status" },
];

class AdminsIndex extends IndexView
{

  constructor(props)
  {
    super(props);
    this.state = {};
  }

  render()
  {
    const { total, page_size } = this.state;
    const { page_number } = this.parsedQuery();

    //let rows;
    //if(this.props.admins)
    const rows = this.props.admins.toIndexedSeq().map(admin =>
    {
      return <AdminsRow key={ admin.id } admin={ admin } />
    });
    return (
      <div id="admin_index">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            { this.searchBar() }
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  { this.generateHeaders(columns) }
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </table>
            { this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            }) }
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) =>
  {
    return AdminActions.index(query).then(response =>
    {
      this.setState({ total: response.meta.total, page_size: response.meta.page_size })
    });
  }
}

const mapStateToProps = state =>
{
  return {
    admins: state.get('admins'),
    total: state.getIn(['page', 'totals', 'leads']),
  }
}

export default connect(mapStateToProps)(AdminsIndex);
