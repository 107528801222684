import React from 'react';
import AnalysisPlacesChainMaybeAdd from './analysis_places_chain_maybe_add_card'
import AnalysisPlacesChainMaybeRemoveCard from './analysis_places_chain_maybe_remove_card';
import AnalysisPlacesChainListIdsCard from './analysis_places_chain_list_ids_card';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import BrandActions from '../actions/brand_actions';
import { connect } from 'react-redux';


class AnalysisPlacesChainBrandsCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
      loaded: false,
    };
    //const { stats } = this.props;

    BrandActions.show(props.brand.get('id')).then(json =>
    {
      this.setState({ loaded: true });
    })

  }

  render()
  {
    const { brand, name, brandDetail, typeResult, id } = this.props
    let chainInfo, mayAddUI, missCanIdsUI, mulCanIdsUI, mayRemoveUI
    const nextUrl = '//adminnext.dataplor.com'

    const urlAdminNext = `${nextUrl}/${typeResult}/${id}/analysis/places?analysis_key=chains.${brand.get('id')}`

    if (this.state.open)
    {
      const logo =
        <div className='col-6 mb-2'>
          <div className='col border border-primary rounded' style={ { height: 120 } }>
            <img style={ { height: 100 } } src={ brandDetail.logo } alt="The logo couldn't be loaded, please check the url" className="mx-auto d-block img-fluid" data-toggle="tooltip" data-placement="top" title="Logo image" />
          </div>
        </div>
      const example_photo =
        <div className='col-6 mb-2'>
          <div className='col border border-primary rounded' style={ { height: 120 } }>
            <img style={ { height: 100 } } src={ brandDetail.example_photo } alt="The example photo couldn't be loaded, please check the url" className="mx-auto d-block img-fluid mt-2" data-toggle="tooltip" data-placement="top" title="Example photo image" />
          </div>
        </div>
      chainInfo = <div className='row'>
        { logo }
        { example_photo }
      </div>
      mayAddUI = <AnalysisPlacesChainMaybeAdd maybeAdd={ brand.get('maybe_add') } />
      missCanIdsUI = <AnalysisPlacesChainListIdsCard ids={ brand.get('missing_canonical_observation') } title={ 'Missing Canonical Observation' } />
      mulCanIdsUI = <AnalysisPlacesChainListIdsCard ids={ brand.get('multiple_canonical_ids') } title={ 'Multiple Canonical Ids' } />
      mayRemoveUI = <AnalysisPlacesChainMaybeRemoveCard maybeRemove={ brand.get('maybe_remove') } />
    }

    let maybe_add_total, maybe_remove_total = 0, missing_canonical_obs_total = 0, multiple_canonical_ids_total = 0

    maybe_add_total = brand.get('maybe_add').size
    maybe_remove_total = brand.get('maybe_remove').size
    missing_canonical_obs_total = brand.get('missing_canonical_observation').size
    multiple_canonical_ids_total = brand.get('multiple_canonical_ids').size

    return (
      <div className="card border-info mb-2 mx-3">
        <div className="card-header text-info cursor-pointer" onClick={ this.toggle }>
          { <Link to={ `/brands/${brand.get('id')}/edit` } key={ brand.get('id') } target="_blank" className="badge badge-brands m-1">{ name }</Link> }
          { `[${maybe_add_total}, ${maybe_remove_total}, ${missing_canonical_obs_total}, ${multiple_canonical_ids_total}]` }
          { <Link to={ urlAdminNext } key={ brand.get('id') + name } target="_blank" rel="noreferrer" className="badge badge-success m-1">adminNext</Link> }
        </div>
        <div className="col mp-5">
          { chainInfo }
          { mayAddUI }
          { mayRemoveUI }
          { missCanIdsUI }
          { mulCanIdsUI }

        </div>
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


const mapStateToProps = (state, props) =>
{
  const id = props.brand.get('id')
  const brand = state.getIn(['brands', id.toString()])
  return {
    brandDetail: brand
  };
};

export default connect(mapStateToProps, null)(AnalysisPlacesChainBrandsCard);
