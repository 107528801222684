import Immutable from 'immutable';

import Validator from '../../shared/models/validator';

import { getStoredValidator, setAuth } from '../../shared/helpers/authentication_helpers';

// https://github.com/maxogden/browser-locale/blob/master/index.js
const getLocale = () => {
  try {
    let lang
    if (navigator.languages && navigator.languages.length) {
      // latest versions of Chrome and Firefox set this correctly
      lang = navigator.languages[0]
    } else if (navigator.userLanguage) {
      // IE only
      lang = navigator.userLanguage
    } else {
      // latest versions of Chrome, Firefox, and Safari set this correctly
      lang = navigator.language
    }
    return lang.match(/^\w{2}/)[0]
  } catch (e) {
    return 'es';
  }
};

const defaultValue = new Validator(
  Immutable.fromJS(
    Object.assign({
      locale: getLocale(),
    }, getStoredValidator())
  )
);

export default (state = defaultValue, action) => {
  switch (action.type) {
    case `validator.load`: {
      const loadedValidator = Object.keys(action.data).reduce((state, key) => {
        return state.set(key, Immutable.fromJS(action.data[key]));
      }, state);
      setAuth('validator', loadedValidator.toJSON());
      return loadedValidator;
    }
    case `validator.merge`: {
      const mergedValidator = state.mergeDeep(action.data);
      setAuth('validator', mergedValidator.toJSON());
      return mergedValidator;
    }
    case `validator.delete_in`: {
      const modifiedValidator = state.withMutations(s => {
        action.data.forEach(p => {
          s.deleteIn(p);
        });
      });
      setAuth('validator', modifiedValidator.toJSON());
      return modifiedValidator;
    }
    default:
      return state;
  }
};
