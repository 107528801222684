
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  show: function(brandId, params)
  {
    return authorizedFetch(`/v3/admin/brands/${brandId}/export`, params);
  },
  create: function(brandId, params, actionType = "load")
  {
    const opts = { method: 'POST' };
    return authorizedFetch(`/v3/admin/brands/${brandId}/export`, params, opts);
  }
};
