import React from 'react';
import { withRouter } from 'react-router-dom';

import AnalysisPlacesRecurrancesCardNewRow from './analysis_places_recurrances_card_new_row';

class AnalysisPlacesRecurrancesCardNew extends React.PureComponent
{
  constructor(props)
  {
    super(props);

    this.state = {
      open: false,
    };
  }

  render()
  {
    const nextUrl = 'https://adminnext.dataplor.com'
    const id = this.props.match.params.id
    const typeResult = this.props.match.path.toString().includes('deliveries') ? 'deliveries' : 'samples'
    const { ids, attribute } = this.props;

    let list

    const rows = Array.from(ids.keys()).map(key =>
    {
      return <AnalysisPlacesRecurrancesCardNewRow key={ key } item={ ids.get(key) } />
    })


    let height

    if (this.state.open)
    {
      list =
        <div>
          <div className='row'>
            <a href={ `${nextUrl}/${typeResult}/${id}/analysis/places?analysis_key=recurrences.${attribute}` } target='_blank' rel="noreferrer" className='col-2 btn btn-info m-1'>adminNext</a>

          </div>
          <table className="table table-striped table-bordered table-sm table-hover">
            <tbody className="mb-3">
              { rows }
            </tbody>
          </table>
        </div>
      height = { height: '50vh', 'overflowY': 'auto' }
    }
    return (
      <div className="card border-warning mb-2" >
        <div className="card-header text-warning cursor-pointer" onClick={ this.toggle }>
          Recurrences: { attribute } [{ (ids && ids.size) || 0 }]
        </div>
        <div className="card-body">
          <div className="col mp-5" style={ height }>
            { list }
          </div>
        </div>
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default withRouter(AnalysisPlacesRecurrancesCardNew);
