import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Linkify from 'react-linkify';

class ObservationsPatchRow extends React.Component {

  render() {
    const patch = this.props.patch
    const attr  = this.props.attribute

    if(patch === undefined)
    {
      return (
        <div className = {'border-white row border-solid border mb-2 rounded p-2'}>
          <div className ="col-2">

          </div>
          <div className ="col-3 text-monospace">
           <Linkify>{attr}</Linkify>
          </div>
          <div className ="col">
            not found
          </div>
        </div>
      );
    }

    const c = patch.get('c')
    const o = patch.get('o')
    const p = patch.get('p')
    const v = patch.get('v')

    const color100 = `scale-border-${Math.round(c * 100)}`
    const color = {'border-danger':!o, 'border-success':o}
    const rowClass = cn(color, `row border-solid border mb-2 rounded p-2 ${color100}`)

    return (
      <div className = {rowClass}>
        <div className ="col-2">
          {c}
        </div>
        <div className ="col-3 text-monospace">
          <Linkify>{p}</Linkify>
        </div>
        <div className ="col">
          <Linkify>{JSON.stringify(v)}</Linkify>
        </div>
      </div>
    );
  }


}


export default connect()(ObservationsPatchRow);
