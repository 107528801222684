import React from 'react';

class DuplicatePlaceComparisonPanelRow extends React.PureComponent
{
  render()
  {
    const { value1, value2, score, name, weight } = this.props

    const scoreI = Math.floor(score)

    const scaleClass = ((value1 && value2 && weight > 0) ? `scale-bg-${scoreI} text-white` : 'text-dark');
    const textColor = ((value1 && value2 && weight > 0) ? `text-white` : 'text-dark');

    let middleRow = <td className={ `span1 text-center ${scaleClass}` } style={ { width: "10%" } }>{ name } <br /></td>
    if (score)
      middleRow = <td className={ `span1 text-center ${scaleClass}` } style={ { width: "10%" } }>{ name } <br /> { score }%</td>
    return (
      <tr style={ { height: "50px" } } key={ name }>
        <td className={ `span4 text-center align-middle ${textColor}` } style={ { width: "45%" } }>{ value1 }</td>
        { middleRow }
        <td className={ `span4 text-center align-middle ${textColor}` } style={ { width: "45%" } }>{ value2 }</td>
      </tr>
    )
  }
}

export default DuplicatePlaceComparisonPanelRow;
