import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class AnalysisPlacesChainMaybeRemoveCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
    };
  }

  render()
  {
    const { maybeRemove } = this.props

    let brandsUI, table


    if (this.state.open)
    {
      brandsUI = Array.from(maybeRemove.keys()).map(key =>
      {
        const place = maybeRemove.get(key).get('id')
        const placeLink = <Link to={ `/places/${place}/edit` } key={ key } target="_blank" className="badge badge-places m-1">{ place }</Link>

        return (
          <tr key={ key }>
            <td> { placeLink } </td>
            <td> { maybeRemove.get(key).get('confidence').toFixed(2) } </td>
          </tr>

        )
      })

      table = (
        <table className="table table-striped table-bordered table-sm table-hover">
          <thead>
            <tr>
              <th>Id</th>
              <th>Confidence</th>
            </tr>
          </thead>
          <tbody>
            { brandsUI }
          </tbody>
        </table>
      )
    }

    return (
      <div className="card border-info mb-2">
        <div className="card-header text-info cursor-pointer" onClick={ this.toggle }>
          Maybe Remove: [{ Array.from(this.props.maybeRemove.keys()).length }]
        </div>
        <div className="col mp-5">
          { table }
        </div>
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default AnalysisPlacesChainMaybeRemoveCard;
