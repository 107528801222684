import React from 'react';
import { connect } from 'react-redux';
import { getZ } from '../helpers/confidence_helpers'

class PlacesDataViewPatchRow extends React.Component {

  render() {

    const patch = this.props.patch
    const cutout = this.props.cutout

    const c = patch['c']
    const o = patch['o']
    const p = patch['p']
    const v = patch['v']
    const d = patch['d']

    let conf = c
    if (cutout) {
      conf = getZ(c, d, { 'as_of': cutout })
    }

    const colorborder = o ? 'table-success' : 'table-danger'

    return (
      <tr className={ colorborder } key={ v }>
        <td style={ { width: '30%' } }>{ p.substring(1) }</td>
        <td style={ { width: '40%', word_break: 'break_all' } }>{ v }</td>
        { <td style={ { width: '10%' } }>{ parseFloat((conf * 100).toFixed(2)) + "%" }</td> }
      </tr>
    )
  }


}


export default connect()(PlacesDataViewPatchRow);
