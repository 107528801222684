import React from 'react';
import { connect } from 'react-redux';
import { Link,Redirect } from 'react-router-dom';

import AccountActions from '../actions/account_actions';

import T from '../../shared/components/t';
import DashboardsIndex from './dashboards_index';

const projectId = '0';

class QuizReportsShow extends React.PureComponent {
  componentDidMount(){
    if(!this.props.explorer.quiz_completed_at){
      if( this.props.region.id && this.allWereAnswered() && this.allAreCorrect() ){
        this.onComplete();
      }
    }
  }

  componentWillUnmount(){
    this.props.initQuiz();
  }

  render(){
    if(this.props.region.id){
      if(  this.allWereAnswered() && this.allAreCorrect() ){
        return (
          <DashboardsIndex />
        );
      } else {
        return (
          <div className="row">
            <div className="col">
              <h2><T k="quiz_reports.show.failed.header" /></h2>
              <p><T k="quiz_reports.show.failed.copy" /></p>
              <Link to="/training">
                <button className="btn btn-primary col mb-2 repeat-training-button">
                  <T k="quiz_reports.show.failed.training_link" />
                </button>
              </Link>
              <button className="btn btn-primary col repeat-quiz-button" onClick={this.onQuiz}>
                <T k="quiz_reports.show.failed.quiz_link" />
              </button>
            </div>
          </div>
        );
      }
    } else {
      return <Redirect to="/quiz" />
    }
  }

  allWereAnswered = () => {
    const numQuestions = this.quiz().get('questions').size;
    return this.props.quiz_answers && (this.props.quiz_answers.size === numQuestions);
  }

  allAreCorrect = () => {
    return this.props.quiz_answers && this.props.quiz_answers.every(x => x);
  }

  onQuiz = () => {
    this.props.initQuiz();
    this.props.history.push('/quiz');
  }

  onComplete = () => {
    const body = { quiz_completed_at: (new Date().toISOString()) };
    return AccountActions.update(body).then(() => this.props.history.push('/') );
  }

  quiz(){
    return this.props.region.quiz;
  }

}

const mapStateToProps = state => ({
  quiz_answers: state.getIn(['quiz_answers',projectId]),
  quiz_completed_at: state.getIn(['explorer','quiz_completed_at']),
  region: state.get('region'),
  explorer: state.get('explorer'),
});

const mapDispatchToProps = {
  initQuiz: () => ({ type: 'quiz_answers.load', data: {[projectId]: {}} }),
};

export default connect(mapStateToProps,mapDispatchToProps)(QuizReportsShow);
