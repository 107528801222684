import React from 'react';
import { connect } from 'react-redux';

class BrandsComparePlacesWebsiteExplanationPanel extends React.Component
{

  render()
  {
    const { website_explanation, place } = this.props;
    if (!website_explanation)
    {
      return null
    }
    const place_website = place.data.get('website')
    let website_link
    if (place_website)
      website_link = <a target="_blank" key='nom' className="badge bg-warning m-1" href={ place_website } rel="noreferrer">{ place_website }</a>


    return (
      <div className='card mb-1'>
        <div className='card-header bg-info'>

          <div className='row text-white justify-content-between fs-1 fw-bold'>
            <div className='col-9 fw-bold'>
              Website explanation
            </div>
            <div className='col-auto fw-bold'>
              Score: { website_explanation.score.toFixed(2) }
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            Place website: { website_link || 'null' }
          </div>

        </div>


      </div>
    )
  }
}

export default connect(null)(BrandsComparePlacesWebsiteExplanationPanel);
