import React from 'react';
import { connect } from 'react-redux';
import IndexView from './index_view';
import BrandsRow from './brands_row';
import BrandActions from '../actions/brand_actions';

class BrandsIndex extends IndexView {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { total, page_size } = this.state;
    const { page_number }      = this.parsedQuery();

    const rows = this.props.brands.toIndexedSeq().map(brand => {
      return <BrandsRow key={brand.id} brand={brand} />;
    })

    return (
      <div id="brandsIndex">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            {this.searchBar()}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  {this.generateHeaders(BrandsRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({ page_number: (page_number || 1), total, page_size })}
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) => {
    return BrandActions.index(query).then(response => {
      this.setState({ total: response.meta.total, page_size: response.meta.page_size })
    });
  }
}

const mapStateToProps = state => {
  return {
    brands: state.get('brands'),
    total: state.getIn(['page', 'totals', 'brands']),
  }
}

export default connect(mapStateToProps)(BrandsIndex);
