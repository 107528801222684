import React from 'react';
import { connect } from 'react-redux';
import LiveDate from './live_date';
import { Link } from 'react-router-dom';
import { getZ } from '../helpers/confidence_helpers'
import { OverlayTrigger, Popover } from 'react-bootstrap';

class PlacesDataViewCurrentPatchPanelRow extends React.Component
{

  render()
  {

    const patch = this.props.patch
    const cutout = this.props.cutout
    let winner = null

    patch.forEach(row =>
    {
      if (row['o'] === true)
      {
        if (winner === null)
        {
          winner = row
        }
        else
        {
          const challengerZ = getZ(row['c'], row['d'], { 'as_of': cutout })
          const currentZ = getZ(winner['c'], winner['d'], { 'as_of': cutout })
          if (challengerZ > currentZ)
          {
            winner = row
          }
        }
      }
    })

    const c = winner['c']
    const p = winner['p']
    const pi = winner['pi']
    const v = winner['v']
    const d = winner['d']
    const i = winner['i']
    const z = winner['z']
    const t = winner['t']
    const r = winner['r']
    const x = winner['x']
    const pp = winner['pp']

    const zvalue = parseInt(Math.round(getZ(c, d, { 'as_of': cutout }) * 100))
    const color = `scale-bg-${zvalue}`

    const button =
      <div style={ { height: 120 } }>
        <Popover
          id="popover-basic popover-positioned-top border border-primary"
          style={ { 'width': '275px', 'height': '300px' } }
          title="Obs"
        >
          <table className="table_ly table table-bordered table-hover" >
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Date</td>
                <td><LiveDate date={ d } /></td>
              </tr>
              <tr className={ color }>
                <td>Confidence</td>
                <td>{ c }({ z }){ r === true ? 'relative' : null }</td>
              </tr>
              <tr>
                <td>
                  Type
                </td>
                <td>
                  <Link to={ `/observations/${i}` } target="_blank" className="badge badge-observations">{ t }</Link>
                </td>
              </tr>
              <tr>
                <td>
                  Parent
                </td>
                <td>
                  <Link to={ `/observations/${pi}` } target="_blank" className="badge badge-observations">{ pi }</Link>
                </td>
              </tr>
              <tr>
                <td>
                  Path
                </td>
                <td>
                  <Link to={ `/observations/${pi}` } target="_blank" className="badge badge-observations">{ pp }</Link>
                </td>
              </tr>
              <tr>
                <td>
                  Disabled at:
                </td>
                <td>
                  <LiveDate date={ x } />
                </td>
              </tr>
            </tbody>
          </table>
        </Popover>
      </div>

    let colortext = 'text-success'
    if (zvalue <= 30)
      colortext = 'text-danger'
    else if (zvalue <= 60)
      colortext = 'text-warning'

    let conf = c//getZ(c,d)
    if (cutout)
    {
      conf = getZ(c, d, { 'as_of': cutout })
    }

    return (
      <OverlayTrigger trigger={ ['focus'] } placement='left' overlay={ button } tabIndex="0">
        <tr key={ v } tabIndex="0">
          <td style={ { width: '30%' } }>{ p.substring(1) }</td>
          <td style={ { width: '40%', word_break: 'break_all', color: { color } } }>{ v }</td>
          <td className={ colortext } style={ { width: '10%' } }>{ parseFloat((conf * 100).toFixed(2)) + "%" }</td>
        </tr>
      </OverlayTrigger>
    )
  }


}


export default connect()(PlacesDataViewCurrentPatchPanelRow);
