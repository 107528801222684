import React from 'react';


class PlaceReviewCard extends React.PureComponent
{


    onError = (e) =>
    {
        console.log("Error")
    }

    onLoad = () =>
    {
        console.log("Load")
    }

    render()
    {

        const { place } = this.props;
        let streetView, address, mail, facebook, google_maps, websiteLink
        const latitude = place.latitude
        const longitude = place.longitude

        const header = <a href={ `/places/${place.id}` } className=" text-info" target="_blank" rel="noopener noreferrer">{ place.name }</a>
        const url = `http://maps.google.com?cbll=${latitude},${longitude}&cbp=12,20.09,,0,5&layer=c`
        //const content = <a href={ url } target="_blank" rel="noopener noreferrer">{ latitude }/{ longitude }</a>

        if (latitude !== undefined && longitude !== undefined)
        {
            streetView = <a className="btn btn-outline-info col-12 mb-2 justify-content-center align-middle text-align-center" target="_blank" rel="noreferrer" href={ url } role="button">Open streetview</a>
        }

        const email = place.getIn(['data', 'email'],)

        let websiteData = place.getIn(['data', 'website'])
        if (websiteData && websiteData.includes('http://') === false)
            websiteData = 'http://' + websiteData
        const facebookUrl = place.getIn(['data', 'facebook_url'])
        const facebookId = place.getIn(['data', 'facebook_id'])
        const googlemaps = place.getIn(['data', '_google_places_id'])

        if (websiteData)
        {
            websiteLink = <a className="btn btn-outline-info col-12 mb-2 justify-content-center align-middle text-align-center" target="_blank" rel="noreferrer" href={ websiteData } role="button">Open website</a>
        }


        address = <div className='col-12 border rounded border-info mb-2'>
            <table className='table table-striped table-bordered table-sm table-hover mt-2'>
                <tbody>
                    <tr>
                        <td>Address</td>
                        <td>{ place.getIn(['data', 'address']) }</td>
                    </tr>
                    <tr>
                        <td>Address2</td>
                        <td>{ place.getIn(['data', 'address2']) }</td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td>{ place.getIn(['data', 'city']) }</td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td>{ place.getIn(['data', 'state']) }</td>
                    </tr>
                    <tr>
                        <td>Postal code</td>
                        <td>{ place.getIn(['data', 'postal_code']) }</td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>{ place.getIn(['data', 'country']) }</td>
                    </tr>
                </tbody>
            </table>
        </div>

        if (email)
        {
            mail = <div className='row m-2'>
                E-mail: { email }
            </div>
        }

        if (facebookUrl)
        {
            facebook = <a className="btn btn-outline-info col-12 mb-2 justify-content-center align-middle text-align-center" target="_blank" rel="noreferrer" href={ facebookUrl } role="button">Open facebook page</a>
        }
        if (facebookId)
        {
            const url = `www.facebook.com/${facebookId}`
            facebook = <a className="btn btn-outline-info col-12 mb-2 justify-content-center align-middle text-align-center" target="_blank" rel="noreferrer" href={ url } role="button">Open facebook page</a>
        }
        if (googlemaps)
        {
            const url = `https://www.google.com/maps?q=place_id:${googlemaps}`
            google_maps = <a className="btn btn-outline-info col-12 mb-2 justify-content-center align-middle text-align-center" target="_blank" rel="noreferrer" href={ url } role="button">Open google maps page</a>
        }

        const website = <div className="col-12 mb-2 embed-responsive embed-responsive-4by3">
            <iframe className="col border border-info rounded embed-responsive-item" src={ websiteData } onError={ this.onError } onLoad={ this.onLoad } title="website"></iframe>
        </div>

        return (
            <div id="brandsShow" className="mb-5">
                <div className="card-header h5 text-info">
                    { header }
                    <span className='ml-2 badge badge-info'>  { place.business_category_id }</span>
                </div>
                <div className="card-body">
                    <div className='row text-info' style={ { minHeight: 100 } }>

                        { address }
                        { mail }
                        { google_maps }
                        { facebook }
                        { streetView }
                        { websiteLink }
                        { website }
                    </div>

                    {/** <iframe className="embed-responsive-item" style={ { width: '400px', height: '200px' } } src={ url } title="website"></iframe> */ }
                </div>
            </div>
        );
    }
}



export default PlaceReviewCard;
