import React from 'react';
import { connect } from 'react-redux';
import BrandActions from '../actions/brand_actions';
import BrandsFormPanelLocaleRow from './brands_form_panel_locale_row';
import BrandsFormPanelLocaleRowAdd from './brands_form_panel_locale_row_add';
import Select from '../../shared/components/select';
import { v4 as uuidv4 } from 'uuid';
import NewBusinessCategoryActions from '../actions/new_business_category_actions';

class BrandsFormPanelRow extends React.Component
{
  constructor()
  {
    super();
    this.state = {
      submitted: false,
      selected_business_categories: []
    };
  }

  componentDidMount()
  {
    NewBusinessCategoryActions.index().then(() => {
      this.props.initForm(this.props.brand.id)
      const translations = JSON.parse(JSON.stringify(this.props.brand.translations))
      const translationKeys = Object.keys(translations)

      translationKeys.forEach(key => {
        if (key.includes('aliases')) {
          return
        }

        const locale = key
        const name = translations[key]
        const aliases = translations[`${key}_aliases`] || []

        this.props.addTranslation(this.props.brand.id, locale, name, aliases)
      })

      const { brand, business_categories } = this.props

      const selected_business_categories = Array.from(business_categories.toList()).filter((category) => {
        return !!brand.business_category_ids.includes(category.key)
      }).map(category => {
        return { value: category.id, label: category.key }
      })

      this.setState({ submitted: false, selected_business_categories: selected_business_categories })
    })
  }

  onChange = e =>
  {
    if (e === undefined)
    {
      return
    }

    const componentId = e.target.id
    let value = e.target.value

    if (e.target.type === "checkbox")
    {
      value = e.target.checked
    }

    this.props.updateValue(this.props.brand.id, componentId, value)
  }

  updateBusinessCategories = categories =>
  {
    const ids = categories.map(category => { return category.label })
    this.props.updateValue(this.props.brand.id, 'business_category_ids', ids);
    this.setState({ selected_business_categories: categories })
  }

  render()
  {
    const { brand, form, changes } = this.props;

    if (form === undefined)
    {
      return null
    }

    const translated_names = JSON.parse(JSON.stringify(form))
    let translated_names_ui, keys = []

    if (translated_names.translations)
    {
      keys = Object.keys(translated_names.translations)
    }

    translated_names_ui = keys.map(key =>
    {
      return <BrandsFormPanelLocaleRow brand={ brand } key={ key } data={ translated_names.translations[key] } />
    })

    if (this.state.submitted)
    {
      return (
        <div>
          Submitted! Reload to see the changes.
        </div>
      )
    }

    return (
      <div className='row'>
        <div className='card col-6'>
          <div className='card-body'>
            <form>
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" id="name" defaultValue={ brand.name } onChange={ this.onChange } />
              </div>
              <div className="form-group">
                <label>Website</label>
                <input type="text" className="form-control" id="website" defaultValue={ brand.website } onChange={ this.onChange } />
              </div>
              <div className="form-group">
                <label>Stock ticker</label>
                <input type="text" className="form-control" id="stock_ticker" defaultValue={ brand.stock_ticker } onChange={ this.onChange } />
              </div>
              <div className="form-group">
                <label>Example photo</label>
                <input type="text" className="form-control" id="example_photo" defaultValue={ brand.example_photo } onChange={ this.onChange } />
              </div>
              <div className="form-group">
                <label>Logo</label>
                <input type="text" className="form-control" id="logo" defaultValue={ brand.logo } onChange={ this.onChange } />
              </div>
              <div className="form-group">
                <label>Notes</label>
                <textarea type="text" className="form-control" rows="4" id="notes" defaultValue={ brand.notes } onChange={ this.onChange } />
              </div>
              <div className="form-group">
                <label>Business Category</label>
                { this.renderBusinessCategorySelect() }
              </div>
              <div className='row'>
                <div className="form-group col-6 text-center">
                  <label className='text-center'>Is a Chain?</label>
                  <input type="checkbox" className="form-control" id="is_chain" defaultChecked={ brand.is_chain } onChange={ this.onChange } />
                </div>
                <div className="form-group col-6 text-center">
                  <label className=''>Ready for Identification?</label>
                  <input type="checkbox" className="form-control" id="ready_for_identification" defaultChecked={ brand.ready_for_identification } onChange={ this.onChange } />
                </div>
              </div>
              <div className='form-group'>
                <label>Translations</label>
                { translated_names_ui }
                <BrandsFormPanelLocaleRowAdd brand={ brand } id={ this.props.id } />
              </div>

              <button type="submit" className="btn btn-primary" onClick={ this.onSubmit }>Submit</button>
            </form>
          </div>
        </div>

        <div className='card col-6'>
          <div className="card">
            <div className="card-header">
              Images:
            </div>
            <div className="card-body">
              <div className='row mb-2'>
                <div className='col border border-primary rounded' style={ { minHeight: 100 } }>
                  <img src={ changes && changes.get('logo') ? changes.get('logo') : brand.logo } alt="The logo couldn't be loaded, please check the url" className="mx-auto d-block img-fluid" data-toggle="tooltip" data-placement="top" title="Logo image" />
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col border border-primary rounded' style={ { minHeight: 100 } }>
                  <img src={ changes && changes.get('example_photo') ? changes.get('example_photo') : brand.example_photo } alt="The example photo couldn't be loaded, please check the url" className="mx-auto d-block img-fluid" data-toggle="tooltip" data-placement="top" title="Example photo image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBusinessCategorySelect()
  {
    const business_categories = this.props.business_categories.toList().map(category =>
    {
      return { value: category.get('id'), label: category.get('key') }
    }).sort((a, b) =>
    {
      if (a.label < b.label) { return -1 }
      return 1
    })

    return (
      <Select
        value={ this.state.selected_business_categories }
        options={ business_categories }
        onChange={ this.updateBusinessCategories }
        placeholder={ "Select" }
        isClearable
        isMulti
      />
    )
  }

  onSubmit = (e) =>
  {
    e.preventDefault()
    const { brand, changes, errors } = this.props;

    if (!changes)
    {
      console.log('no changes')
      return
    }

    if (JSON.stringify(errors))
    {
      console.log(JSON.stringify(errors))
      return
    }

    const fields = JSON.parse(JSON.stringify(changes)) || []
    const translationKeys = Object.keys(fields.translations || {})
    const toSubmit = { translations: {} }

    translationKeys.forEach(translationKey =>
    {
      const translation = fields.translations[translationKey]
      const locale = translation.locale
      const name = translation.name
      const aliases = translation.aliases.toString().split(',')

      let formattedAliases = []

      aliases.forEach(alias =>
      {
        const trimmedAlias = alias.trim()
        if (trimmedAlias)
        {
          formattedAliases.push(trimmedAlias)
        }
      })

      toSubmit.translations[locale] = name
      toSubmit.translations[`${locale}_aliases`] = formattedAliases
    })

    toSubmit.name = fields.name
    toSubmit.website = fields.website
    toSubmit.stock_ticker = fields.stock_ticker
    toSubmit.wikipedia = fields.wikipedia
    toSubmit.example_photo = fields.example_photo
    toSubmit.logo = fields.logo
    toSubmit.notes = fields.notes
    toSubmit.is_chain = fields.is_chain
    toSubmit.ready_for_identification = fields.ready_for_identification
    toSubmit.business_category_ids = fields.business_category_ids

    const body = { data: { type: 'brand', attributes: toSubmit } }
    return BrandActions.update(brand.id, body).then(this.setState({ submitted: true }))
  }
}

const mapStateToProps = (state, props) =>
{
  const brand = state.getIn(['brands', props.match.params.id]);
  const form = state.getIn(['forms', 'brands/edit', props.match.params.id]);
  const errors = state.getIn(['form_errors', 'brands/edit', props.match.params.id]);
  const business_categories = state.get('new_business_categories');
  const changes = state.getIn(['form_changes', 'brands/edit', props.match.params.id]);
  return {
    form: form,
    brand: brand,
    errors: errors,
    changes: changes,
    business_categories: business_categories
  };
};

const mapDispatchToProps = {
  initForm: (brandId) =>
  {
    return { type: 'forms.load', data: { 'brands/edit': { [brandId]: {} } } }
  },
  updateValue: (brandId, attr, value) =>
  {
    return { type: 'forms.merge', data: { 'brands/edit': { [brandId]: { [attr]: value } } } }
  },
  addTranslation: (brandId, locale, name, aliases) =>
  {
    const id = uuidv4();
    return {
      type: 'forms.merge',
      data: {
        'brands/edit': {
          [brandId]: {
            'translations': {
              [id]: {
                'id': id,
                'locale': locale,
                'name': name,
                'aliases': aliases
              }
            }
          }
        }
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsFormPanelRow);
