import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import DataplorMap from '../../shared/components/dataplor_map';
import { savePlaceManualObservationFromPatch } from '../helpers/observation_helpers';
import PlacesObservationsActions from '../actions/places_observations_actions';


class PlacesMapPanel extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      draggable: true,
      position: [props.place.latitude ? props.place.latitude : 0, props.place.longitude ? props.place.longitude : 0],
      startPos: [props.place.latitude ? props.place.latitude : 0, props.place.longitude ? props.place.longitude : 0],
      placeId: [props.place.id],
      showConfirm: false,
      submitted: false,
    };

  }

  render()
  {
    const onSend = () =>
    {
      let lat = {
        c: 0.95,
        o: true,
        p: "/latitude",
        v: this.state.position[0]
      }
      let long = {
        c: 0.95,
        o: true,
        p: "/longitude",
        v: this.state.position[1]
      }
      savePlaceManualObservationFromPatch(this.state.placeId, { data: { attributes: { patch: [lat, long] } } }, PlacesObservationsActions.create)
      this.setState({ submitted: true })
    }

    const retractF = () =>
    {
      let lat = {
        c: 0.95,
        o: false,
        p: "/latitude",
        v: this.props.place.latitude
      }
      let long = {
        c: 0.95,
        o: false,
        p: "/longitude",
        v: this.props.place.longitude
      }
      savePlaceManualObservationFromPatch(this.state.placeId, { data: { attributes: { patch: [lat, long] } } }, PlacesObservationsActions.create)
      this.setState({ submitted: true, showConfirm: false })
    }
    const onRetract = () =>
    {
      this.setState({ showConfirm: true })
      return
    }


    const link = `https://www.google.com/maps/?q=${this.state.position[0]},${this.state.position[1]}`
    const linkClick = <a href={ link } target="_blank" rel="noopener noreferrer" className="col-auto btn btn-secondary"> { 'Gmaps link' }</a>

    const alert =
      <div className="alert alert-warning" role="alert">
        Are you sure you want to retract the coords?

        <div className="btn btn-danger mx-1" onClick={ retractF }>Retract Coordinates</div>
        <div className="btn btn-primary mx-1" onClick={ () => { this.setState({ showConfirm: false }) } }>Cancel</div>
      </div>

    const onDragEnd = (e) =>
    {
      this.setState({ position: [e.target._latlng.lat, e.target._latlng.lng] })
    }

    const { place } = this.props;
    const retract = <div className="col-auto ">
      <div className="btn btn-danger" onClick={ onRetract }>Retract Coordinates</div>
    </div>

    const latlngF = <div>
      <input
        type="text"
        value={ this.state.position[0] }
        onChange={ (e) => { console.log(e) } }
      />
      <input
        type="text"
        value={ this.state.position[1] }
        onChange={ (e) => { console.log(e) } }
      />
      <div className="btn btn-info m-1" onClick={ onSend }>Add Manual Coordinates</div>
    </div>

    if (this.state.submitted)
    {
      return (
        <div><span>Submitted! Refresh to review again.</span></div>
      );
    }
    if (place && (place.latitude || place.longitude))
      return (
        <div>
          { this.state.showConfirm ? alert : null }
          <div className='row my-1'>
            { retract }
            { linkClick }
          </div>
          { latlngF }
          <DataplorMap center={ this.state.position } zoom={ 15 } key="place-map" style={ { height: '75vh' } }>
            <Marker position={ this.state.position } draggable={ true } onDragend={ onDragEnd } />
          </DataplorMap>

        </div>
      )
    else return (
      <div>
        <div className="col align-self-end">
          <div className="alert-primary">No coordinates</div>
          { latlngF }
        </div>
        <DataplorMap center={ this.state.position } zoom={ 5 } key="place-map" style={ { height: '75vh' } }>
          <Marker position={ this.state.position } draggable={ true } onDragend={ onDragEnd } />
        </DataplorMap>

      </div>
    )
  }


}

const mapStateToProps = (state, initialProps) =>
{
  return (state) =>
  {
    return {
      place: state.getIn(['places', initialProps.match.params.id]),
    }
  }
};

export default connect(mapStateToProps)(PlacesMapPanel);
