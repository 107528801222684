import React from 'react';

class ConfirmModal extends React.PureComponent
{
  render()
  {
    const { message, confirm, cancel, title, id, onConfirm } = this.props
    const modal =
      <div className="modal" id={ id } tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{ title }</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              { message }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={ onConfirm }>{ confirm } </button>
              <button type="button" className="btn btn-secondary" >{ cancel }</button>
            </div>
          </div>
        </div>
      </div>
    return modal
  }

}

export default ConfirmModal;
