import React from 'react';
import { Link } from 'react-router-dom';
import LiveDate from './live_date';

class BrandsRow extends React.PureComponent
{

  render()
  {
    const { brand } = this.props;

    return (
      <tr>
        <td><Link to={ `/brands/${brand.id}` } className="badge badge-brands">{ brand.id }</Link></td>
        <td>{ brand.name }</td>
        <td>{ brand.key }</td>
        <td>{ brand.stock_ticker }</td>
        <td><a href={ brand.website } className="badge badge-brands">{ brand.website }</a></td>
        <td><LiveDate date={ brand.created_at } /></td>
        <td><LiveDate date={ brand.updated_at } /></td>
      </tr>
    )
  }
}

BrandsRow.columns = [
  { col: "id", label: "Id" },
  { col: "name", label: "Name" },
  { col: "key", label: "Key" },
  { col: "stock_ticker", label: "Stock Ticker" },
  { col: "website", label: "Website" },
  { col: "created_at", label: "Created" },
  { col: "updated_at", label: "Updated" }
];

export default BrandsRow;
