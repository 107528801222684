import React from 'react';
import { Link } from 'react-router-dom';
import * as queries from '../../shared/data/queries_analysis.json';
import { withRouter } from 'react-router-dom';

class AnalysisPlacesNonStandardValueCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount()
  {
    const { nonstandard_value } = this.props;
    let indexes, Rows = [], total = 0, dictionary = {}

    if (nonstandard_value)
    {
      indexes = Array.from(nonstandard_value.keys())
      indexes.forEach((item, i) =>
      {
        total += nonstandard_value.get(item).size
        const label = item ? item : "null"
        dictionary[label] = []
        nonstandard_value.get(item).forEach((item, i) =>
        {
          dictionary[label].push(item)
          Rows.push(<Link to={ `/places/${item}/edit` } target="_blank" className="badge badge-places">{ item }</Link>)
        });

        dictionary[label] = dictionary[label]
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)

      });
    }

    var sortable = [];
    for (var id in dictionary)
    {
      sortable.push([id, dictionary[id]]);
    }

    sortable.sort(function(a, b)
    {
      return b[1].length - a[1].length;
    });

    this.setState({ dicValues: sortable, total: total })
  }

  render()
  {
    const nextUrl = 'https://adminnext.dataplor.com'
    let list, copy, height, body
    const { attribute } = this.props;
    const id = this.props.match.params.id


    let typeResult = this.props.match.path.toString().includes('deliveries') ? 'deliveries' : this.props.match.path.toString().includes('samples') ? 'samples' : 'brands'


    if (this.state.open)
    {
      list =
        this.state.dicValues.map((item) =>
        {
          let toReturn = []
          let idsBadges = []

          const amountItems = item[1].length

          item[1].forEach((it, i) =>
          {
            if (i < 100 && amountItems <= 100)
              idsBadges.push(<Link to={ `/places/${it}/edit` } key={ i } target="_blank" className="badge badge-places m-1">{ it }</Link>)
            else if (i < 50 || i >= amountItems - 50)
            {
              idsBadges.push(<Link to={ `/places/${it}/edit` } key={ i } target="_blank" className="badge badge-places m-1">{ it }</Link>)
            }
          });
          let label = item[0]
          var humanize = require('humanize-plus');
          if (!isNaN(item[0]))
            label = humanize.intComma(item[0])

          toReturn.push(
            <div className="card border-primary m-2" key={ label }>
              <div className="card-header">{ label }
                <div className="card-title">
                  <div className="btn btn-info mx-2" onClick={ () => { navigator.clipboard.writeText(JSON.stringify(item[1])) } }>
                    Copy
                  </div>
                  <div className="btn btn-info" onClick={ () =>
                  {
                    let idsList = ''

                    item[1].forEach(element =>
                    {
                      idsList = idsList + element.toString() + ','
                    })

                    idsList = idsList.substring(0, idsList.length - 1)

                    let query = queries.queries[attribute].replace('{{ids}}', idsList)
                    //console.log(query)
                    navigator.permissions.query({ name: "clipboard-write" }).then(result =>
                    {
                      if (result.state === "granted" || result.state === "prompt")
                      {
                        navigator.clipboard.writeText(query)
                      }
                    });
                  } }>
                    Query
                  </div>
                </div>
              </div>
              <div className="card-body">{ idsBadges }
              </div>
            </div>)


          return toReturn;
        })


      copy = <div className="row align-self-end">
        <div className="col-2 btn btn-info m-1" onClick={ this.download }>Copy</div>
        { attribute in queries.queries ? <div className="col-2 btn btn-info m-1" onClick={ this.query }>Query</div> : null }
        <a href={ `${nextUrl}/${typeResult}/${id}/analysis/places?analysis_key=${this.props.typeIssue}.${attribute}` } target='_blank' rel="noreferrer" className='col-2 btn btn-info m-1'>adminNext</a>
      </div>

      height = { height: '50vh', 'overflowY': 'auto' }
      body = <div className="card-body" key={ attribute } style={ height }>
        { copy }
        <div key={ attribute }>
          { list }
        </div>
      </div>
    }


    return (
      <div className="card border-danger mb-2" key={ attribute }>
        <div className="card-header text-danger cursor-pointer" onClick={ this.toggle }>
          { attribute }: [{ this.state.total }]
        </div>
        { body }
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }

  download = () =>
  {
    let ids = []

    for (let i = 0; i < this.state.dicValues.length; ++i)
    {
      for (let j = 0; j < this.state.dicValues[i][1].length; ++j)
      {
        ids.push(this.state.dicValues[i][1][j])
      }
    }
    console.log(ids)
    navigator.permissions.query({ name: "clipboard-write" }).then(result =>
    {
      if (result.state === "granted" || result.state === "prompt")
      {
        navigator.clipboard.writeText(JSON.stringify(ids))
      }
    });
  }

  query = () =>
  {
    const { attribute } = this.props;
    let idsList = ''
    const ids = this.state.dicValues

    ids.forEach((x, i) =>
    {
      ids[i][1].forEach(element =>
      {
        idsList = idsList + element.toString() + ','
      })
    });

    idsList = idsList.substring(0, idsList.length - 1)

    let query = queries.queries[attribute].replace('{{ids}}', idsList)
    console.log(query)

    navigator.permissions.query({ name: "clipboard-write" }).then(result =>
    {
      if (result.state === "granted" || result.state === "prompt")
      {
        navigator.clipboard.writeText(query)
      }
    });
  }
}


export default withRouter(AnalysisPlacesNonStandardValueCard);
