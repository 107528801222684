import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';

export default function(){
  const render = Component => {
    ReactDOM.render(<Component />, document.getElementById('root'));
  };

  render(App);

  if (module.hot) {
    module.hot.accept('./components/app', () => {
      const NextApp = require('./components/app').default;
      render(NextApp);
    });
  }
}
