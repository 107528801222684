import Immutable from 'immutable';

const defaults = {
  id: null,
  name: null,
  key: null,
  website: null,
  stock_ticker: null,
  external_ids: Immutable.fromJS({}),
  parent_id: null,
  created_at: null,
  updated_at: null,
  relationships: Immutable.fromJS({}),
  logo: null,
  example_photo: null,
  duplicates_path: null,
  analysis: null,
  analysis_path: null,
  translations: Immutable.fromJS({}),
  notes: null,
  is_chain: null,
  chains_path: null,
  ready_for_identification: null,
  business_category_ids: Immutable.fromJS([]),
};

class Brand extends Immutable.Record(defaults) {
}

export default Brand;
