import React from 'react';
import { connect } from 'react-redux';

import TasksActions from '../actions/tasks_actions';

import IndexView from './index_view';
import TasksRow from './tasks_row';

const columns = [
  { col: "id", label: "Id" },
  { col: "type", label: "Type" },
  { col: "priority", label: "Priority" },
  { col: "assigned_at", label: "Assigned" },
  { col: "created_at", label: "Created" },
];

class TasksIndex extends IndexView
{

  constructor(props)
  {
    super(props);
    this.state = {};
  }

  render()
  {
    const { total, page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.tasks.toIndexedSeq().map(task =>
    {

      return <TasksRow key={ task.id } task={ task } />
    })

    return (
      <div id="tasksIndex">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            { this.searchBar() }
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  { this.generateHeaders(columns) }
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </table>
            { this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            }) }
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) =>
  {
    return TasksActions.index(query).then(response =>
    {
      this.setState({ total: response.meta.total, page_size: response.meta.page_size })
    });
  }
}

const mapStateToProps = state =>
{
  return {
    tasks: state.get('tasks'),
    total: state.getIn(['page', 'totals', 'places']),
  }
}

export default connect(mapStateToProps)(TasksIndex);
