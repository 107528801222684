import React from 'react';
import { connect } from 'react-redux';
import geodist from 'geodist';
import { Marker, Circle} from 'react-leaflet'
import DataplorMap from '../../shared/components/dataplor_map';
import { blue,yellow } from '../../shared/helpers/color_helpers';
import { calculateBounds } from '../../shared/helpers/google_maps_helpers';

class DuplicatePlaceMap extends React.PureComponent
{
  render()
  {
    const { original, duplicate, explain } = this.props;
    let score = explain.getIn(['total'])
    if(score)
      score = (score*100).toFixed(2)

    const originalCoords = {lat: original.latitude,lon: original.longitude};

    const dupCoords = {lat: duplicate.latitude,lon: duplicate.longitude};
    const dist = geodist(originalCoords,dupCoords,{unit: 'meters'}).toFixed(0);
    const distCompact = new Intl.NumberFormat('en-US', { notation: "compact" , compactDisplay: "short" }).format(dist);
    const {east,west,south,north} = calculateBounds([
      {lat: original.latitude, lng: original.longitude},
      {lat: duplicate.latitude, lng: duplicate.longitude},
    ],0.5);
    const bounds = [[north,east],[south,west]]

    return (
      <div id="container" style={{height:"400px", width:"100%"}}>
        <DataplorMap bounds={bounds} style={{height: '400px', "zIndex":"1"}} >
          <Circle center={originalCoords} radius = {original.location_accuracy} color = {yellow} fillOpacity = {0.4} weight = {2} />
          <Circle center={dupCoords} radius = {duplicate.location_accuracy} color = {blue} fillOpacity = {0.4} weight = {2} />
          <Marker position={originalCoords}/>
          <Marker position={dupCoords}/>
        </DataplorMap>
        <div className="text-center p-0 mx-auto" style={{position: "absolute", top:"0", width:"200px", left:"calc(50% - 100px)", borderRadius:"3px", backgroundColor: "rgba(0,0,0,0.5)" , "zIndex":"2"}}>
          <div className="container">
            <div className="row p-0">
              <div className="col p-2 h4 mb-0 border-right">
                <strong>{score}%</strong>
              </div>
              <div className="col text-white p-2 d-flex align-items-center justify-content-center">
                <span className="text-center">{distCompact}m</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onLoad = () => {
    const { original, duplicate } = this.props;
    const {east,west,south,north} = calculateBounds([
      {lat: original.latitude, lng: original.longitude},
      {lat: duplicate.latitude, lng: duplicate.longitude},
    ],0.5);

    const bounds = [[north,east],[south,west]]
    return bounds
    //map.fitBounds(bounds)
  }
}

const mapStateToProps = (state,props) =>
{

  const explain = state.getIn(['page', 'explain'])
  return{
    explain: explain
  };
};
export default connect(mapStateToProps)(DuplicatePlaceMap);
