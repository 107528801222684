import React from 'react';
import { Link } from 'react-router-dom';

import LiveDate from './live_date';

class SamplesRow extends React.PureComponent {

  render(){

    return (
      <tr>
        <td><Link to={`/samples/${this.props.sample.id}/edit`} target="_blank" className="badge badge-samples">{this.props.sample.id}</Link></td>
        <td>{this.props.sample.name}</td>
        <td><LiveDate date={this.props.sample.created_at} /></td>
        <td>{this.props.sample.notes}</td>
      </tr>
    )
  }
}

export default SamplesRow;
