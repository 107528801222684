import React from 'react';
import { connect } from 'react-redux';
import LiveDate from './live_date';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import isNil from 'lodash/isNil';

class PlacesPatchRow extends React.Component {

  render() {
    const patch = this.props.patch

    const c = patch.get('c')
    const o = patch.get('o')
    const p = patch.get('p')
    const v = patch.get('v')
    const d = patch.get('d')
    const i = patch.get('i')
    const z = patch.get('z')
    const t = patch.get('t')

    const color = {'border-danger':!o, 'border-success':o}
      const rowClass = cn(color, 'row border-solid border mb-2 rounded p-2 self-center align-middle')

      let confidenceOverTime
      if(isNil(z))
      {
        confidenceOverTime =
          <div className ="col-1 align-middle">
            {c}
          </div>
      }
      else
      {
        confidenceOverTime =
        <div className ="col-1 align-middle">
          {c} { "->" } {z.toFixed(2)}
        </div>
      }

    return (
      <div className = {rowClass}>
        {confidenceOverTime}
        <div className ="col-2">
          <Link to={`/observations/${i}`} target="_blank" className="badge badge-observations">{i}</Link>
          <span className="badge badge-dark ">{t}</span>
        </div>
        <div className ="col-2 text-monospace">
          {p}
        </div>
        <div className = "col-1">
          <LiveDate date={d}/>
        </div>
        <div className ="col">
          {JSON.stringify(v)}
        </div>
      </div>
    );
  }


}


export default connect()(PlacesPatchRow);
