import React from 'react';
import { Link } from 'react-router-dom';

class AnalysisPlacesMissingCardRow extends React.PureComponent
{

  constructor(props)
  {
    super(props);
    this.state = {
      open: false,
      loaded: false,
    };
  }

  render()
  {
    const { id } = this.props;

    return (
      <Link to={ `/places/${id}/edit` } target="_blank" className="badge badge-places m-1">{ id }</Link>
    )
  }
}

export default AnalysisPlacesMissingCardRow;
