import React from 'react';
import { connect } from 'react-redux';

class BrandsComparePlacesCategoryExplanationPanel extends React.Component
{

  render()
  {
    const { category_explanation } = this.props;
    if (!category_explanation)
    {
      return null
    }
    let rows


    if (category_explanation.categories)
    {
      rows = Object.keys(category_explanation.categories).map(key =>
      {
        return (
          <tr key={ key }>
            <td className='text-truncate'>
              { category_explanation.place_category }
            </td>
            <td>
              { key }
            </td>
            <td>
              { category_explanation.categories[key] }
            </td>
          </tr>
        )
      })
    }
    return (
      <div className='card mb-1'>
        <div className='card-header  bg-info'>
          <div className='row text-white justify-content-between fs-1 fw-bold'>
            <div className='col-9 fw-bold'>
              Category explanation
            </div>
            <div className='col-auto fw-bold'>
              Score: { category_explanation.score.toFixed(2) }
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row mb-1'>
            Place category: { category_explanation.place_category || 'null' }
          </div>
          <div className='row'>
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th className='w-25'>Place Category</th>
                  <th>Category</th>
                  <th className='w-25'>Score</th>
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </table>
          </div>
        </div>

      </div >
    )
  }
}

export default connect(null)(BrandsComparePlacesCategoryExplanationPanel);
