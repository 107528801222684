import React from 'react';
// import schema from '../../shared/schemas/data_schema'
import { connect } from 'react-redux';
import ConfidenceScale from 'react-confidence-scale';
// const properties = schema.get("properties").keySeq().toJSON().sort();

class PatchFormClosedRow extends React.PureComponent
{

  // constructor(props)
  // {
  //   super(props);
  // }

  // componentDidMount()
  // {
  //   const permanently_closed = this.props.data.get("permanently_closed")
  //   const temporarily_closed   = this.props.data.get("temporarily_closed")
  // }

  confidenceChange = (value) =>
  {
    const c = (this.props.row.get("confidence"))
    if (c === value)
    {
      this.props.updateRow(this.props.formKey, this.props.row.get('id'), { confidence: undefined })
      this.props.validate(this.props.row.merge({ confidence: undefined }))
    }
    else
    {
      this.props.updateRow(this.props.formKey, this.props.row.get('id'), { confidence: value })
      this.props.validate(this.props.row.merge({ confidence: value }))
    }
  }

  onChange = e =>
  {
    if (e === undefined)
      return
    let value = e.target.value
    if (value !== '')
    {
      this.props.updateRow(this.props.formKey, this.props.row.get('id'), { new_value: e.target.value, assert: true })
      this.props.validate(this.props.row.merge({ new_value: e.target.value, assert: true }), this.props.formKey)
    }
    else
    {
      this.props.updateRow(this.props.formKey, this.props.row.get('id'), { new_value: undefined, assert: undefined, confidence: undefined })
      this.props.validate(this.props.row.merge({ new_value: undefined, assert: undefined, confidence: undefined }), this.props.formKey)
    }
  }

  render()
  {
    const { form_errors } = this.props;
    let input
    input = <select className="custom-select" name="selectBoool" onChange={ this.onChange }>
      <option value=''></option>
      <option value={ 'pclosed' }>Permanently closed</option>
      <option value={ 'tclosed' }>Temporarily closed</option>
      <option value={ 'open' }>Open</option>
    </select>


    return (
      <div className="row border-bottom p-2 has-danger">
        <div className="col-2 text-white d-flex align-items-center">
          <small className="text-monospace">business status</small>
        </div>
        <div className="col-6 text-white d-flex align-items-center">
          { input }
        </div>
        <div className="col-1 d-flex align-items-center justify-content-center">
        </div>
        <div className="col-3 d-flex align-items-center">
          <ConfidenceScale value={ this.props.row.get('confidence') } onChange={ this.confidenceChange } />
        </div>
        <div className="col-2" />
        <div className="col d-flex align-items-center text-danger invalid-feedback">
          { form_errors }
        </div>
      </div>
    )
  }

}


const mapStateToProps = (initialState, initialProps) =>
{
  const formKey = initialProps.formKey;
  const id = initialProps.row.get('id')
  const form = initialState.getIn(['forms', formKey])
  return ({
    form_errors: initialState.getIn(['form_errors', formKey, id, "error"]),
    form: form,
  });

};

const mapDispatchToProps = {
  updateRow: (formKey, id, changes) => ({ type: 'forms.merge', data: { [formKey]: { [id]: changes } } }),
}


export default connect(mapStateToProps, mapDispatchToProps)(PatchFormClosedRow);
