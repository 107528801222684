import React from 'react';
import { connect } from 'react-redux';

class NetworkErrorAlert extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = { show: false };
  }

  render()
  {
    const { network_error } = this.props

    const onClickCopy = () =>
    {
      navigator.clipboard.writeText(JSON.stringify(network_error.error_report));
    }

    const errorAlert = (
      <div className="alert alert-danger m-2" role="alert">
        Status: { network_error.status } <br />
        Url: { network_error.error_report?.url } <br />
        Body: { network_error.error_report?.options.body }<br />
        <button type="button" className="btn btn-dark my-2" onClick={ onClickCopy }>Copy</button>
      </div>)

    if (network_error.error_report)
      return errorAlert
    else
      return null
  }

}

const mapStateToProps = (state, props) =>
{
  return {
    network_error: state.get('network_errors')
  };
};

export default connect(mapStateToProps, null)(NetworkErrorAlert);
