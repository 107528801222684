import React from 'react';

class ExternalIdAutoLinker extends React.PureComponent
{

  render()
  {
    const { value, type, borderColor } = this.props


    const url = (type, value) =>
    {
      switch (type)
      {
        case 'google_places_id':
          return `https://www.google.com/maps?q=place_id:${value}`
        case 'facebook_id':
          return `https://www.facebook.com/${value}`
        case 'starbucks_store_id':
          return `https://www.starbucks.com/store-locator/store/${value}`
        default:
          return null
      }
    }

    const link = url(type, value)

    const content = link === null ? <span>{ value }</span> : <a href={ link } target="_blank" rel="noopener noreferrer" className="">{ value }</a>

    return (
      <td className={ `span4 text-center align-middle ${borderColor}` } style={ { width: "45%" } }>
        { content }
      </td>
    )

  }
}

export default ExternalIdAutoLinker;
