import React from 'react';
import AnalysisResults from './analysis_results'
import { connect } from 'react-redux';
import Immutable from 'immutable';
import Ajv from 'ajv';
import { raw } from '../../shared/schemas/analyze_schema';

class AnalysisPlacesManualFile extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
    };
  }

  render()
  {
    let { analysis } = this.props;
    let results
    let formUpload

    if (this.state.loaded && this.state.jsonFile)
    {
      results = <AnalysisResults analysis={ analysis || this.state.jsonFile } />
    }
    else
    {
      formUpload =
        <form>
          <div className="form-group">
            <label htmlFor="exampleFormControlFile1">Upload File</label>
            <input type="file" className="form-control-file" id="exampleFormControlFile1"
              onChange={ (event) =>
              {
                this.handleFileSelect(event)
              } }
              onClick={ (event) =>
              {
                event.target.value = null
              } }
            />
          </div>
        </form>
    }

    return (
      <div className="card border-danger mb-2" >
        <div className="card-header text-danger cursor-pointer">
        </div>
        { formUpload }
        { results }
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }

  handleFileSelect(event)
  {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (event) =>
    {
      const obj = Immutable.fromJS(JSON.parse(event.target.result))
      const ajv = new Ajv()
      const validate = ajv.compile(raw)
      const valid = validate(JSON.parse(event.target.result))
      if (valid)
      {
        this.props.merge(obj)
        this.setState({ loaded: true, jsonFile: obj })
      }
      else
      {
        console.log(validate.errors)
        this.setState({ loaded: true, error: valid })
      }
    }
    reader.readAsText(file);
  }
}


const mapStateToProps = (state, props) =>
{
  return {
    analysis: state.manualAnalysis
  };
};

const mapDispatchToProps = (state, props) =>
{
  return dispatch => ({
    merge: (result) => dispatch({ type: 'manualAnalysis.merge', data: { analysis: result } }),
  });
}


export default connect(mapStateToProps, mapDispatchToProps)(AnalysisPlacesManualFile)
