import React from 'react';

import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts';

class AnalysisPlacesDistributionCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);

    this.state = {
      open: false,
    };
  }

  render()
  {
    const { distributions } = this.props;

    let barchart;

    let keys = Array.from(distributions.keys());
    let values = Array.from(distributions.values());

    let data = []
    for (let i = 0; i < 101; ++i)
    {
      data.push({ 'key': i, 'count': 0 })
    }

    for (let i = 0; i < keys.length; i++)
    {
      data[keys[i]].count = values[i]
      //data.push({'key': parseInt(keys[i]), 'val':values[i]})
    }

    if (this.state.open)
    {
      barchart =
        <ResponsiveContainer width='100%' height={ 300 }>
          <LineChart
            width={ 500 }
            height={ 500 }
            data={ data }
            margin={ {
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            } }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="key" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={ { r: 8 } } />
            <Bar dataKey="count" fill="#AAAAAA" />
          </LineChart>
        </ResponsiveContainer>
    }
    return (
      <div className="card border-primary mb-2">
        <div className="card-header text-primary cursor-pointer w-100" onClick={ this.toggle }>
          Distributions: [{ data.length }]
        </div>
        { barchart }
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default AnalysisPlacesDistributionCard;
