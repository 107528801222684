import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class PlacesChildsRecursivePanel extends React.Component
{
  render()
  {
    const { place, allChilds, main, root } = this.props;
    let rows;

    if (allChilds)
    {
      rows = allChilds.toIndexedSeq().map(p =>
      {
        if (p.parent_id === place.id)
        {
          return <PlacesChildsRecursivePanel place={ p } allChilds={ allChilds } main={ main } root={ root } />
        }
        else
        {
          return null
        }
      })
    }

    const color = main && main.id && place.id === main.id ? "badge badge-primary" : "badge badge-places"
    var link = <div><Link to={ `/places/${place.id}/edit` } className={ color }>{ place.id }</Link>{ place.name },{ place.address },{ place.city },{ place.country }</div>
    var renderHeaderClassName = "visits-data mx-2 my-2 bg-primary"
    if (main && main.id && main.id !== place.id)
    {
      link = <div><Link to={ `/places/${place.id}/edit` } target="_blank" className={ color }>{ place.id }</Link> <Link to={ `/places/${main.id}/compare/${place.id}` } className="text-white bg-warning px-2">Compare to main</Link>{ place.name },{ place.address },{ place.city },{ place.country }</div>
      renderHeaderClassName = "visits-data mx-2 my-2"
    }
    return (
      <div className="visits-data mx-2 my-2">
        <div className={ renderHeaderClassName }>
          { link }
        </div>
        <ul className="nested">
          { rows }
        </ul>
      </div>
    )
  }

}

export default connect()(PlacesChildsRecursivePanel);
