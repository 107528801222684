import Immutable from 'immutable';

const defaults = {
  id: null,
  key: null,
  path: null
};

class NewBusinessCategory extends Immutable.Record(defaults) {
}

export default NewBusinessCategory;
