import React from 'react';

const JFormAppend = (props) => {
  const element = props.element;

  const content = element.get('append');

  if(content){
    return (
      <div className="input-group-append">
        <span className="input-group-text">{content}</span>
      </div>
    )
  } else {
    return null;
  }
};

export default React.memo(JFormAppend);
