import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch, Route, Link } from 'react-router-dom';
import TasksDataPanel from './tasks_data_panel';
import TasksEditPanel from './tasks_edit_panel';
import TasksDetailPanel from './tasks_detail_panel';
import TasksActions from '../actions/tasks_actions';

import LiveDate from './live_date';

class TasksShow extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = {
      task: {},
      tab: 'details',
      loaded: false
    };
  }

  componentDidMount()
  {
    TasksActions.show(this.props.match.params.id).then(json =>
    {
      this.setState({ loaded: true, taskJson: json });
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if (this.props.match.params.id !== prevProps.match.params.id)
    {
      this.setState({ loaded: false });
      TasksActions.show(this.props.match.params.id).then(json =>
      {
        this.setState({ loaded: true, taskJson: json });
      })
    }
  }


  render()
  {
    if (this.state.loaded)
    {
      const task = this.props.task;
      const subpath = this.props.location.pathname.match(/tasks\/\d+\/?(\w*)/)[1];
      const taskNameColored = <span className="badge badge-tasks">{ task.id }</span>
      return (
        <div id="tasksShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-tasks">{ task.name }</span> { taskNameColored }</h2>
              <p>Added <LiveDate date={ task.created_at } />, updated <LiveDate date={ task.updated_at } /></p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'data') }) } to={ `/tasks/${task.id}/data` }>Data</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'detail') }) } to={ `/tasks/${task.id}/detail` }>Detail</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={ cn('nav-link', { active: (subpath === 'edit') }) } to={ `/tasks/${task.id}/edit` }>Edit</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/tasks/:id/" component={ TasksDataPanel } />
                    <Route exact path="/tasks/:id/data" component={ TasksDataPanel } />
                    <Route exact path="/tasks/:id/detail" component={ TasksDetailPanel } />
                    <Route exact path="/tasks/:id/edit" render={ (props) => <TasksEditPanel taskJson={ this.state.taskJson } { ...props } /> } />

                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    }
    else
    {
      return 'loading...'
    }
  }

  switchTab = (e) =>
  {
    e.preventDefault();
    this.setState({ tab: e.target.getAttribute('data-tab') })
  }

  fetchData = (query) =>
  {
    TasksActions.index(
      Object.assign(
        {},
        query,
        { filter: { root_id: this.props.match.params.id } }
      ), "merge"
    )
  }
}

const mapStateToProps = (state, props) =>
{
  return {
    task: state.getIn(['tasks', props.match.params.id]),
  }
}


export default connect(mapStateToProps)(TasksShow);
