import React from 'react';
import { connect } from 'react-redux';

import IndexView from './index_view';
import ObservationsRow from './observations_row';

import PlaceObservationsActions from '../actions/place_observations_actions';

class PlacesObservationsPanel extends IndexView
{
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  render()
  {
    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.observations.toIndexedSeq().map(obs => {
      return <ObservationsRow key={obs.id} observation={obs}/>
    })

    return (
      <div>
        <div className="row">
          <div className="col">
            <table className="table table-sm table-bordered table-hover table-striped col">
              <thead>
                <tr>
                  {this.generateHeaders(ObservationsRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>

      </div>
    )
  }

  fetchData = (query) => {
    return PlaceObservationsActions.index(this.props.match.params.id, query).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = (initialState,initialProps) => {

  const place = initialState.getIn(['places',initialProps.match.params.id])

  return (state) => ({//FIX, o.place_path might be null
    observations: state.get('observations').filter(o => o.place_path && o.place_path.startsWith(place.path))
  });
}

export default connect(mapStateToProps)(PlacesObservationsPanel);
