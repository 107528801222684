import React from 'react';
import { connect } from 'react-redux';
import { white } from '../../shared/helpers/color_helpers';
import { getLink } from '../helpers/external_ids_linker_helpers'


class PlacesExternalIdsPanel extends React.PureComponent
{
  render()
  {
    const { place } = this.props;

    const external_ids = Array.from(place.external_ids.keys())

    const rows = external_ids.map(id =>
    {
      const value = place.external_ids.get(id)
      const link = getLink(id, value)
      if (link !== value)
        return (
          <a target="_blank" key={ id } className="badge badge-places m-1" href={ link } rel="noreferrer">{ id + ": " + value }</a>
        )
      else
        return (
          <span key={ id } className='badge bg-secondary m-1'>{ id + " : " + value }</span>
        )
    })

    const latitude = place.latitude
    const longitude = place.longitude
    let content

    if (latitude && longitude)
    {
      const link = getLink('street_view', { latitude: latitude, longitude: longitude })
      content = <a target="_blank" key={ 'streetView' } href={ link } className="badge badge-places m-1" style={ { color: white } } rel="noreferrer"> StreetView </a>
      rows.push(content)
    }



    return (
      <div className="row m-1 p-1 align-content-start">
        <p>External ids:{ rows }</p>
      </div>
    );
  }
}

export default connect(null)(PlacesExternalIdsPanel);
