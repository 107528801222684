
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params, actionType = "load")
  {
    const q = Object.assign({ sort: 'observations.id', sort_dir: 'desc' }, params, { page_size: 25 });
    return authorizedFetch('/v3/admin/observations', q).then(handleResponse("observations", actionType));
  },

  show: function(id)
  {
    return authorizedFetch(`/v3/admin/observations/${id}`).then(handleResponse("observations", "merge"))
  },
  update: function(id, params)
  {
    const opts = {
      method: 'PUT',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/observations/${id}`, null, opts).then(handleResponse("observations", "merge"));
  },
  segregate: function(id, params)
  {
    const opts = {
      method: 'POST',
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v3/admin/observations/${id}/segregations`, null, opts).then(handleResponse("observations", "merge"));
  }
};
