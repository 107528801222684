import React from 'react';
import { connect } from 'react-redux';
import LiveDate from './live_date';
import PlacesOverviewPatchPanelPatches from './places_overview_patch_panel_patches'

class PlacesOverviewPatchPanelRow extends React.Component
{

  constructor(props)
  {
    super(props)
    this.state  ={expanded:false}
  }

  toggleExpanded = (e) => {
    this.setState(prevState => ({expanded: !prevState.expanded}))
  }

  render() {

    const expanded = this.state.expanded
    const winner = this.props.winner
    const patch = this.props.patch

    const c = winner['c']
    const p = winner['p']
    const v = winner['v']
    const d = winner['d']

    const losers = expanded ? <PlacesOverviewPatchPanelPatches patch = {patch[p]}/> : undefined
    return (
      <React.Fragment>
        <tr onClick = {this.toggleExpanded}>
          <td>{p}</td>
          <td>{v}</td>
          <td>{c}</td>
          <td><LiveDate date={d} /></td>
        </tr>
        {losers}
      </React.Fragment>
    )
  }
}


export default connect()(PlacesOverviewPatchPanelRow);
