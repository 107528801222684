export const getZ = (c, d, options = {}) => {
	const cliff = 6 //months
	const MONTHSECS = 2592000000

	const as_of = options['as_of'] ? Date.parse(options['as_of']) : Date.now()
	const date = Date.parse(d)

	const months_old = (as_of - date) / MONTHSECS
	return (c * (1 - (1 / (1 + (Math.exp((cliff - months_old))))) + (1 / (1 + (Math.exp(cliff))))))
}

