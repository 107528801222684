import React from 'react';
import { connect } from 'react-redux';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

class BrandsFormPanelLocaleRowAdd extends React.PureComponent {

  addLocale = (e) => {
    e.preventDefault()
    this.props.addTranslation(this.props.brand.id);
  }

  render() {
    return <div className="row">
      <div className="col-auto">
        <button className={ `btn btn-lg btn-dark}` } onClick={ this.addLocale } ><FontAwesomeIcon className="fa-1x" icon={ faPlusCircle } style={ { color: "green" } } /> Add translation</button>
      </div>
    </div>
  }
}


const mapDispatchToProps = {

  addTranslation: (formKey) => {
    const id = uuidv4();
    return {
      type: 'forms.merge',
      data: {
        'brands/edit':
        {
          [formKey]: {
            'translations':
            {
              [id]:
              {
                'id': id,
                'locale': '',
                'name': '',
                'aliases': ''
              }
            }
          }
        }
      }
    };
  }
}


export default connect(null, mapDispatchToProps)(BrandsFormPanelLocaleRowAdd);
