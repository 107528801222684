import React from 'react';
import { connect } from 'react-redux';
import { determineTaskUIType } from '../helpers/task_helpers'

import TaskActions from '../actions/tasks_actions';

class TasksEditPanel extends React.Component
{

  constructor(props)
  {
    super(props)
    this.state = { changes_saved: false }
  }

  onComplete = (body) =>
  {
    TaskActions.update(this.props.task.id, { data: { attributes: { response: body } } }).then(json =>
    {
      //console.log(json)
      this.setState({ changes_saved: true })
    }).catch(e =>
    {
      console.log(e)
    })
  }

  render()
  {
    const { task, taskJson } = this.props;


    const TaskWindow = determineTaskUIType(task);

    if (this.state.changes_saved)
    {
      return (
        <div className="alert alert-primary" role="alert">
          Changes saved!
        </div>
      )
    }
    else
    {
      return (
        <div>
          <TaskWindow task={ task } taskJson={ taskJson } onComplete={ this.onComplete } />
        </div>
      );
    }

  }
}

const mapStateToProps = (state, props) =>
{
  return {
    task: state.getIn(['tasks', props.match.params.id])
  };
};

export default connect(mapStateToProps, null)(TasksEditPanel);
