
import { handleResponse } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


export default {  
  show: function(id)
  {
     return authorizedFetch(`/v3/admin/places/${id}/relatives`).then(handleResponse("places", "merge"));
  }
};
