import React from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from 'react-router-dom';
import logo from '../img/logo.svg';
import cn from 'classnames';

import { isLoggedIn } from '../../shared/helpers/authentication_helpers';

import T from '../../shared/components/t';

class Navbar extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {show: false};
  }

  render(){
    const loggedIn = isLoggedIn();
    const trainingDone = !!this.props.training_completed_at;

    const links = [];

    const collapseClasses = cn("collapse navbar-collapse",{show: this.state.show})

    if(loggedIn && trainingDone){
      links.push(
        <li className="nav-item" key="explore">
          <Link className={this.linkKlass('/')} to="/" onClick={this.toggle}><T k="navbar.explore" /></Link>
        </li>
      );

      links.push(
        <li className="nav-item" key="my_submissions">
          <Link className={this.linkKlass('/visits')} to="/visits" onClick={this.toggle}><T k="navbar.my_submissions" /></Link>
        </li>
      );

      links.push(
        <li className="nav-item" key="account">
          <Link className={this.linkKlass('/account')} to="/account" onClick={this.toggle}><T k="navbar.account" /></Link>
        </li>
      );
    }

    if(loggedIn && trainingDone){
      links.push(
        <li className="nav-item" key="training">
          <Link className={this.linkKlass('/training')} to="/training" onClick={this.toggle}><T k="navbar.training" /></Link>
        </li>
      );
    }

    let hamburger = (
      <button className="navbar-toggler" type="button" onClick={this.toggle}>
        <span className="navbar-toggler-icon"></span>
      </button>
    );

    const navStyles = {
      "backgroundColor": "#4b4be2"
    };

    const intercomLink = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a className="nav-link intercom-trigger" href="#" rel="noopener noreferrer">
        <T k="navbar.help" />
      </a>
    )

    return (

      <nav className="navbar navbar-expand-lg navbar-dark mb-4" style={navStyles}>
        <Link className="navbar-brand" to="/">
          <img src={logo} height="30" alt=""/>
        </Link>
        { hamburger }
        <div className={collapseClasses} id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            { links }
            <li className="nav-item" key="help">
              {intercomLink}
            </li>
          </ul>
        </div>
      </nav>
    )
  }

  toggle = (e) => {
    this.setState((prevState) => ({show: !prevState.show}) )
  }

  linkKlass = (path) => cn("nav-link",{active: (this.props.location.pathname === path)})
}

const mapStateToProps = state => ({
  training_completed_at: state.getIn(['explorer','training_completed_at']),
});

export default withRouter(connect(mapStateToProps)(Navbar));

