import React from 'react';
import BrandsImagesPanel from './brands_images_panel'

class BrandReviewCard extends React.PureComponent
{


  render()
  {
    const { brand } = this.props;

    const params = {
      params: { id: brand.id.toString() }
    }
    //const images = <BrandsImagesPanel brand={ brand } match={ params } />

    const content = <a href={ `/brands/${brand.id}` } target="_blank" rel="noopener noreferrer">{ brand.name }</a>


    return (
      <div id="brandsShow" className="mb-5 ">
        <div className="card-header h5 text-warning">
          { content }
        </div>



        <div className='card-body'>

          <div className='row'>
            <BrandsImagesPanel brand={ brand } match={ params } />
            <a className="btn btn-outline-primary col-12 mb-2" target="_blank" rel="noreferrer" href={ brand.website } role="button">Website</a>
            <a className="btn btn-outline-primary col-12" target="_blank" rel="noreferrer" href={ `http://google.com/search?q=${brand.name}` } role="button">Google it</a>
          </div>
        </div>


      </div>
    );
  }
}



export default BrandReviewCard;
