import React from 'react';
import { connect } from 'react-redux';

class BrandsImagesPanel extends React.Component
{
  render()
  {
    const { brand } = this.props;
    if (!brand)
    {
      return null
    }
    const logo =
      <div className='row mb-2'>
        <div className='col border border-primary rounded' style={ { minHeight: 100 } }>
          <img src={ brand.logo } alt="The logo couldn't be loaded, please check the url" className="mx-auto d-block img-fluid" data-toggle="tooltip" data-placement="top" title="Logo image" />
        </div>
      </div>
    const example_photo =
      <div className='row mb-2'>
        <div className='col border border-primary rounded' style={ { minHeight: 100 } }>
          <img src={ brand.example_photo } alt="The example photo couldn't be loaded, please check the url" className="mx-auto d-block img-fluid mt-2" data-toggle="tooltip" data-placement="top" title="Example photo image" />
        </div>
      </div>

    return (
      <div id="brandsShow" className="mb-5 ">
        <div className="card-body">
          { logo }
          { example_photo }
          <div className="row mb-2 embed-responsive embed-responsive-4by3">
            <iframe className="col border border-primary rounded embed-responsive-item" src={ brand.website } title="website"></iframe>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, props) =>
{
  const brand = state.getIn(['brands', props.match.params.id]);
  return {
    brand: brand
  };

};

export default connect(mapStateToProps)(BrandsImagesPanel);
