import Immutable from 'immutable';

export const raw = {
  "properties": {
    "permanently_closed": { "type": "boolean" },
    "temporarily_closed": { "type": "boolean" },
    "name": { "type": "string", "minLength": 1 },
    "business_category": { "type": "string" },
    "street_number": { "type": "string", "minLength": 1 },
    "street_name": { "type": "string", "minLength": 1 },
    "address": { "type": "string", "minLength": 1 },
    "address2": { "type": "string", "minLength": 1 },
    "neighborhood": { "type": "string", "minLength": 1 },
    "city": { "type": "string", "minLength": 1 },
    "chain_id": { "type": "string", "minLength": 1 },
    "coordinates": { "type": "string", "minLength": 15 },
    "state": { "type": "string", "minLength": 1 },
    "postal_code": { "type": "string", "minLength": 1 },
    "country_code": { "type": "string", "minLength": 2, "maxLength": 2, "enum": ["af", "ax", "al", "dz", "as", "ad", "ao", "ai", "aq", "ag", "ar", "am", "aw", "au", "at", "az", "bs", "bh", "bd", "bb", "by", "be", "bz", "bj", "bm", "bt", "bo", "ba", "bw", "bv", "br", "io", "bn", "bg", "bf", "bi", "kh", "cm", "ca", "cv", "ky", "cf", "td", "cl", "cn", "cx", "cc", "co", "km", "cg", "cd", "ck", "cr", "ci", "hr", "cu", "cy", "cz", "dk", "dj", "dm", "do", "ec", "eg", "sv", "gq", "er", "ee", "et", "fk", "fo", "fj", "fi", "fr", "gf", "pf", "tf", "ga", "gm", "ge", "de", "gh", "gi", "gr", "gl", "gd", "gp", "gu", "gt", "gg", "gn", "gw", "gy", "ht", "hm", "va", "hn", "hk", "hu", "is", "in", "id", "ir", "iq", "ie", "im", "il", "it", "jm", "jp", "je", "jo", "kz", "ke", "ki", "kp", "kr", "kw", "kg", "la", "lv", "lb", "ls", "lr", "ly", "li", "lt", "lu", "mo", "mk", "mg", "mw", "my", "mv", "ml", "mt", "mh", "mq", "mr", "mu", "yt", "mx", "fm", "md", "mc", "mn", "ms", "ma", "mz", "mm", "na", "nr", "np", "nl", "an", "nc", "nz", "ni", "ne", "ng", "nu", "nf", "mp", "no", "om", "pk", "pw", "ps", "pa", "pg", "py", "pe", "ph", "pn", "pl", "pt", "pr", "qa", "re", "ro", "ru", "rw", "sh", "kn", "lc", "pm", "vc", "ws", "sm", "st", "sa", "sn", "cs", "sc", "sl", "sg", "sk", "si", "sb", "so", "za", "gs", "es", "lk", "sd", "sr", "sj", "sz", "se", "ch", "sy", "tw", "tj", "tz", "th", "tl", "tg", "tk", "to", "tt", "tn", "tr", "tm", "tc", "tv", "ug", "ua", "ae", "gb", "us", "um", "uy", "uz", "vu", "ve", "vn", "vg", "vi", "wf", "eh", "ye", "zm", "zw"] },
    "phone": { "type": "string", "pattern": "\\+\\d+" },
    "mobile": { "type": "string", "pattern": "\\+\\d+" },
    "email": { "type": "string", "format": "email" },
    "credit_card": { "type": "boolean" },
    "website": { "type": "string", "format": "uri" },
    "facebook_url": { "type": "string", "format": "uri" },
    "instagram_url": { "type": "string", "format": "uri" },
    "tripadvisor_url": { "type": "string", "format": "uri" },
    "google_maps_url": { "type": "string", "format": "uri", "pattern": ".+google.com\/maps\/place\/.+" }
  },
  "allOf": [
    {
      "oneOf":
        [
          { "not": { "properties": { "country_code": { "enum": ["mx"] } } } },
          {
            "properties": {
              "country_code": { "enum": ["mx"] },
              "phone": { "pattern": "\\+52\\d{10}", "minLength": 13, "maxLength": 13 },
              "mobile": { "pattern": "\\+52\\d{10}", "minLength": 13, "maxLength": 13 },
              "postal_code": { "pattern": "\\d{5}", "minLength": 5, "maxLength": 5 }
            }
          }
        ]
    }
  ]
}
export default Immutable.fromJS(raw)
