import React from 'react';
import { connect } from 'react-redux';
import PlacesOverviewPatchPanelPatchesRow from './places_overview_patch_panel_patches_row'

class PlacesOverviewPatchPanelPatches extends React.Component
{
  render()
  {
    const loser = this.props.patch
    const losers = loser.map( (element, index) => {
     return <PlacesOverviewPatchPanelPatchesRow key={ index } patch={ element }/>
    })

    return (
      <React.Fragment>
        {losers}
      </React.Fragment>
    )
  }
}


export default connect()(PlacesOverviewPatchPanelPatches);
