import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import TaskClaimActions from '../actions/task_claim_actions';
import { determineTaskUIType } from '../helpers/task_helpers'
import ValidatorTaskBar from './validator_task_bar';

class ValidatorIndex extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = { loaded: false, requestInProgress: false, prevTaskId: undefined };
  }

  componentDidMount()
  {
    var interval = setInterval(this.poll, 5000);
    this.setState({ interval })
    this.poll();
  }

  poll = () =>
  {
    if ((!this.props.task || !this.state.requestInProgress) && this.props.error === undefined)
    {
      this.setState({ requestInProgress: true });
      TaskClaimActions.create().then(response =>
      {
        this.setState({ loaded: true, requestInProgress: false, taskJson: response })
      }).catch((e) =>
      {
        this.setState({ requestInProgress: false, error: e, loaded: true })
      })
        .finally(() =>
        {
          this.setState({ requestInProgress: false })
        });
    }
  }

  componentWillUnmount()
  {
    if (this.props.task)
    {
      TaskClaimActions.destroy(this.props.task.id);
      this.props.clearTask(this.props.task)
    }
    clearInterval(this.state.interval)
  }

  render()
  {
    const { task } = this.props;
    let taskWindow;

    if (this.state.loaded && task)
    {
      const Task = determineTaskUIType(task);
      taskWindow = <Task task={ task } onComplete={ this.onComplete } taskJson={ this.state.taskJson } />
    }
    else if (this.state.error === undefined)
    {
      taskWindow = (
        <div className="mx-auto text-center mt-5">
          <FontAwesomeIcon icon={ faSpinner } spin className="mx-auto mb-3" size="lg" />
          <br />
          <span className="mx-auto" >Searching for a new task</span>
        </div>
      )
    }
    else if (this.state.error)
    {
      taskWindow = (
        <div className="mx-auto text-center mt-5">
          <br />
          <span className="mx-auto" >Error while retrieving tasks, please check the console for more info</span>
        </div>
      )
    }
    else if (this.state.errorOnComplete)
    {
      taskWindow = (
        <div className="mx-auto text-center mt-5">
          <br />
          <span className="mx-auto" >Error </span>
        </div>
      )
    }

    return (
      <div className="validator_index">
        <ValidatorTaskBar task={ task } prevTaskId={ this.state.prevTaskId } />
        <div style={ { marginLeft: '-15px', marginRight: '-15px', height: 'calc(100vh - 51px - 64px)' } }>
          { taskWindow }
        </div>
      </div>
    )
  }

  onComplete = (body) =>
  {
    return TaskClaimActions.update(this.props.task.id, body).then(json =>
    {
      this.setState({ prevTaskId: this.props.task.id })
      this.props.clearTask(this.props.task);
      TaskClaimActions.create();
    }).catch(e =>
    {
      console.log(e)
    });
  }
}

const mapStateToProps = state =>
{
  const task = state.get('tasks').sortBy(t => t.created_at).first();
  return {
    task
  }
};

const mapDispatchToProps = {
  clearTask: (task) => ({ type: 'tasks.delete_in', data: [[task.id.toString()]] }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidatorIndex);
