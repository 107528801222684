import React from 'react';
import variables from '../../css/extra_colors.scss'

import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';

class AnalysisPlacesStatisticsCard extends React.PureComponent
{
  constructor(props)
  {
    super(props);

    const { stats } = this.props;
    if (stats === undefined)
      return

    let keys = Array.from(stats.keys());
    let values = Array.from(stats.values());
    let colors = []
    colors.push(variables.explorer)
    colors.push(variables.explorerShade1)
    colors.push(variables.explorerShade2)
    colors.push(variables.explorerShade3)
    colors.push(variables.place)
    colors.push(variables.placeShade1)
    colors.push(variables.placeShade2)
    colors.push(variables.placeShade3)
    colors.push(variables.observation)
    colors.push(variables.observationShade1)
    colors.push(variables.observationShade2)
    colors.push(variables.observationShade3)

    let data = [], others = [], total = 0, totalothers = 0, totals = []

    for (let i = 0; i < keys.length; i++)
    {
      total += values[i]
      data.push({ 'key': keys[i], 'value': values[i], 'fill': colors[i % 10] })
      totals.push({ 'key': keys[i], 'value': values[i], 'fill': colors[i % 10] })
    }

    for (let i = 0; i < keys.length; i++)
    {
      if (values[i] / total < 0.01)
      {
        delete (data[i])
        others.push({ 'key': keys[i], 'value': values[i] })
        totalothers += values[i]
      }
    }

    data = data.filter(function(el)
    {
      return el != null;
    });

    data.push({ 'key': 'others', 'value': totalothers, 'fill': colors[10] })

    this.state = {
      open: false,
      activeIndex: 0,
      data: data,
      others: others,
      totals: totals
    };
  }


  onPieEnter = (_, index) =>
  {
    this.setState({
      activeIndex: index,
    });
  };

  renderActiveShape = (props) =>
  {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={ cx } y={ cy } dy={ 8 } textAnchor="middle" fill='#FF00FF'>
          { }
        </text>
        <Sector
          cx={ cx }
          cy={ cy }
          innerRadius={ innerRadius }
          outerRadius={ outerRadius }
          startAngle={ startAngle }
          endAngle={ endAngle }
          fill={ variables.observation }
        />
        <Sector
          cx={ cx }
          cy={ cy }
          startAngle={ startAngle }
          endAngle={ endAngle }
          innerRadius={ outerRadius + 6 }
          outerRadius={ outerRadius + 10 }
          fill={ variables.observation }
        />
        <path d={ `M${sx},${sy}L${mx},${my}L${ex},${ey}` } stroke={ 'fill' } fill="none" />
        <circle cx={ ex } cy={ ey } r={ 2 } fill={ 'fill' } stroke="none" />
        <text x={ ex + (cos >= 0 ? 1 : -1) * 12 } y={ ey } textAnchor={ textAnchor } fill="#BBB">{ `${value} ${payload.key || "null"}` }</text>
        <text x={ ex + (cos >= 0 ? 1 : -1) * 12 } y={ ey } dy={ 18 } textAnchor={ textAnchor } fill="#999">
          { `(Rate ${(percent * 100).toFixed(2)}%)` }
        </text>
      </g>
    );
  }

  render()
  {

    if (this.state === undefined)
      return null
    let size = 0
    let barchart, list;
    let rows = this.state?.totals.sort((a, b) => b.value - a.value);
    let dataSorted = this.state?.data.sort((a, b) => b.value - a.value);
    rows = rows?.map((sample, index) =>
    {
      if (sample.value !== 0)
        size = size + 1
      return <tr key={ index }>
        <td> { sample.key } </td>
        <td> { sample.value } </td>
      </tr>
    })


    if (this.state?.open)
    {
      if (this.state.totals.length > 2)
        barchart =
          <ResponsiveContainer width="100%" height={ 300 }>
            <PieChart width={ 400 } height={ 400 }>
              <Pie
                activeIndex={ this.state.activeIndex }
                activeShape={ this.renderActiveShape }
                data={ dataSorted }
                cx="50%"
                cy="50%"
                innerRadius={ 60 }
                outerRadius={ 80 }
                fill="#FFFFFF"
                dataKey="value"
                onMouseEnter={ this.onPieEnter }
              />
            </PieChart>
          </ResponsiveContainer>
      list =
        <table className="table table-striped table-bordered table-sm table-hover">
          <tbody className="mb-3">
            { rows }
          </tbody>
        </table>
    }

    return (
      <div className="card border-success mb-2">
        <div className="card-header text-success cursor-pointer" onClick={ this.toggle }>
          { this.props.attribute }: [{ size }]
          { barchart }
        </div>
        <div className="col mp-5">
          { list }
        </div>
      </div>
    )
  }

  toggle = () =>
  {
    this.setState({ open: !this.state.open })
  }
}


export default AnalysisPlacesStatisticsCard;
