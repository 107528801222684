import React from 'react';
import { Map, TileLayer } from 'react-leaflet'

const DataplorMap = (props) =>
{
  const { tileset, ...mapProps } = props;
  const url = tileset || 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png';

  try
  {
    return (
      <Map { ...mapProps }>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={ url }
        />
        { props.children }
      </Map>
    )
  }
  catch (err)
  {
    console.log(err)
  }
};

export default React.memo(DataplorMap);
