import React from 'react';
import RetinaImage from 'react-retina-image';
import ImageMetadataTag from './image_metadata_tag';

class ImageWithMetadata extends React.PureComponent
{
  render()
  {
    const { assetsServer, image, urlKey, className, type } = this.props;
    let key = urlKey;
    let tags = [];

    if (urlKey)
    {
      let role = (image && image.role) || this.props.role;
      tags.push(<ImageMetadataTag key="role" text={ role } bstyle="secondary" />)

      if (image)
      {
        key = image.key.replace(/\.\w+$/, '');
        //tags.push( <ImageMetadataTag key="make" text={`make: ${image.make}`} bstyle={image.make ? 'success' : 'danger'}/>)
        //tags.push( <ImageMetadataTag key="model" text={`model: ${image.model}`} bstyle={image.make ? 'success' : 'danger'}/>)
        //tags.push( <ImageTakenAtMetadata key="taken_at" image={image}/> )
        //tags.push( <ImageDimensionMetadata key="width" image={image} attr="width"/>)
        //tags.push( <ImageDimensionMetadata key="height" image={image} attr="height"/>)
        //tags.push( <ImageMetadataTag key="format" text={`format: ${image.format}`} bstyle={image.format === 'jpeg' ? 'success' : 'danger'}/>)
        //if(latitude && longitude && image.latitude && image.longitude){
        //  tags.push( <ImageDistanceMetadata key="distance" image={image} latitude={latitude} longitude={longitude}/>)
        //  tags.push( <ImageCoordinatesMetadata key="coordinates" image={image} />)
        //} else {
        //  tags.push( <ImageMetadataTag key="coordinates" text={`No GPS`} bstyle="danger"/>)
        //}
      }
      else
      {
        tags.push(<ImageMetadataTag key="no_tags" text="no tags" bstyle="secondary" />)
      }

      const classes = ['position-relative', className].filter(x => x).join(" ");
      let imgUrl = `${assetsServer}/${key}_800.jpg`
      if (type === 'photoObservation')
      {
        imgUrl = `${assetsServer}/${key}`
      }
      return (
        <div className={ classes }>
          <a href={ imgUrl } target="_blank" rel="noopener noreferrer">
            <RetinaImage
              className="col p-0"
              src={ [imgUrl, imgUrl] }
              checkIfRetinaImgExists={ false }
              style={ { minHeight: "150px", maxHeight: "150px", maxWidth: "150px" } }
            />
            <div className="position-absolute metadata-tags-container p-2" style={ { minHeight: "150px", maxHeight: "150px", maxWidth: "150px" } }>
              { tags }
            </div>
          </a>
        </div>
      )
    } else
    {
      return null;
    }
  }
}

export default ImageWithMetadata;
